import { CloudFileInfo } from './getFileInfoFromSource';

/**
 * @param files a list of OnboardingRemoteFiles
 * @returns     a list of mrxs folder paths
 */

export const generateMrxsFolderPaths = (files: CloudFileInfo[]): string[] =>
  files
    .filter((file) => ['.mrxs'].some((v) => file.filename.endsWith(v)))
    .map(({ path, filename }) => {
      const fileName = filename.split('.mrxs')[0];
      return `${path.split(fileName)[0]}${fileName}/`;
    });
