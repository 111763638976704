import { Button, Flex, Icon, Input } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { LoginState, useValidation } from './Login.component';
import {
  $FormContainer,
  $LoginForm,
  $LoginMain,
  $LoginMainHeader,
  $LoginWrapper,
  $Logo,
  $ResetPasswordButton,
  $SentEmailDescription,
  $SentEmailTitle,
  $Separator,
  $SignInSeparator,
} from './Login.styles';
import { $ResetPasswordSent } from './ResetPassword.styles';

interface ResetPasswordFormProps {
  onSubmit: (args: { email: string }) => void | Promise<void>;
  loginState: null | LoginState;
  logo: ReactElement;
  loginBackgroundSrc: string | undefined;
}

export function ResetPasswordForm({
  onSubmit,
  loginState,
  logo,
  loginBackgroundSrc,
}: ResetPasswordFormProps): ReactElement {
  const [email, changeEmail] = useValidation('');
  const theme = useTheme();
  return (
    <Flex
      direction="column"
      style={{
        width: '100vw',
        position: 'relative',
        zIndex: '0',
        height: '100dvh',
        backgroundColor: theme.colors.primary,
        backgroundImage: `url(${loginBackgroundSrc})`,
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          flexGrow: '1',
        }}
      >
        <div
          style={{
            padding: `${theme.spacings[56]}px ${theme.spacings[72]}px 0`,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <$Logo>{logo}</$Logo>
        </div>
        <$LoginWrapper>
          {loginState === 'pending' ? (
            <$ResetPasswordSent>
              <Icon
                icon="CheckCircle"
                color="white"
                size="large"
                strokeWidth={2}
              />
              <$SentEmailTitle>We have sent you an email</$SentEmailTitle>
              <$SentEmailDescription>
                If you are registered in our system, you should receive an email
                to reset your password. Don’t forget to also check your spam
                folder.
              </$SentEmailDescription>
            </$ResetPasswordSent>
          ) : (
            <$LoginMain>
              <$LoginMainHeader>Create or change password</$LoginMainHeader>
              <$LoginForm
                id="authentication-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  void onSubmit({ email });
                }}
                noValidate={true}
              >
                <$FormContainer>
                  <Input
                    id="email"
                    sizeVariant="large"
                    key="aignx-email"
                    name="aignx-email"
                    type="email"
                    autoComplete="username"
                    placeholder="Email address"
                    value={email}
                    onChange={changeEmail}
                  />
                  <$ResetPasswordButton
                    id="user-sign-in-button"
                    type="submit"
                    disabled={!email}
                  >
                    Send Request
                  </$ResetPasswordButton>
                </$FormContainer>
                <$Separator />
                <$FormContainer>
                  <$SignInSeparator>Already a Portal user?</$SignInSeparator>
                  <Button
                    to="/login?expanded=true"
                    id="user-sign-in-button"
                    type="submit"
                    variant="primaryOutline"
                  >
                    Sign In
                  </Button>
                </$FormContainer>
              </$LoginForm>
            </$LoginMain>
          )}
        </$LoginWrapper>
      </div>
    </Flex>
  );
}
