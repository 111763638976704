import { ParseMeta } from 'papaparse';

export const handleCsvWithTableNameHeader = (
  meta: ParseMeta,
  isRetryWithStrippedHeader: boolean
): boolean => {
  if (
    meta.fields![0] && // First field is non-empty
    meta.fields!.slice(1).every((fieldName) => !fieldName) && // Rest of the fields are empty
    !isRetryWithStrippedHeader
  ) {
    return true; // Indicate that the case was handled
  }
  return false; // Indicate that the case was not handled
};
