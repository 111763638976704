import { User } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { FETCH_ONBOARDING_FILTERS_OPTIONS } from '../../../graphql/queries/FETCH_ONBOARDING_FILTERS_OPTIONS';
import { Association } from '../../../types';

export type FilterOptions = {
  users: User[];
  statuses: string[];
  uploadDates: Array<{ created_at: string }>;
  associations: Association[];
};

// get the filter options for the data onboarding page
export function useDataOnboardingFiltersOptions(): FilterOptions {
  const [filterOptions, setFiltersOptions] = useState<FilterOptions>({
    users: [],
    statuses: [],
    uploadDates: [],
    associations: [],
  });

  useQuery(FETCH_ONBOARDING_FILTERS_OPTIONS, {
    onCompleted(data) {
      const { users, statuses, uploadDates, associations } =
        data.onboardingBatches.collectionAttributes;
      setFiltersOptions({
        users,
        statuses,
        uploadDates,
        associations,
      });
    },
  });

  return filterOptions;
}
