import React from 'react';
import styled from 'styled-components';
import { Input } from '../Input';
import { Flex } from '../Layout';
import { $Select } from './OnboardingReadOnlyList.styles';
const $OnboardingReadOnlyBatchHeaderWrapper = styled.div `
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  padding-bottom: ${({ theme }) => theme.spacings[8]}px;
`;
const $OnboardingReadOnlyBatchHeaderLabel = styled.div `
  ${({ theme }) => theme.fontStyles.smallBold};
`;
const $OnboardingReadOnlyBatchHeaderInputWrapper = styled.div `
  width: 100%;
`;
export const OnboardingReadOnlyBatchHeader = ({ name, association, species, showAssociations, metadataUsageRestriction, }) => {
    return (React.createElement($OnboardingReadOnlyBatchHeaderWrapper, null,
        React.createElement(Flex, { gap: "8", wrap: "wrap" },
            React.createElement(Flex, { direction: "column", gap: "4", flexGrow: "2", flexShrink: "1" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Batch Name"),
                React.createElement($OnboardingReadOnlyBatchHeaderInputWrapper, null,
                    React.createElement(Input, { id: `batch-${name}-name-input`, value: name, disabled: true }))),
            showAssociations && (React.createElement(Flex, { direction: "column", flexGrow: "1", flexShrink: "1", gap: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Association Name"),
                React.createElement($Select, { id: `batch-${name}-association-select`, value: association, options: [{ value: association, label: association }], disabled: true, style: { width: '100%' } }))),
            React.createElement(Flex, { direction: "column", flexGrow: "1", flexShrink: "1", gap: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Species"),
                React.createElement($Select, { id: `batch-${name}-species-select`, value: species, options: [{ value: species, label: species }], disabled: true, style: { width: '100%' } })),
            React.createElement(Flex, { direction: "column", flexGrow: "1", flexShrink: "1", gap: "4" },
                React.createElement($OnboardingReadOnlyBatchHeaderLabel, null, "Data Usage Restriction"),
                React.createElement($Select, { id: `batch-${name}-usage-permissions-select`, value: metadataUsageRestriction, options: [
                        {
                            value: metadataUsageRestriction,
                            label: metadataUsageRestriction,
                        },
                    ], disabled: true, style: { width: '100%' } })))));
};
