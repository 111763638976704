import { DataOnboardingBatchList } from '@aignostics/onboarding-ui';
import React from 'react';
import useUserRole from '../../../hooks/useUserRole';
import { useAuthUser } from '../../App/AuthProvider';
export const AdminDataOnboardingWrapper = (): JSX.Element => {
  const role = useUserRole();
  const currentUser = useAuthUser();
  const {
    organization: { external_bucket },
  } = useUserRole();

  return (
    <DataOnboardingBatchList
      role={role}
      currentUser={currentUser}
      externalBucket={external_bucket}
    />
  );
};
