import {
  getAgGridPaginationConfig,
  useSnackbarMutations,
} from '@aignostics/components';
import { DocumentNode, useApolloClient } from '@apollo/client';
import {
  IServerSideDatasource,
  SetFilterModel,
  TextFilterModel,
} from 'ag-grid-enterprise';
import { useMemo } from 'react';

export type ColumnFilterModel = TextFilterModel | SetFilterModel;
export type FilterModel = Record<string, ColumnFilterModel>;

export type AnnotationCategoryData = {
  id: string;
  name: string;
  color: string;
  setId: string;
  setName: string;
  setCode: string;
  setModule: string;
  actions: string;
};

export const EMPTY_VALUE_INDICATOR = 'EMPTY';

/**
 * Extract text filter value from filter model
 */
export function extractTextFilter(model?: ColumnFilterModel): string {
  return model?.filterType === 'text' ? model.filter ?? '' : '';
}

/**
 * Extract set filter values from filter model
 */
export function extractSetFilter(model?: ColumnFilterModel): string[] {
  if (model?.filterType === 'set' && model.values?.length > 0) {
    const values = model.values as string[];
    return values.map((v) => {
      if (v === 'Empty') {
        return EMPTY_VALUE_INDICATOR;
      }
      return v;
    });
  }
  return [];
}

/**
 * Hook to create a server-side datasource for annotation categories
 */
export function useAnnotationCategoriesDatasource(
  queryDocument: DocumentNode
): IServerSideDatasource<AnnotationCategoryData> {
  const client = useApolloClient();
  const { addSnackbar } = useSnackbarMutations();

  return useMemo(() => {
    const datasource: IServerSideDatasource<AnnotationCategoryData> = {
      getRows: async (params) => {
        try {
          const {
            startRow: maybeStartRow,
            endRow: maybeEndRow,
            sortModel,
            filterModel,
          } = params.request;

          const { page, pageSize } = getAgGridPaginationConfig(
            maybeStartRow,
            maybeEndRow
          );
          const fm = filterModel as FilterModel;

          const search = extractTextFilter(fm['name']);
          const setNames = extractSetFilter(fm['setName']);
          const setCodes = extractSetFilter(fm['setCode']);
          const setModules = extractSetFilter(fm['setModule']);

          const variables = {
            page,
            pageSize,
            search,
            setNames,
            setCodes,
            setModules,
            sortBy: sortModel?.[0]?.colId || 'name',
            sortDirection: sortModel?.[0]?.sort || 'asc',
          };

          const { data, error } = await client.query({
            query: queryDocument,
            variables,
            fetchPolicy: 'network-only',
          });

          if (error) {
            addSnackbar({
              type: 'error',
              message: error.message,
            });
            params.fail();
            return;
          }

          const rowData = data?.annotationCategories?.nodes ?? [];
          const totalElements =
            data?.annotationCategories?.pageInfo.totalElements ??
            rowData.length;

          params.success({ rowData, rowCount: totalElements });
        } catch {
          params.fail();
        }
      },
    };
    return datasource;
  }, [client, queryDocument, addSnackbar]);
}
