import { Icon } from '@aignostics/components';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import { $UploadedRatioContainer } from './BatchListColumn.styles';

export const UploadedFilesRatioCell = (
  props: CustomCellRendererProps
): ReactElement | null => {
  const batchItem = props.data;
  if (!batchItem) {
    return null;
  }
  const { totalSlideFiles } = batchItem;
  return (
    <$UploadedRatioContainer>
      {totalSlideFiles}
      <Icon icon="Image" size="small" />
    </$UploadedRatioContainer>
  );
};
