import { FilterField } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import { OnboardingBatchStatus } from '../../../graphql/api.types';

type FilterKeysWithoutAssociation =
  | 'name'
  | 'createdBy'
  | 'status'
  | 'uploadingDate';
type FilterKeysWithAssociation = FilterKeysWithoutAssociation | 'association';
export type FilterKeys =
  | FilterKeysWithoutAssociation
  | FilterKeysWithAssociation;

type FilterRecordWithoutAssociation = Record<
  FilterKeysWithoutAssociation,
  FilterField
>;
type FilterRecordWithAssociation = Record<
  FilterKeysWithAssociation,
  FilterField
>;
export type FilterRecord =
  | FilterRecordWithoutAssociation
  | FilterRecordWithAssociation;

type StatusMessage = { message: string; color: keyof Theme['colors'] };

const completedStatusMessage: StatusMessage = {
  message: 'Completed',
  color: 'text',
};
export const mapStatusToMessage: Record<OnboardingBatchStatus, StatusMessage> =
  {
    uploading: {
      message: 'Uploading slides...',
      color: 'text',
    },
    pending: {
      message: 'Batch uploaded',
      color: 'text',
    },
    validating: {
      message: 'Processing...',
      color: 'dark',
    },
    failed: completedStatusMessage,
    completed: completedStatusMessage,
    'completed-with-errors': completedStatusMessage,
  };
