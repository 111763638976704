import type {
  MultiChannelFormRow,
  SingleChannelFormRow,
  TissueBlockCaseIdTriple,
} from './form.state.types';
import { getFirstMatchingTripleOrWsisGroup } from './getFirstMatchingTripleOrWsisGroup';

/**
 * generates an array of unique tissue - block - caseName triples from an array of slides
 * @param slides an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @returns      an array of unique TissueBlockCaseIdTriple type
 */

export const getUniqueTissueBlockCaseIdTriples = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): TissueBlockCaseIdTriple[] =>
  slides.reduce((acc: TissueBlockCaseIdTriple[], slide) => {
    const matchingPair = getFirstMatchingTripleOrWsisGroup(slide, acc);
    const { tissue, block, caseName } =
      slide.type !== 'single-channel' ? slide.parentAssigned : slide;
    // TODO: [FE-5615]: Rename caseId to caseName in Onboarding API
    return matchingPair ||
      // don't add slides with unset tissues or empty case names to the list
      tissue === null ||
      caseName === ''
      ? acc
      : [...acc, { tissue, block, caseId: caseName }];
  }, []);
