import * as Icons from '@aignostics/icons';
import React from 'react';
import { Tooltip } from '../Tooltip';
import { $Tag } from './Tag.styles';
/**
 * Tag component with optional icon.
 */
const Tag = ({ icon, name, color, tooltip, ...props }) => {
    // Get icon component from `FeatherIcons`
    const Icon = icon && Icons[icon];
    return (React.createElement(Tooltip, { content: tooltip },
        React.createElement($Tag, { "aria-label": tooltip, "data-testid": "tag", color: color, ...props },
            Icon && React.createElement(Icon, { size: 12, strokeWidth: 1.5 }),
            name)));
};
export default Tag;
