import {
  ProgressBar,
  Section,
  useSnackbarMutations,
  VStack,
} from '@aignostics/components';
import { type WsiSlideMetadata } from '@aignostics/extract-wsi-metadata';
import { pluralize } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState, type ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { FETCH_PARSED_CHANNEL_METADATA } from './FETCH_PARSED_CHANNEL_METADATA';
import { CloudFileInfo } from './utils/getFileInfoFromSource';
import { mapHERGBtoHE } from './utils/mapHERGBtoHE';

export type OnboardingCloudFileWithMetadata = CloudFileInfo & {
  metadata: WsiSlideMetadata;
};
interface RequestMetadataStepProps {
  files: CloudFileInfo[];
  onFetchedMetadata: (
    filesWithMetadata: OnboardingCloudFileWithMetadata[]
  ) => void;
}

const FILES_PER_REQUEST = 100;

export const RequestMetadataStep = ({
  files,
  onFetchedMetadata,
}: RequestMetadataStepProps): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const theme = useTheme();
  const [metadata, setMetadata] = useState<WsiSlideMetadata[]>([]);
  const filesRef = useRef(files);
  const chunk = filesRef.current.slice(0, FILES_PER_REQUEST);
  const [isProgress, setIsProgress] = useState(false);

  const parsingFiles = chunk.map((file) => file.path);
  useQuery<{
    onboardingParser: { parsedMetadata: WsiSlideMetadata[] };
  }>(FETCH_PARSED_CHANNEL_METADATA, {
    variables: {
      paths: parsingFiles,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (filesRef.current.length > FILES_PER_REQUEST) {
        filesRef.current = filesRef.current.slice(FILES_PER_REQUEST);
      }

      setMetadata((metadata) => [
        ...metadata,
        ...data.onboardingParser.parsedMetadata,
      ]);
    },
    onError(error) {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });

  useEffect(() => {
    if (metadata.length === files.length && !isProgress) {
      const mappedMetadata = mapHERGBtoHE(metadata);
      onFetchedMetadata(
        files.map((file, fileIndex) => ({
          ...file,
          metadata: mappedMetadata[fileIndex],
        }))
      );
    }
  }, [metadata, files, onFetchedMetadata, isProgress]);

  return (
    <Section>
      <VStack spacing="large">
        <span>{`Requesting metadata for total of ${
          files.length
        } slide ${pluralize('file', files.length)}.`}</span>

        <ProgressBar
          width={theme.breakpoints.FULL}
          value={metadata.length / files.length}
          valueLabel={metadata.length}
          onProgressStart={() => {
            setIsProgress(true);
          }}
          onProgressEnd={() => {
            setIsProgress(false);
          }}
        />
      </VStack>
    </Section>
  );
};
