export const MULTIPLE_STAININGS_SEPARATOR = '|';
export const REQUIRED_COLUMNS = [
  'Filename',
  'Scanner',
  'Staining',
  'Localization',
  'Patient ID',
  'Case Name',
  'Block',
  'Section',
  'Disease',
  'Preparation Type',
  'Sample Type',
] as const;
/** mapping parser csv columns back to form state columns */

export const newColumnMapping = new Map<string, string>([
  ['Filename', 'filename'],
  ['scannerId', 'scannerId'],
  ['Staining', 'staining'],
  ['Localization', 'tissue'],
  ['Patient ID', 'patientExternalId'],
  ['Case Name', 'caseName'],
  ['Block', 'block'],
  ['Section', 'section'],
  ['Disease', 'disease'],
  ['Preparation Type', 'samplePreparation'],
  ['Sample Type', 'sampleType'],
  ['parent_tma_row', 'parentTmaRow'],
  ['parent_tma_col', 'parentTmaCol'],
  ['parent_slide_pos_x', 'parentSlidePosX'],
  ['parent_slide_pos_y', 'parentSlidePosY'],
  ['parent_wsi_uuid', 'parentWsiUuid'],
  ['wsi_uuid', 'wsiUuid'],
  ['case_uuid', 'caseUuid'],
  ['Morphology', 'morphology'],
  ['Cancer Site', 'cancerSite'],
]);
const OPTIONAL_COLUMNS_STRING = ['Morphology', 'Cancer Site'] as const;
export const OPTIONAL_COLUMNS_INT32 = [
  'parent_tma_row',
  'parent_tma_col',
  'parent_slide_pos_x',
  'parent_slide_pos_y',
] as const;
export const OPTIONAL_COLUMNS_UUID = [
  'parent_wsi_uuid',
  'wsi_uuid',
  'case_uuid',
] as const;
export const OPTIONAL_COLUMNS = [
  ...OPTIONAL_COLUMNS_STRING,
  ...OPTIONAL_COLUMNS_INT32,
  ...OPTIONAL_COLUMNS_UUID,
] as const;
