import { gql } from '@apollo/client';

export const FETCH_ADMIN_PROJECT_USERS = gql`
  query GET_ADMIN_PROJECT_USERS(
    $search: String
    $searchEmail: String
    $page: Int
    $pageSize: Int
    $projectId: ID!
    $projectAssigned: String
  ) {
    users(
      search: $search
      searchEmail: $searchEmail
      page: $page
      pageSize: $pageSize
      projectId: $projectId
      projectAssigned: $projectAssigned
    ) {
      nodes {
        id
        name
        email
        inParent(projectId: $projectId)
        isNewUser
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
      collectionAttributes {
        availableUsers
      }
    }
  }
`;
