import { MULTIPLE_STAININGS_SEPARATOR } from '../const';
import { type CSVParseResultWSIRow } from '../types';

export function splitMIFChannels({ Staining }: CSVParseResultWSIRow): string[] {
  const mifChannelsStainings = Staining
    ? Staining.split(MULTIPLE_STAININGS_SEPARATOR)
    : [];

  return mifChannelsStainings;
}
