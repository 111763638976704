import { useEffect, useRef } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';
import {
  UPLOADING_FILE_STATUS,
  useUploadFilesContext,
} from '../UploadStateProvider';

const onBeforeUnload = (event: BeforeUnloadEvent): void => {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = '';
};

export const UploadWakeAndNavigationLock = (): null => {
  const { uploadState } = useUploadFilesContext();
  const isLockHadBeenAcquiredAtLeastOnce = useRef(false);
  const { isSupported, released, request, release } = useWakeLock();

  useEffect(() => {
    if (
      uploadState.status === UPLOADING_FILE_STATUS.UPLOADING ||
      uploadState.status === UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD
    ) {
      if (isSupported && [true, undefined].includes(released)) {
        isLockHadBeenAcquiredAtLeastOnce.current = true;
        void request();
      }
      window.addEventListener('beforeunload', onBeforeUnload, true);
    } else if (uploadState.status === UPLOADING_FILE_STATUS.IDLE) {
      if (
        isSupported &&
        isLockHadBeenAcquiredAtLeastOnce.current &&
        !released
      ) {
        void release();
      }
      window.removeEventListener('beforeunload', onBeforeUnload, true);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload, true);
    };
  }, [uploadState, isSupported, request, release, released]);

  return null;
};
