import { AgGridReact } from 'ag-grid-react';
import React, { type ReactElement } from 'react';
import { $BatchesGridWrapper } from './BatchesGrid.styles';
import type { GridDataBatches } from './BatchesGrid.types';
import {
  useBatchesGridProps,
  type BatchesGridHookParams,
} from './BatchesGrid.utils';

type BatchesGridProps = BatchesGridHookParams & {
  supressAgGridVirtualization?: boolean;
};

export const BatchesGrid = ({
  supressAgGridVirtualization,
  ...params
}: BatchesGridProps): ReactElement => (
  <$BatchesGridWrapper>
    <AgGridReact<GridDataBatches>
      {...useBatchesGridProps(params)}
      suppressColumnVirtualisation // so autosizing works for every column
      suppressRowVirtualisation={supressAgGridVirtualization}
    />
  </$BatchesGridWrapper>
);
