import { FirstDataRenderedEvent, GridApi } from 'ag-grid-enterprise';
import { useCallback, useState } from 'react';

export function useGridAPI<T>(): [
  GridApi | null,
  (params: FirstDataRenderedEvent<T>) => void,
] {
  const [gridAPI, setGridAPI] = useState<GridApi | null>(null);

  const handleGridReady = useCallback((params: FirstDataRenderedEvent<T>) => {
    setGridAPI(params.api);
  }, []);

  return [gridAPI, handleGridReady];
}
