import {
  Button,
  DeprecatedContainer,
  Form,
  FormField,
  HStack,
  Section,
  ToggleItem,
  VStack,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { formatDate } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { WsiThumbnail } from '../../../components';
import { Project } from '../../../types';
import { GET_ADMIN_PROJECT_DETAILS_TAB } from './FETCH_ADMIN_PROJECT_DETAILS';
import { useInviteAssignedUsersToProject } from './useInviteAssignedUsersToProject';
import { useSetProjectVisibility } from './useSetProjectVisibility';
import { useUpdateProject } from './useUpdateProject';

const $Thumbnail = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => `${theme.spacings.tile}px`};
  width: ${({ theme }) => `${theme.spacings.tile}px`};
`;

const $Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => `${theme.spacings.tile}px`};
  border: 1px solid ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.spacings.radius}px;
`;

interface AdminProjectDetailsProps {
  organizationUuid: string;
  userRole: OrganizationRole;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

const AdminProjectDetails = ({
  organizationUuid,
  userRole,
  rasterTileServerUrl,
  getToken,
}: AdminProjectDetailsProps): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data,
    loading: fetchProjectDetailsLoading,
    error: fetchProjectDetailsError,
  } = useQuery<{
    project: Project;
  }>(GET_ADMIN_PROJECT_DETAILS_TAB, {
    variables: { projectId, isAdminView: true },
  });

  const canEdit = userRole.scopes['project:edit'];
  const project = data?.project;

  const setProjectVisibility = useSetProjectVisibility();

  const {
    execute: inviteAssignedUsersToProject,
    loading: inviteAssignedUsersLoading,
  } = useInviteAssignedUsersToProject(organizationUuid);

  const { updateProject, loading: updateProjectLoading } = useUpdateProject(
    projectId as string
  );

  const handleInviteAssignedUsers = () => {
    if (project === undefined) return;

    inviteAssignedUsersToProject(project.id)
      .then((result) => {
        if (result.errors.length === 0) {
          addSnackbar({
            type: 'success',
            message: `Invitations sent`,
          });
        } else {
          addSnackbar({
            type: 'error',
            message: `${result.errors.length} invitations could not be sent, please check all assigned users have an email address set.`,
          });
        }
      })
      .catch(() => {
        addSnackbar({
          type: 'error',
          message: 'Error sending invitations',
        });
      });
  };

  const handleProjectVisibility = (project: Project, value: boolean) => {
    void setProjectVisibility(project.id, value);
  };

  const formFields = useMemo<FormField[]>(
    () => [
      {
        type: 'text',
        id: 'id',
        label: 'ID',
        value: project?.id || '',
        disabled: true,
        copy: true,
      },
      {
        type: 'text',
        id: 'name',
        label: 'Name',
        value: project?.name || '',
        isRequired: true,
        disabled: !canEdit,
      },
      {
        type: 'textarea',
        id: 'description',
        label: 'Description',
        value: project?.description || '',
        disabled: !canEdit,
      },
      {
        type: 'text',
        id: 'createdBy',
        label: 'Created by',
        value: project?.createdBy?.name || '',
        disabled: true,
      },
      {
        type: 'text',
        id: 'createdAt',
        label: 'Created at',
        value: project ? formatDate(project.createdAt) : '',
        disabled: true,
      },
      {
        type: 'text',
        id: 'updatedAt',
        label: 'Updated',
        value: project ? formatDate(project?.updatedAt) : '',
        disabled: true,
      },
    ],
    [project, canEdit]
  );

  const loading =
    fetchProjectDetailsLoading ||
    updateProjectLoading ||
    inviteAssignedUsersLoading;

  return (
    <Section
      title="Edit Project"
      loading={loading}
      error={fetchProjectDetailsError}
    >
      {project !== undefined ? (
        <HStack>
          <DeprecatedContainer size="small">
            <VStack spacing="large" alignItems="stretch">
              {canEdit && (
                <ToggleItem
                  label="Visible for users"
                  checked={project?.isVisible}
                  onChange={(value) => {
                    handleProjectVisibility(project, value);
                  }}
                />
              )}

              <Button
                banner
                onClick={handleInviteAssignedUsers}
                disabled={!project.isVisible}
              >
                Invite all assigned users
              </Button>

              {/* Project first wsi preview */}
              {project.thumbnailWsiId && (
                <$Container>
                  <$Thumbnail>
                    <WsiThumbnail
                      wsiId={project.thumbnailWsiId}
                      getToken={getToken}
                      rasterTileServerUrl={rasterTileServerUrl}
                    />
                  </$Thumbnail>
                </$Container>
              )}

              {/* Edit project entity */}
              <Form
                onSubmit={updateProject}
                loading={updateProjectLoading}
                fields={formFields}
                reset
              />
            </VStack>
          </DeprecatedContainer>
        </HStack>
      ) : null}
    </Section>
  );
};

export default AdminProjectDetails;
