import { PageLayout, Section } from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import React, { ReactElement } from 'react';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import useUserAssignedAssociation from '../../hooks/useUserAssignedAssociation';

import { BatchList, OnboardingSlideButtons } from './components';

type AdminDataOnboardingProps = {
  externalBucket: string | null;
  role: OrganizationRole;
  currentUser: User;
};

export const DataOnboardingBatchList = ({
  currentUser,
  role,
  externalBucket,
}: AdminDataOnboardingProps): ReactElement => {
  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();

  return (
    <>
      <PageLayout
        title="Data Onboarding"
        description="Overview, status and progress of onboarding batches."
        loading={userAssignedAssociationLoading}
        currentOrganization={role.organization.name}
      >
        <Section>
          <OnboardingSlideButtons externalBucket={externalBucket} />
          <BatchList
            role={role}
            currentUser={currentUser}
            userAssignedAssociation={userAssignedAssociation}
          />
        </Section>
      </PageLayout>

      {/*Modal components*/}
      <OnboardingTutorialModal />
    </>
  );
};
