import {
  Button,
  HStack,
  Input,
  LoaderBar,
  Modal,
  Placeholder,
  TableSkeleton,
  VStack,
} from '@aignostics/components';
import { useDebounce } from '@aignostics/hooks';
import { useQuery } from '@apollo/client';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { compareFilenames } from '../UploadSlides';
import { CloudSlideList } from './CloudSlideList';
import FETCH_ONBOARDING_CLOUD_FILES, {
  OnboardingCloudBucket,
} from './FETCH_ONBOARDING_CLOUD_FILES';
import TransferCloudSlidesCrumbs from './TransferCloudSlidesCrumbs.component';
import { extractBucketName } from './utils/extractBucketName';
import { generateFolders } from './utils/generateFolders';
import { generateMrxsFolderPaths } from './utils/generateMrxsFolderPaths';
import {
  CloudFileInfo,
  getFilesInfoFromSource,
} from './utils/getFileInfoFromSource';

const ContentWrapper = styled.div`
  overflow: auto;
  width: ${({ theme }) => theme.breakpoints.FULL}px;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacings.button}px;
`;

const ModalTitle = styled.h1`
  ${({ theme }) => theme.fontStyles.displayBold};
  text-align: left;
  margin-bottom: 48px;
`;

const TransferCloudSlidesTableHeader = styled.div`
  margin-bottom: 24px;
`;

const ContentFooter = styled.div`
  text-align: center;
  margin-top: 24px;
`;

interface TransferCloudSlidesModalProps {
  isVisible: boolean;
  onClose: () => void;
  externalBucket: string | null;
}

const TransferCloudSlidesModal = ({
  isVisible,
  onClose,
  externalBucket,
}: TransferCloudSlidesModalProps): ReactElement => {
  const [inputUrl, setInputUrl] = useState(
    externalBucket ? `gs://${externalBucket}` : ''
  );
  const organizationUuid = useSelectedOrganizationUuid();

  const debouncedInputUrl = useDebounce(inputUrl);
  const bucket = useMemo(
    () => extractBucketName(debouncedInputUrl),
    [debouncedInputUrl]
  );

  const path =
    debouncedInputUrl && bucket ? debouncedInputUrl.split(bucket)[1] ?? '' : '';

  const { data, error, loading } = useQuery<{
    onboardingListCloudFiles: OnboardingCloudBucket;
  }>(FETCH_ONBOARDING_CLOUD_FILES, {
    variables: {
      path,
    },
    fetchPolicy: 'no-cache',
  });

  const responseFiles = getFilesInfoFromSource(
    data?.onboardingListCloudFiles?.files ?? []
  );
  const responsePrefixes = data?.onboardingListCloudFiles?.prefixes;

  const mrxsFolderPaths = useMemo(() => {
    if (!responseFiles) return [];
    return generateMrxsFolderPaths(responseFiles);
  }, [responseFiles]);

  const files = useMemo(() => {
    return [
      ...(responseFiles ?? []),
      ...(responsePrefixes
        ? generateFolders(responsePrefixes, mrxsFolderPaths)
        : []),
    ];
  }, [responseFiles, responsePrefixes, mrxsFolderPaths]);

  const [selectedFiles, setSelectedFiles] = useState<CloudFileInfo[]>([]);

  // reset file selection on folder change
  useEffect(() => {
    setSelectedFiles([]);
  }, [debouncedInputUrl]);

  const navigate = useNavigate();

  const onPathUpdate = useCallback(
    (path: string) => {
      const updatedPath = `${bucket}${path}`;
      setInputUrl(updatedPath);
    },
    [setInputUrl, bucket]
  );

  const onSelectedFilesUpdate = useCallback(
    (updatedFiles: CloudFileInfo[]) => {
      setSelectedFiles(updatedFiles);
    },
    [setSelectedFiles]
  );

  const handleSubmit = () => {
    if (selectedFiles.length > 0) {
      const files = selectedFiles
        .slice()
        .sort(({ filename: filenameA }, { filename: filenameB }) =>
          // sort files in a stable order
          // slides returned from backend are sorted by slide names too
          compareFilenames(filenameA, filenameB)
        );
      void navigate(
        `/${organizationUuid}/admin/data-onboarding/transfer-slides`,
        {
          state: { files },
        }
      );
    }
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 40,
          left: 0,
          width: '100%',
          height: '20px',
          zIndex: 10,
        }}
      >
        <LoaderBar loading={loading} />
      </div>
      <Modal
        shouldCloseOnEscKey
        hasCloseButton
        aria-label="Transfer Cloud Modal"
        isVisible={isVisible}
        onClose={onClose}
      >
        <ContentWrapper>
          <ModalTitle>Select Slides</ModalTitle>
          <TransferCloudSlidesTableHeader>
            <Input
              id="bucket-name-input"
              type="text"
              value={inputUrl.split(bucket)[1]}
              onChange={(val) => {
                setInputUrl(bucket + val);
              }}
              placeholder="Enter path..."
              staticPrefix={bucket}
              role="textbox"
            />
          </TransferCloudSlidesTableHeader>
          {!loading && !error && (
            <>
              {bucket.length > 0 && path.length > 0 && (
                <TransferCloudSlidesCrumbs
                  bucket={bucket}
                  path={path}
                  onPathUpdate={onPathUpdate}
                />
              )}
              {files.length > 0 ? (
                <CloudSlideList
                  files={files}
                  selectedFiles={selectedFiles}
                  onPathUpdate={onPathUpdate}
                  onSelectedFilesUpdate={onSelectedFilesUpdate}
                />
              ) : (
                <HStack style={{ justifyContent: 'center' }}>
                  <Placeholder
                    title="No slides"
                    description={`No slides found in this ${bucket} in this path: ${path}`}
                  />
                </HStack>
              )}
            </>
          )}
          {loading && (
            <VStack style={{ marginTop: '16px' }}>
              <TableSkeleton rows={4} />
            </VStack>
          )}
          {error && (
            <HStack style={{ justifyContent: 'center' }}>
              <Placeholder title="No slides" description={error.message} />
            </HStack>
          )}
          <ContentFooter>
            <Button
              disabled={selectedFiles.length === 0}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ContentFooter>
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default TransferCloudSlidesModal;
