import { ColDef } from 'ag-grid-enterprise';
import type { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';
import type { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { Association } from '../../../../types';

import { FilterOptions } from '../../hooks/useDataOnboardingFiltersOptions';
import { ActionCell } from './ActionCell';
import { BatchNameCell } from './BatchNameCell';
import { getAssociationColDef } from './getAssociationColDef';
import {
  getUploadedByFilterValues,
  getUploadingDateFilterRange,
} from './helpers';
import { StatusCell } from './StatusCell';
import { UploadedFilesRatioCell } from './UploadedFilesRatioCell';
import {
  generateUploadedByFilterFormatter,
  statusFormatter,
  uploadedByFormatter,
  uploadingDateFormatter,
} from './valueFormatters';

type Params = {
  userAssignedAssociation: Pick<Association, 'name'> | null;
  onAssignToProject: (data?: string) => void;
  filterOptions: FilterOptions;
  refreshGrid: () => void;
};

// get the column definition for the onboarding batch list
export const getBatchListColumnDef = ({
  userAssignedAssociation,
  onAssignToProject,
  filterOptions,
  refreshGrid,
}: Params): ColDef<OnboardingBatchItem>[] => {
  // TODO: Why we are fetching a list of uploadDate?
  const uploadingDateFilterRange = getUploadingDateFilterRange(
    filterOptions.uploadDates
  );
  const uploadedByFilterValues = getUploadedByFilterValues(filterOptions.users);
  const uploadedByFilterFormatter = generateUploadedByFilterFormatter(
    filterOptions.users
  );

  const associationColDef: ColDef<OnboardingBatchItem>[] = getAssociationColDef(
    {
      userAssignedAssociation,
      associationFilterOptions: filterOptions.associations,
    }
  );

  return [
    {
      headerName: 'Batch Name',
      field: 'batchName',
      cellRenderer: BatchNameCell,
      filter: 'agTextColumnFilter',
      sortable: false,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    },
    ...associationColDef,
    {
      headerName: 'Uploading date',
      field: 'createdAt',
      sortable: false,
      valueFormatter: uploadingDateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: ['inRange'],
        maxNumConditions: 1,
        minValidDate: uploadingDateFilterRange?.from,
        maxValidDate: uploadingDateFilterRange?.to,
        buttons: ['reset'],
      },
    },
    {
      headerName: 'Files',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: UploadedFilesRatioCell,
    },
    {
      headerName: 'Uploaded by',
      field: 'createdBy',
      sortable: false,
      valueFormatter: uploadedByFormatter,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: uploadedByFilterValues,
        valueFormatter: uploadedByFilterFormatter,
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      sortable: false,
      cellRenderer: StatusCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: filterOptions.statuses,
        valueFormatter: statusFormatter,
      },
      tooltipValueGetter: (params) => {
        if (params.value === 'failed') {
          return 'All slides failed processing';
        } else return '';
      },
    },
    {
      headerName: 'Actions',
      sortable: false,
      cellRenderer: (props: CustomCellRendererProps<OnboardingBatchItem>) => (
        <ActionCell
          {...props}
          onAssignToProject={onAssignToProject}
          refreshGrid={refreshGrid}
        />
      ),
    },
  ];
};
