export const dropFirstLine = async (
  fileOrText: File | string
): Promise<string> => {
  const text =
    typeof fileOrText === 'string' ? fileOrText : await fileOrText.text();
  const newLineIdx = text.indexOf('\n');

  if (newLineIdx !== -1) {
    const res = text.slice(newLineIdx + 1);
    return res;
  }

  return '';
};
