import { useKeyPress } from '@aignostics/hooks';
import React, { memo } from 'react';
import Icon from '../Icon';
import { Tooltip } from '../Tooltip';
import getTooltip from '../utils/getTooltip';
import { $IconButton, $IconWrap } from './IconButton.styles';
/**
 * Reusable icon button component
 */
const IconButton = ({ onClick, icon, color, style, iconStyle, type = 'button', size = 'button', disabled, description, onKey, componentTheme = 'transparent', ...props }) => {
    // Apply keyboard handler
    useKeyPress(onKey, onClick, description);
    const tooltip = getTooltip(description, onKey);
    return (React.createElement(Tooltip, { content: tooltip },
        React.createElement($IconButton, { style: style, onClick: onClick, disabled: disabled, type: type, size: size, "aria-label": description, componentTheme: componentTheme, ...props },
            React.createElement($IconWrap, { style: iconStyle },
                React.createElement(Icon, { role: "presentation", icon: icon, color: color, size: size })))));
};
export default memo(IconButton);
