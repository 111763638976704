import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import OneLiner from '../../OneLiner';
import { HStack } from '../../Stack';
import { Tooltip } from '../../Tooltip';
import { pluralize } from '../../utils/pluralize';
import GridItem from '../GridItem.component';
const GridItemSlide = ({ title, image, to, onClick, disabled, annotationsCount, taggersCount, overlaysCount, brightfieldCount, fluorescenceCount, regionsOfInterestCount, infoModalDisclosure, }) => {
    const theme = useTheme();
    const footer = (React.createElement(HStack, { justifyContent: "flex-start", alignItems: "center", style: {
            gap: '12px',
            ...theme.fontStyles.small,
            borderTop: '1px solid #EDEDED',
            paddingTop: '12px',
            marginTop: '4px',
        } },
        React.createElement(Tooltip, { content: pluralize('BrightField', brightfieldCount) },
            React.createElement("div", { role: "section", "aria-label": "Count of brightfield layers" },
                React.createElement(HStack, { spacing: "small", alignItems: "center" },
                    React.createElement(Icon, { icon: "BrightField", size: "input" }),
                    React.createElement("div", null, brightfieldCount ?? 0)))),
        React.createElement(Tooltip, { content: pluralize('Fluorescence', fluorescenceCount) },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "Fluorescence", size: "input" }),
                React.createElement("div", null, fluorescenceCount ?? 0))),
        React.createElement(Tooltip, { content: pluralize('static overlay', overlaysCount) },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "Layers", size: "input" }),
                React.createElement("div", null, overlaysCount))),
        React.createElement(Tooltip, { content: pluralize('interactive overlay', taggersCount) },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "Disc", size: "input" }),
                React.createElement("div", null, taggersCount))),
        React.createElement(Tooltip, { content: pluralize('annotation', annotationsCount) },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "Tag", size: "input" }),
                React.createElement("div", null, annotationsCount))),
        React.createElement(Tooltip, { content: (regionsOfInterestCount ?? 0) > 1
                ? 'Regions of Interest'
                : 'Region of Interest' },
            React.createElement(HStack, { spacing: "small", alignItems: "center" },
                React.createElement(Icon, { icon: "RegionOfInterest", size: "input" }),
                React.createElement("div", null, regionsOfInterestCount)))));
    return (React.createElement(GridItem, { header: React.createElement(OneLiner, { tooltip: title }, title), title: title, to: to, image: image, disabled: disabled, onClick: onClick, footer: footer, floatingTags: React.createElement(IconButton, { description: "Open slide metadata", icon: "FileText", size: "button", iconStyle: {
                borderRadius: `${theme.spacings[4]}px`,
                padding: `${theme.spacings[2]}px 1px`,
                backgroundColor: theme.colors.white,
            }, onClick: (e) => {
                e?.preventDefault();
                infoModalDisclosure.open();
            } }) }));
};
export default GridItemSlide;
