import {
  Button,
  HStack,
  Modal,
  Section,
  Text,
  VStack,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import { CanDeleteAnnotationCategory } from '../AnnotationCategoriesTableRowActions.component';

export const CONFIRM_UPDATE_MODAL_MAX_WIDTH = 415;
export const CATEGORY_NAME_CHANGE_IS_GLOBAL_WARNING = `The category name change will be applied globally across all projects using
    this category`;
export const CATEGORY_NAME_CHANGE_SUBPROJECT_ASSIGNED_WARNING = ` and will affect at least one subproject.`;
export const ARE_YOU_SURE_TEXT = ` Are you sure you want to proceed?`;

export type ConfirmUpdateAnnotationCategoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  canDeleteAnnotationCategory: CanDeleteAnnotationCategory | null;
  onUpdateAnnotationCategory: () => void;
};
export const ConfirmUpdateAnnotationCategoryModal = ({
  isOpen,
  onClose,
  loading,
  canDeleteAnnotationCategory,
  onUpdateAnnotationCategory,
}: ConfirmUpdateAnnotationCategoryModalProps): ReactElement => {
  return (
    <Modal onClose={onClose} isVisible={isOpen} hasCloseButton>
      <Section
        loading={loading}
        style={{ maxWidth: `${CONFIRM_UPDATE_MODAL_MAX_WIDTH}px` }}
      >
        <VStack alignItems="center" spacing="16">
          <Text as="h2" fontStyle="displayBold">
            Category name change
          </Text>
          <Text fontStyle="small" style={{ textAlign: 'center' }}>
            {CATEGORY_NAME_CHANGE_IS_GLOBAL_WARNING}
            {canDeleteAnnotationCategory?.isAssignedToSubproject
              ? CATEGORY_NAME_CHANGE_SUBPROJECT_ASSIGNED_WARNING
              : '.'}
            {ARE_YOU_SURE_TEXT}
          </Text>
          <HStack spacing="16">
            <Button variant="primary" onClick={onUpdateAnnotationCategory}>
              Confirm change
            </Button>
            <Button variant="primaryOutline" onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </VStack>
      </Section>
    </Modal>
  );
};
