import { Pill } from '@aignostics/components';
import styled from 'styled-components';

export const $GridWrapper = styled.div`
  height: 50vh;
`;

export const $PillDefault = styled(Pill)`
  margin-right: ${({ theme }) => `${theme.spacings[4]}px`};
  cursor: default;
`;

export const $PillPointer = styled($PillDefault)`
  cursor: pointer;
`;
