import { ONBOARDING_STATUS_ICON_ICON_SIZE } from '@aignostics/components';
import styled from 'styled-components';

export const ICON_WRAPPER_STYLES = {
  verticalAlign: 'middle',
  marginRight: '10px',
} as const;

export const $SlideName = styled.span`
  display: inline-flex;
  flex-direction: column;
  line-height: 18px;
  vertical-align: middle;
`;

export const $Filesize = styled.span`
  color: ${({ theme }) => theme.colors.mid};
  ${({ theme }) => theme.fontStyles.tiny};
`;

export const $StatusIconPlaceholder = styled.span`
  display: inline-block;
  vertical-align: ${ICON_WRAPPER_STYLES.verticalAlign};
  width: ${ONBOARDING_STATUS_ICON_ICON_SIZE}px;
  height: ${ONBOARDING_STATUS_ICON_ICON_SIZE}px;
  margin-right: ${ICON_WRAPPER_STYLES.marginRight};
`;
