import { Loader, Section } from '@aignostics/components';
import { useMutation } from '@apollo/client';
import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { TRANSFER_ONBOARDING_BATCH } from './TRANSFER_ONBOARDING_BATCH';

export const TransferFilesStep = ({
  batchId,
}: {
  batchId: string;
}): ReactElement => {
  const navigate = useNavigate();
  const [transfer] = useMutation<void, { onboardingBatchId: string }>(
    TRANSFER_ONBOARDING_BATCH
  );
  const organizationUuid = useSelectedOrganizationUuid();

  useEffect(() => {
    if (batchId) {
      void transfer({
        variables: { onboardingBatchId: batchId },
      });
      void navigate(
        `/${organizationUuid}/admin/data-onboarding/batch/${batchId}`
      );
    }
  }, [batchId, transfer, navigate, organizationUuid]);

  return (
    <Section>
      <div style={{ display: 'flex' }}>
        Transferring Slides
        <Loader />
      </div>
    </Section>
  );
};
