import {
  Button,
  DropdownMenu,
  HStack,
  Modal,
  Pagination,
  PaginationInfo,
  ProgressBar,
  Section,
  TableSkeleton,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useDisclosure,
  useFilters,
  usePagination,
  useSnackbarMutations,
} from '@aignostics/components';
import { useDebounce, useSetQueryParams } from '@aignostics/hooks';
import { Theme } from '@aignostics/theme';
import { buildClientWsiUrl, contrast, pluralize } from '@aignostics/utils';
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { AdminSubProjectSlidesAssignment } from './AdminSubProjectSlidesAssignment';
import { AdminSubProjectSlidesImportForm } from './AdminSubProjectSlidesImportForm';
import { AssignedSlidesTable } from './AssignedSlidesTable/AssignedSlidesTable';

import { OrganizationRole } from '@aignostics/core';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { WsiMetadataModal } from '../../../../components';
import {
  SortByDirection,
  prefix,
  useLayoutMode,
  useSortBy,
} from '../../../../hooks';
import {
  RegistrationGroups,
  SubProject,
  SubProjectWsisFilterValues,
  Wsi,
} from '../../../../types';
import {
  downloadReport,
  getAnnotationProgress,
  getQueryParams,
} from '../../../../utils';
import { FETCH_SUBPROJECT_WSIS } from '../../FETCH_SUBPROJECT_WSIS';
import { SortByOptions } from '../../Subproject.types';
import { FETCH_INTERACTIVE_OVERLAYS_MATRIX } from '../AdminSubProjectAssignInteractiveOverlays/FETCH_INTERACTIVE_OVERLAYS_MATRIX';
import { AdminSubProjectWsisFilters } from '../AdminSubProjectWsisFilters.component';
import {
  FilterKeysWithAssociation,
  PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
  PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST,
} from './AdminSubprojectSlides.type';
import EditStainingsModal from './EditStainingsModal/EditStainingsModal.component';
import { FETCH_SUB_PROJECT_REGISTRATION_GROUPS } from './FETCH_SUB_PROJECT_REGISTRATION_GROUPS';
import { IMPORT_WSIS_FROM_SUB_PROJECT } from './IMPORT_WSIS_FROM_SUB_PROJECT.queries';
import { ImageRegistrationModal } from './ImageRegistrationModal/ImageRegistrationModal.component';
import {
  READ_SUBPROJECT_SLIDES,
  READ_SUBPROJECT_SLIDES_VARIABLES,
} from './READ_SUBPROJECT_SLIDES.queries';
import { LIST_SUB_PROJECT_SLIDES } from './RemoveSlidesModal/RemoveSlides.query';
import RemoveSlidesModal from './RemoveSlidesModal/RemoveSlidesModal.component';
import { SlidesGrid } from './SlidesGrid.component';

const AIGNOSTICS_ORGANIZATION_NAME = 'aignostics';

const $SectionDescription = styled.div<{
  background: keyof Theme['colors'];
}>`
  ${({ theme }) => theme.fontStyles.base};
  color: ${({ background, theme }) =>
    contrast(theme.colors[background], theme)};
`;

const ADMIN_ASSIGNED_SLIDES_PAGE_SIZE = 20;

const paramsSchema = z.object({
  projectId: z.string(),
});
/** Assigned Slides */
const AdminSubProjectSlides = ({
  subprojectId,
  userRole,
  organizationUuid,
  rasterTileServerUrl,
  getToken,
}: {
  subprojectId: string;
  userRole: OrganizationRole;
  organizationUuid: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}): ReactElement => {
  const theme = useTheme();

  const isViewer = userRole.roleName === 'viewer';
  const [page, setPage] = usePagination(getPageFromQueryParams());
  const { addSnackbar } = useSnackbarMutations();
  const { projectId } = paramsSchema.parse(useParams());

  const showAssociations =
    userRole.organization.name === AIGNOSTICS_ORGANIZATION_NAME;

  const [importWsisFromSubProject, { loading: isImportSlidesLoading }] =
    useMutation(IMPORT_WSIS_FROM_SUB_PROJECT);

  const queryParamsFilters = getFiltersFromQueryParams(
    showAssociations
      ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
      : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST
  );

  const { filters, filterProps } = useFilters(
    showAssociations
      ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
      : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
    queryParamsFilters
  );
  const urlParams = getQueryParams(['sortBy', 'sortDirection']);
  const sortByParam = urlParams?.sortBy as SortByOptions;
  const sortDirectionParam = urlParams?.sortDirection as SortByDirection;

  const { sortBy, setSortByOptions } = useSortBy<SortByOptions>({
    column: sortByParam ?? 'name',
    sortDirection: sortDirectionParam || 'asc',
  });
  const queryParams = useMemo(
    () => ({
      page: page.toString(),
      ...filters,
      ...(sortBy?.column ? { sortBy: sortBy.column } : {}),
      ...(sortBy?.sortDirection ? { sortDirection: sortBy.sortDirection } : {}),
    }),
    [page, filters, sortBy?.sortDirection, sortBy?.column]
  );
  useSetQueryParams(queryParams);

  const assignDialog = useDisclosure();
  const importSlidesDialog = useDisclosure();
  const removeSlidesDialog = useDisclosure(false);
  const editStainingsDialog = useDisclosure(false);
  const imageRegistrationModal = useDisclosure();
  const infoModalDisclosure = useDisclosure<Wsi>(false);

  const debouncedFilters = useDebounce(filters, 200);

  const {
    data: subProjectRegistrationGroups,
    error: subProjectRegistrationGroupsError,
  } = useQuery<{
    subProject: {
      registrationGroups: RegistrationGroups;
    };
  }>(FETCH_SUB_PROJECT_REGISTRATION_GROUPS, {
    skip: isViewer,
    variables: {
      subProjectId: subprojectId,
    },
  });

  const {
    data,
    previousData,
    loading: subProjectSlidesLoading,
    error: subProjectSlidesError,
    refetch,
  } = useQuery<
    {
      subProject: SubProject;
    },
    READ_SUBPROJECT_SLIDES_VARIABLES
  >(READ_SUBPROJECT_SLIDES, {
    variables: {
      subProjectId: subprojectId,
      page,
      pageSize: ADMIN_ASSIGNED_SLIDES_PAGE_SIZE,
      search: debouncedFilters.searchWsis as string,
      associations:
        (debouncedFilters as Record<FilterKeysWithAssociation, string[]>)
          .associations ?? [],
      batches: debouncedFilters.batches as string[],
      case: debouncedFilters.caseId as string,
      sortBy: sortBy?.column || 'name',
      isAsc: sortBy?.sortDirection === 'asc',
      objectivePowers: (debouncedFilters.objectivePowers as string[]).map(
        (value) => parseFloat(value)
      ),
      scanners: debouncedFilters.scanners as string[],
      stainings: debouncedFilters.stainings as string[],
      tissues: debouncedFilters.tissues as string[],
      diseases: debouncedFilters.diseases as string[],
      annotations:
        debouncedFilters.filterAnnotations as SubProjectWsisFilterValues,
      annotatedBy: debouncedFilters.filterAnnotatedBy as string[],
      annotationCategory: debouncedFilters.filterAnnotationCategory as string[],
      overlays: debouncedFilters.filterOverlays as SubProjectWsisFilterValues,
    },
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-and-network',
  });

  const { layoutMode, LayoutSelector } = useLayoutMode(
    undefined,
    `layout-${subprojectId}`
  );

  const subProject = (data || previousData)?.subProject;

  const wsis = subProject?.wsis?.nodes;

  const registrationGroups =
    subProjectRegistrationGroups?.subProject?.registrationGroups;

  const dropdownMenuActions = registrationGroups?.length
    ? [
        {
          title: 'Import slides',
          id: 'import-slides',
          props: {
            small: true,
            onClick: importSlidesDialog.open,
            'aria-label': 'import-slides',
          },
        },
        {
          title: 'Remove slides',
          id: 'remove-slides',
          props: {
            small: true,
            onClick: removeSlidesDialog.open,
            disabled: wsis?.length === 0,
            'aria-label': 'remove-slides',
          },
        },
        {
          title: 'Edit stainings',
          id: 'edit-stainings',
          props: {
            small: true,
            onClick: editStainingsDialog.open,
            disabled: wsis?.length === 0,
            'aria-label': 'edit-stainings',
          },
        },
        {
          title: 'Image registration',
          id: 'image-registration',
          props: {
            small: true,
            onClick: imageRegistrationModal.open,
            disabled: wsis?.length === 0,
            'aria-label': 'image-registration',
          },
        },
      ]
    : [
        {
          title: 'Import slides',
          id: 'import-slides',
          props: {
            small: true,
            onClick: importSlidesDialog.open,
            'aria-label': 'import-slides',
          },
        },
        {
          title: 'Remove slides',
          id: 'remove-slides',
          props: {
            small: true,
            onClick: removeSlidesDialog.open,
            disabled: wsis?.length === 0,
            'aria-label': 'remove-slides',
          },
        },
        {
          title: 'Edit stainings',
          id: 'edit-stainings',
          props: {
            small: true,
            onClick: editStainingsDialog.open,
            disabled: wsis?.length === 0,
            'aria-label': 'edit-stainings',
          },
        },
      ];

  const loading = subProjectSlidesLoading || isImportSlidesLoading;
  const totalWsisCount = subProject?.wsis.pageInfo.totalElements ?? 0;

  const error = subProjectSlidesError || subProjectRegistrationGroupsError;

  const showCsv = subProject?.showCsv || false;

  const annotationFeature = subProject?.annotationFeature || 'OFF';
  const { progress, count, total } = getAnnotationProgress(
    subProject?.wsis.collectionAttributes.totalAnnotated,
    totalWsisCount
  );

  return (
    <>
      <Section loading={loading} error={error}>
        <VStack spacing="32" alignItems="center">
          <AdminSubProjectWsisFilters
            subprojectId={subprojectId}
            filterProps={filterProps}
            onChange={(value) => {
              filterProps.onChange(value);
              setPage(1);
            }}
            showAssociations={showAssociations}
            userRole={userRole}
          />

          <HStack
            justifyContent="space-between"
            style={{ width: '100%' }}
            alignItems="end"
          >
            <VStack spacing="small" style={{ flexShrink: '0' }}>
              <PaginationInfo
                totalCount={subProject?.wsis?.pageInfo.totalElements || 0}
                currentPage={page}
                itemsPerPage={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
                itemType={pluralize(
                  'Slide',
                  subProject?.wsis?.pageInfo.totalElements || 0
                )}
                loading={subProject?.wsis?.pageInfo.totalElements === undefined}
              />

              <$SectionDescription background="lighter">
                All slides assigned to this subproject.
              </$SectionDescription>
            </VStack>
            <HStack
              spacing="large"
              alignItems="flex-end"
              style={{ marginLeft: 'auto' }}
            >
              {annotationFeature !== 'OFF' && (
                <ProgressBar
                  value={progress}
                  color="primary"
                  label={`${count} of ${total} ${pluralize(
                    'Slide',
                    count
                  )} annotated`}
                />
              )}

              {/* Show project report download buttons according to flags.*/}
              {showCsv && (
                <Button
                  icon="Download"
                  onClick={() => {
                    if (subProject !== undefined) downloadReport(subProject);
                  }}
                  small
                >
                  CSV
                </Button>
              )}

              {wsis && wsis.length > 0 && (
                <LayoutSelector key="wsi-view-type" name="wsi-view-type" />
              )}
            </HStack>
          </HStack>

          {!isViewer && wsis && wsis.length > 0 && (
            <HStack
              spacing="large"
              justifyContent="end"
              style={{ width: '100%' }}
            >
              <DropdownMenu
                menuLabel={{
                  title: 'Edit slides',
                  props: { small: true, 'aria-label': 'edit-slides' },
                }}
                menuItems={dropdownMenuActions}
              />
              <Button small variant="primary" onClick={assignDialog.open}>
                Assign new slides
              </Button>
            </HStack>
          )}

          {subProject && wsis ? (
            <VStack
              spacing="12"
              alignItems="center"
              style={{ width: '100%', overflowX: 'scroll' }}
            >
              {layoutMode === 'List' ? (
                <VStack
                  style={{
                    gap: `${theme.spacings[32]}px`,
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <AssignedSlidesTable
                    loading={loading}
                    subprojectId={subprojectId}
                    showMore={!isViewer}
                    wsis={wsis}
                    getWsiHref={(wsiUuid) =>
                      buildClientWsiUrl(
                        organizationUuid,
                        projectId,
                        subprojectId,
                        wsiUuid
                      )
                    }
                    setSortBy={setSortByOptions}
                    sortBy={sortBy}
                    pageSize={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
                    role={userRole}
                    getToken={getToken}
                    rasterTileServerUrl={rasterTileServerUrl}
                  />
                </VStack>
              ) : (
                <SlidesGrid
                  organizationUuid={organizationUuid}
                  projectId={projectId}
                  subProjectId={subprojectId}
                  loading={loading}
                  wsis={wsis}
                  pageSize={48}
                  infoModalDisclosure={infoModalDisclosure}
                  rasterTileServerUrl={rasterTileServerUrl}
                  getToken={getToken}
                />
              )}
              {!loading && wsis.length === 0 ? (
                <HStack spacing="12">
                  <Button small variant="primary" onClick={assignDialog?.open}>
                    Assign slides
                  </Button>
                  <Button
                    small
                    variant="primaryOutline"
                    onClick={importSlidesDialog?.open}
                  >
                    Import slides
                  </Button>
                </HStack>
              ) : null}
            </VStack>
          ) : (
            <TableSkeleton rows={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE + 1} />
          )}
          {subProject &&
          subProject.wsis?.pageInfo.totalElements >
            ADMIN_ASSIGNED_SLIDES_PAGE_SIZE &&
          subProject.wsis?.pageInfo.totalPages > 1 ? (
            <Pagination
              currentPage={page}
              totalPages={subProject.wsis?.pageInfo.totalPages}
              onPageChanged={setPage}
            />
          ) : null}
        </VStack>
      </Section>

      {!isViewer && (
        <>
          {removeSlidesDialog.isOpen && (
            <RemoveSlidesModal
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
              isVisible={removeSlidesDialog.isOpen}
              onClose={(shouldRefetchOnClose) => {
                removeSlidesDialog.close();
                if (shouldRefetchOnClose) {
                  sessionStorage.removeItem(
                    `${prefix}/${organizationUuid}/project/${projectId}/${subprojectId}`
                  );
                  setPage(1);
                  void refetch();
                }
              }}
              showAssociations={showAssociations}
              subProjectId={subprojectId}
              pageSize={ADMIN_ASSIGNED_SLIDES_PAGE_SIZE}
            />
          )}

          {editStainingsDialog.isOpen && (
            <EditStainingsModal
              rasterTileServerUrl={rasterTileServerUrl}
              getToken={getToken}
              organizationUuid={organizationUuid}
              isVisible={editStainingsDialog.isOpen}
              onClose={() => {
                editStainingsDialog.close();
                void refetch();
              }}
              showAssociations={showAssociations}
              subProjectId={subprojectId}
            />
          )}

          <Modal
            isVisible={assignDialog.isOpen}
            onClose={() => {
              assignDialog.close();
              void refetch();
            }}
            shouldCloseOnEscKey
            hasCloseButton
            header={
              <h1
                id="assignSlidesTitle"
                style={{
                  ...theme.fontStyles.displayBold,
                  padding: `${theme.spacings['32']}px`,
                }}
              >
                Assign slides
              </h1>
            }
            size="max"
          >
            {subProject !== undefined ? (
              <AdminSubProjectSlidesAssignment
                subProject={subProject}
                showAssociations={showAssociations}
                rasterTileServerUrl={rasterTileServerUrl}
                getToken={getToken}
                organizationUuid={organizationUuid}
                userRole={userRole}
              />
            ) : null}
          </Modal>
          <ImageRegistrationModal
            onClose={imageRegistrationModal.close}
            isOpen={imageRegistrationModal.isOpen}
            subprojectId={subprojectId}
            registrationGroups={registrationGroups}
            slidesCount={subProject?.wsis?.pageInfo.totalElements}
          />
          <Modal
            isVisible={importSlidesDialog.isOpen}
            onClose={importSlidesDialog.close}
            hasCloseButton
            size="small"
            aria-labelledby="importSlidesTitle"
            header={
              <h1
                id="importSlidesTitle"
                style={{
                  ...theme.fontStyles.displayBold,
                  padding: '32px 32px 0 32px',
                  margin: 0,
                }}
              >
                Import slides
              </h1>
            }
          >
            <div style={{ padding: '32px' }}>
              <AdminSubProjectSlidesImportForm
                subprojectId={subprojectId}
                onCancel={importSlidesDialog.close}
                onSubmit={(values) => {
                  importWsisFromSubProject({
                    variables: values,
                    refetchQueries: [
                      READ_SUBPROJECT_SLIDES,
                      FETCH_SUBPROJECT_WSIS,
                      LIST_SUB_PROJECT_SLIDES,
                      FETCH_INTERACTIVE_OVERLAYS_MATRIX,
                      FETCH_SUBPROJECT_WSIS,
                      FETCH_SUB_PROJECT_REGISTRATION_GROUPS,
                    ],
                    update: (cache) => {
                      // Add or remove wsi ref from parent subproject
                      cache.modify({
                        id: cache.identify({
                          __ref: `SubProject:${subprojectId}`,
                        }),
                        fields: {
                          wsis: (_value, { DELETE }) => {
                            return DELETE;
                          },
                        },
                      });
                    },
                  })
                    .then(() => {
                      addSnackbar({
                        type: 'success',
                        message: `Slides from subproject ${values.srcSubProjectId} imported successfully`,
                      });
                      importSlidesDialog.close();
                    })
                    .catch((error) =>
                      addSnackbar({
                        type: 'error',
                        message: `Error importing slides from subproject ${values.srcSubProjectId}: ${error.message}`,
                      })
                    );
                }}
              />
            </div>
          </Modal>
        </>
      )}

      {infoModalDisclosure.data && (
        <WsiMetadataModal
          isOpen={infoModalDisclosure.isOpen}
          onClose={infoModalDisclosure.close}
          wsiId={infoModalDisclosure.data.id}
          subProjectId={subprojectId}
          rasterTileServerUrl={rasterTileServerUrl}
          role={userRole}
          getToken={getToken}
        />
      )}
    </>
  );
};

export default AdminSubProjectSlides;
