import {
  Button,
  Checkbox,
  LoaderBar,
  Modal,
  OneLiner,
  Pagination,
  Placeholder,
  SpreadFilterProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { useDebounce } from '@aignostics/hooks';
import { pluralize, pluralizeWithCount } from '@aignostics/utils';
import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { WsiThumbnail } from '../../../../../components';
import { SubProject, SubProjectWsisFilterValues } from '../../../../../types';
import { AdminSubProjectWsisFilters } from '../../AdminSubProjectWsisFilters.component';
import {
  FilterKeysWithAssociation,
  FilterKeysWithAssociationWithSlidesFilter,
  PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
  PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST,
} from '../AdminSubprojectSlides.type';
import { LIST_SUB_PROJECT_SLIDES } from './RemoveSlides.query';
import {
  $PaginationContainer,
  ButtonsContainer,
  ContentWrapper,
  CountContainer,
  LoadingContainer,
  ModalTitle,
  ThumbnailWrapper,
} from './RemoveSlidesModal.styles';
import { useRemoveSlides } from './useRemoveSlides';

interface RemoveSlidesModalProps {
  isVisible: boolean;
  onClose: (shouldRefetchOnClose: boolean) => void;
  showAssociations: boolean;
  subProjectId: string;
  pageSize: number;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

const PAGE_SIZE = 20;
const $RemoveSlidesModalTableSkeletonContainer = styled.div`
  margin: ${({ theme }) => `${theme.spacings['32']}px 0`};
`;

const RemoveSlidesModal = ({
  isVisible,
  onClose,
  showAssociations,
  subProjectId,
  pageSize,
  rasterTileServerUrl,
  getToken,
}: RemoveSlidesModalProps): ReactElement => {
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const { filters, filterProps } = useFilters(
    showAssociations
      ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
      : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
    getFiltersFromQueryParams(
      showAssociations
        ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST
        : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST
    )
  );

  const debouncedFilters = useDebounce(filters, 200);

  const filtersValues = {
    search: debouncedFilters.searchWsis,
    associations:
      (debouncedFilters as Record<FilterKeysWithAssociation, string | string[]>)
        .associations ?? [],
    batches: debouncedFilters.batches,
    case: debouncedFilters.caseId,
    objectivePowers: (debouncedFilters.objectivePowers as string[]).map(
      (value) => parseFloat(value)
    ),
    scanners: debouncedFilters.scanners,
    stainings: debouncedFilters.stainings,
    tissues: debouncedFilters.tissues,
    diseases: debouncedFilters.diseases,
    annotations:
      debouncedFilters.filterAnnotations as SubProjectWsisFilterValues,
    annotatedBy: debouncedFilters.filterAnnotatedBy as string[],
    annotationCategory: debouncedFilters.filterAnnotationCategory as string[],
    overlays: debouncedFilters.filterOverlays as SubProjectWsisFilterValues,
  };

  const [shouldRefetchOnClose, setShouldRefetchOnClose] = useState(false);

  const {
    removeWsisFromSubproject,
    loading: removingSlides,
    removeAllWsisFromSubproject,
  } = useRemoveSlides(subProjectId, filtersValues);

  const [checkedSlides, setCheckedSlides] = useState<string[]>([]);
  const resetCheckedSlides = () => {
    setCheckedSlides([]);
  };

  const { data, previousData, loading, refetch } = useQuery<{
    subProject: SubProject;
  }>(LIST_SUB_PROJECT_SLIDES, {
    variables: {
      page,
      pageSize: 20,
      subProjectId,
      ...filtersValues,
    },
    nextFetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    return () => {
      setShouldRefetchOnClose(false);
    };
  }, []);

  const removeAllWsis = async () => {
    if (!data?.subProject.wsis) return;

    await removeAllWsisFromSubproject([]);
    resetCheckedSlides();
    onClose(true);
  };

  const removeSelectedWsis = async () => {
    if (!checkedSlides.length) return;
    await removeWsisFromSubproject(checkedSlides);
    resetCheckedSlides();
    setShouldRefetchOnClose(true);
    void refetch();
  };

  useEffect(() => {
    const totalElements = data?.subProject.wsis?.pageInfo.totalElements;
    if (totalElements && page > Math.ceil(totalElements / PAGE_SIZE)) {
      setPage(page - 1);
    }
  }, [data?.subProject.wsis?.pageInfo.totalElements, page, setPage]);

  return (
    <>
      <LoadingContainer>
        <LoaderBar loading={loading} />
      </LoadingContainer>
      <Modal
        shouldCloseOnEscKey
        hasCloseButton
        isVisible={isVisible}
        onClose={() => {
          onClose(shouldRefetchOnClose);
        }}
      >
        <ContentWrapper>
          <ModalTitle>Remove Slides</ModalTitle>

          <AdminSubProjectWsisFilters
            filterProps={
              filterProps as SpreadFilterProps<FilterKeysWithAssociationWithSlidesFilter>
            }
            subprojectId={subProjectId}
            onChange={(e) => {
              setPage(1);
              filterProps.onChange(e);
            }}
            showAssociations={showAssociations}
            pageId="remove-slides"
          />

          {loading ? (
            <$RemoveSlidesModalTableSkeletonContainer>
              <TableSkeleton rows={pageSize} />
            </$RemoveSlidesModalTableSkeletonContainer>
          ) : null}

          {!loading && !data?.subProject.wsis.nodes.length ? (
            <Placeholder
              title="No slides"
              description="There are no slides matching the current filters"
            />
          ) : null}

          {!loading && data?.subProject.wsis.nodes.length ? (
            <>
              <ButtonsContainer>
                <Button
                  small
                  variant="primaryOutline"
                  onClick={removeAllWsis}
                  disabled={removingSlides}
                >
                  Remove all
                </Button>
                <Button
                  small
                  variant="primary"
                  disabled={!checkedSlides.length || removingSlides}
                  onClick={removeSelectedWsis}
                >
                  Remove selected {pluralize('slide', checkedSlides.length)}
                </Button>
              </ButtonsContainer>

              <CountContainer>
                <div>
                  Showing:{' '}
                  {(data || previousData)?.subProject.wsis.nodes.length}/
                  {(data || previousData)?.subProject.wsisCount} slides
                </div>
                {checkedSlides.length ? (
                  <div>
                    Selected:{' '}
                    {pluralizeWithCount('slide', checkedSlides.length)}
                  </div>
                ) : null}
              </CountContainer>

              <Table borderSpacing={0}>
                <TableHead>
                  <TableRow>
                    <TableHeader style={{ width: '48px', height: '48px' }} />
                    <TableHeader>Slide name</TableHeader>
                    <TableHeader>Scanner</TableHeader>
                    <TableHeader>Staining</TableHeader>
                    <TableHeader>Region of interest</TableHeader>
                    <TableHeader />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.subProject.wsis.nodes.map((wsi) => {
                    return (
                      <TableRow aria-label={`Assigned ${wsi.id}`} key={wsi.id}>
                        <TableCell>
                          <ThumbnailWrapper>
                            <WsiThumbnail
                              wsiId={wsi.id}
                              getToken={getToken}
                              rasterTileServerUrl={rasterTileServerUrl}
                              size="small"
                            />
                          </ThumbnailWrapper>
                        </TableCell>
                        <TableCell style={{ maxWidth: '20ch' }}>
                          <OneLiner>{wsi.name}</OneLiner>
                        </TableCell>
                        <TableCell>
                          <OneLiner>{wsi.scanner.vendor}</OneLiner>
                        </TableCell>
                        <TableCell>
                          <OneLiner>{wsi.staining}</OneLiner>
                        </TableCell>
                        <TableCell>{wsi.regionsOfInterestCount}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={checkedSlides.includes(wsi.id)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                setCheckedSlides((previousData) => [
                                  ...previousData,
                                  wsi.id,
                                ]);
                              } else {
                                setCheckedSlides((previousData) =>
                                  previousData.filter((id) => id !== wsi.id)
                                );
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {data?.subProject.wsis?.pageInfo.totalElements > PAGE_SIZE &&
              data?.subProject.wsis?.pageInfo.totalPages > 1 ? (
                <$PaginationContainer>
                  <Pagination
                    currentPage={page}
                    totalPages={data?.subProject.wsis?.pageInfo.totalPages}
                    onPageChanged={setPage}
                  />
                </$PaginationContainer>
              ) : null}
            </>
          ) : null}
        </ContentWrapper>
      </Modal>
    </>
  );
};

export default RemoveSlidesModal;
