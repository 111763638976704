import { Button, PopUp, useDisclosure } from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDeleteOnboardingBatch } from '../../hooks/useDeleteOnboardingBatch';
import { BatchReadOnlyForm } from '../../pages/AgGridSetFileMetadataStep/Form';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { AssignBatchModal } from '../OnboardingBatches/AssignBatchModal/AssignBatchModal.component';
import { ProcessOnboardingBatchModal } from './ProcessOnboardingBatchModal.component';
import {
  ONBOARDING_BATCH_EDITABLE_STATUSES,
  ONBOARDING_BATCH_REMOVABLE_STATUSES,
  ONBOARDING_BATCH_SUCCESSFULLY_COMPLETED_STATUSES,
} from './const';

interface ReadOnlyTableEntryActionsProps {
  onboardingBatch: BatchReadOnlyForm;
  isLatestBatchEntry: boolean;
  isValidating: boolean;
  isValidationSuccessfullyTriggered: boolean;
  onBatchValidationTrigger?: () => void;
  currentUser: User;
  role: OrganizationRole;
}

const $ButtonContainer = styled.div`
  display: inline-flex;
  gap: 8px;
`;

export const ReadOnlyTableEntryActions = ({
  onboardingBatch,
  isLatestBatchEntry,
  isValidating,
  isValidationSuccessfullyTriggered,
  onBatchValidationTrigger,
  currentUser,
  role,
}: ReadOnlyTableEntryActionsProps): ReactElement => {
  const navigate = useNavigate();

  const { status, batch_id } = onboardingBatch;

  const processSlidesDialog = useDisclosure();

  const assignToProjectDialog = useDisclosure();
  const processRemoveDialog = useDisclosure();
  const { deleteOnboardingBatch, isDeleteLoading } = useDeleteOnboardingBatch();

  const showEditButton = ONBOARDING_BATCH_EDITABLE_STATUSES.includes(status);
  const showProcessButton =
    isLatestBatchEntry &&
    status === 'pending' &&
    !isValidationSuccessfullyTriggered;
  const showAssignToProjectButton =
    ONBOARDING_BATCH_SUCCESSFULLY_COMPLETED_STATUSES.includes(status);
  const showRemoveButton = ONBOARDING_BATCH_REMOVABLE_STATUSES.includes(status);
  const organizationUuid = useSelectedOrganizationUuid();

  return (
    <$ButtonContainer>
      {showEditButton && (
        <Button
          variant="primaryOutline"
          small
          onClick={() => {
            void navigate(
              `/${organizationUuid}/admin/data-onboarding/batch/${batch_id}/edit`,
              {
                state: {
                  onboardingBatch,
                },
              }
            );
          }}
        >
          Edit slides
        </Button>
      )}
      {showProcessButton && (
        <>
          <Button
            onClick={processSlidesDialog.open}
            small
            disabled={isValidating}
          >
            Process slides
          </Button>
          <ProcessOnboardingBatchModal
            onClose={() => {
              processSlidesDialog.close();
              if (onBatchValidationTrigger) onBatchValidationTrigger();
            }}
            isVisible={processSlidesDialog.isOpen}
          />
        </>
      )}
      {showAssignToProjectButton && (
        <>
          <Button onClick={assignToProjectDialog.open} small>
            Assign to project
          </Button>
          {assignToProjectDialog.isOpen && (
            <AssignBatchModal
              isOpen={assignToProjectDialog.isOpen}
              batchId={batch_id}
              onCloseModal={assignToProjectDialog.close}
              currentUser={currentUser}
              role={role}
            />
          )}
        </>
      )}
      {showRemoveButton && (
        <>
          <Button onClick={processRemoveDialog.open} small variant="primary">
            {status === 'uploading' ? 'Cancel & remove entry' : 'Remove entry'}
          </Button>
          <PopUp
            isVisible={processRemoveDialog.isOpen}
            title={`Attention`}
            onClose={() => {
              processRemoveDialog.close();
            }}
            shouldCloseOnBackdropClick={true}
            isLoading={isDeleteLoading}
            secondaryAction={{
              label: 'No',
              onClick: () => {
                processRemoveDialog.close();
              },
            }}
            primaryAction={{
              label: 'Yes',
              onClick: () => {
                void deleteOnboardingBatch(batch_id).then((response) => {
                  processRemoveDialog.close();
                  if (response.errors) {
                    return;
                  }
                  void navigate(`/${organizationUuid}/admin/data-onboarding`);
                });
              },
            }}
          >
            <p style={{ margin: '0' }}>
              Are you sure you want to remove these slides?
            </p>
          </PopUp>
        </>
      )}
    </$ButtonContainer>
  );
};
