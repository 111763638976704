import {
  AG_GRID_LICENSE_KEY,
  SnackbarContainer,
  SnackbarProvider,
} from '@aignostics/components';
import {
  UploadDoneNotifier,
  UploadStateProvider,
  UploadWakeAndNavigationLock,
} from '@aignostics/onboarding-ui';
import { CustomThemeProvider } from '@aignostics/theme';
import isPropValid from '@emotion/is-prop-valid';
import { Theme } from '@radix-ui/themes';
import { LicenseManager } from 'ag-grid-enterprise';
import queryString from 'query-string';
import React, { ReactElement } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ShouldForwardProp, StyleSheetManager } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';
import { initSentry } from '../../sentry';
import { router } from '../__Navigation/Routes';
import { ErrorBoundary } from '../__Pages/ErrorBoundary';
import { RevokedAccessModalWrapper } from '../__Pages/Login/RevokedAccessModalWrapper';
import { AppHead } from './AppHead.component';
import { AuthProvider } from './AuthProvider';
import { AppConfigProvider } from './ConfigProvider';
import { ImpersonationProvider } from './ImpersonationProvider';
import { TrackingProvider } from './TrackingProvider';
import { appConfig } from './appConfig';
import { useNetworkStatusNotification } from './useNetworkStatusNotification';

// TODO FE-5627 Evaluate this props is moving away from motion
const motionProps = new Set([
  'animate',
  'initial',
  'exit',
  'whileHover',
  'whileTap',
  'transition',
  'variants',
  'drag',
]);

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
/**
 * Root react app element to
 * @returns
 */
const App = (): ReactElement => {
  initSentry(appConfig.sentry.dsn, appConfig.buildEnv);

  const shouldForwardProp: ShouldForwardProp<'web'> = (
    prop,
    elementToBeCreated
  ) => {
    // Always forward props to custom React components
    if (typeof elementToBeCreated !== 'string') {
      return true;
    }
    return motionProps.has(prop) || isPropValid(prop);
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <Theme accentColor="violet" grayColor="gray" radius="small">
        <CustomThemeProvider theme={appConfig.theme}>
          <QueryParamProvider
            options={{
              searchStringToObject: queryString.parse,
              objectToSearchString: queryString.stringify,
            }}
          >
            <ErrorBoundary>
              <AppConfigProvider config={appConfig}>
                <SnackbarProvider>
                  <NetworkStatusProvider />
                  <UploadStateProvider>
                    <UploadWakeAndNavigationLock />
                    <AppHead appConfig={appConfig} />
                    <UploadDoneNotifier />
                    <ImpersonationProvider>
                      <AuthProvider>
                        <SnackbarContainer />
                        <RevokedAccessModalWrapper />
                        <TrackingProvider>
                          <RouterProvider router={router} />
                        </TrackingProvider>
                      </AuthProvider>
                    </ImpersonationProvider>
                  </UploadStateProvider>
                </SnackbarProvider>
              </AppConfigProvider>
            </ErrorBoundary>
          </QueryParamProvider>
        </CustomThemeProvider>
        <div id="modal-root" style={{ position: 'relative', zIndex: 1 }} />
        <div id="menu-root" />
      </Theme>
    </StyleSheetManager>
  );
};

function NetworkStatusProvider(): null {
  useNetworkStatusNotification();
  return null;
}

export default App;
