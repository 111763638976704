import { MotionConfig } from 'framer-motion';
import React, { Suspense, lazy } from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';
import { sentryCreateBrowserRouter } from '../../../sentry';
import { RequireAuthenticationGuard } from '../../App/AuthProvider/RequireAuthenticationGuard';
import { PortalApolloProvider } from '../../App/PortalApolloProvider';
import { TrackingConsentGuard } from '../../App/TrackingConsentGuard';
import { appConfig } from '../../App/appConfig';
import { Layout } from '../../__Layout/Layout/Layout';
import { AdminDataOnboardingBatchWrapper } from '../../__Pages/Admin/AdminDataOnboardingBatchWrapper';
import { AdminDataOnboardingWrapper } from '../../__Pages/Admin/AdminDataOnboardingWrapper';
import { AdminEditSlidesWrapper } from '../../__Pages/Admin/AdminEditSlidesWrappers';
import { AdminTransferCloudSlidesWrapper } from '../../__Pages/Admin/AdminTransferCloudSlidesWrapper';
import { AdminUploadSlidesWrapper } from '../../__Pages/Admin/AdminUploadSlidesWrapper';
import { AnnotationManagementGuard } from '../../__Pages/Admin/AnnotationManagement/Admin.AnnotationManagement.Guard';
import { SlideLibraryWrapper } from '../../__Pages/Admin/SlideLibrary/SlideLibraryWrapper';
import { LoginWrapper, ResetPasswordWrapper } from '../../__Pages/Login';
import { NotFound } from '../../__Pages/NotFound';
import RouterErrorElement from '../../__Pages/RouterErrorElement';
import { crumbsHandlers } from '../Crumbs/Crumbs.utilities';
import { AddOrganizationToUrlGuard } from './AddOrganizationToUrlGuard';
import { AdminGuard } from './AdminGuard';
import { SelectedOrganizationGuard } from './SelectedOrganizationGuard';
import { UserEnabledGuard } from './UserEnabledGuard';

const ClientSlideView = lazy(() => import('../../__Pages/Client/Client.Slide'));

/** Admin */
const AdminSlideView = lazy(() => import('../../__Pages/Admin/Slide'));

/** Admin Users */
const AdminUserList = lazy(
  () => import('../../__Pages/Admin/Users/Admin.UserListWrapper.component')
);
const AdminUser = lazy(
  () => import('../../__Pages/Admin/Users/Admin.UserWrapper.component')
);

/** Admin Project */
const ProjectsList = lazy(
  () => import('../../__Pages/Admin/Projects/Admin.ProjectsWrapper')
);
const Project = lazy(
  () => import('../../__Pages/Admin/Projects/Admin.Project.Wrapper')
);
const AdminSubProject = lazy(
  () => import('../../__Pages/Admin/SubProject/Admin.SubProject.Wrapper')
);

/** Admin Annotation Categories */
const AdminAnnotationManagement = lazy(
  () =>
    import(
      '../../__Pages/Admin/AnnotationManagement/Admin.AnnotationManagement.Wrapper'
    )
);

function NavigateToProject() {
  const { organizationUuid, projectId } = useParams();
  const { pathname } = useLocation();

  const path = pathname.split('/');
  const tab = path[path.length - 1];

  return (
    <Navigate
      to={`/${organizationUuid}/project/${projectId}/${tab !== projectId ? tab : ''}`}
    />
  );
}

function NavigateToClientSlide() {
  const { organizationUuid, projectId, subProjectId, wsiId } = useParams();
  const { search } = useLocation();

  return (
    <Navigate
      to={`/${organizationUuid}/project/${projectId}/subproject/${subProjectId}/wsi/${wsiId}${search}`}
    />
  );
}

export const routes: RouteObject[] = [
  {
    path: 'login',
    children: [
      {
        index: true,
        path: '*',
        element: <LoginWrapper />,
        errorElement: <RouterErrorElement />,
      },
    ],
  },
  {
    path: 'reset-password',
    element: <ResetPasswordWrapper />,
    errorElement: <RouterErrorElement />,
  },
  {
    path: '*',
    element: (
      <RequireAuthenticationGuard>
        <AddOrganizationToUrlGuard />
      </RequireAuthenticationGuard>
    ),
    errorElement: <RouterErrorElement />,
  },
  {
    path: ':organizationUuid',
    element: (
      <RequireAuthenticationGuard>
        <TrackingConsentGuard>
          <SelectedOrganizationGuard>
            <UserEnabledGuard>
              <MotionConfig transition={{ duration: 0.3 }}>
                <Suspense fallback={null}>
                  <PortalApolloProvider>
                    <Layout>
                      <Outlet />
                    </Layout>
                  </PortalApolloProvider>
                </Suspense>
              </MotionConfig>
            </UserEnabledGuard>
          </SelectedOrganizationGuard>
        </TrackingConsentGuard>
      </RequireAuthenticationGuard>
    ),
    errorElement: <RouterErrorElement />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        index: true,
        element: <ProjectsList />,
      },
      {
        path: 'project/:projectId/*',
        element: <Outlet />,
        handle: { crumb: crumbsHandlers.project },
        children: [
          {
            index: true,
            path: '*',
            element: <Project />,
          },
          {
            path: ':subProjectId/wsi/:wsiId',
            element: <NavigateToClientSlide />,
          },
          {
            path: 'subproject/:subProjectId/*',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.subProject },
            children: [
              {
                index: true,
                path: '*',
                element: <AdminSubProject />,
              },
              {
                path: 'wsi/:wsiId',
                element: <ClientSlideView />,
                handle: { crumb: crumbsHandlers.wsi },
              },
            ],
          },
        ],
      },
      {
        path: 'admin/wsi/:wsiId/*',
        element: (
          <AdminGuard>
            <AdminSlideView />
          </AdminGuard>
        ),
        handle: { crumb: crumbsHandlers.adminWsi },
      },
      {
        path: 'admin/users',
        element: (
          <AdminGuard>
            <Outlet />
          </AdminGuard>
        ),
        handle: { crumb: crumbsHandlers.adminUserList },
        children: [
          {
            index: true,
            element: <AdminUserList />,
          },
          {
            path: ':userId/*',
            element: <AdminUser />,
            handle: { crumb: crumbsHandlers.adminUser },
          },
        ],
      },
      {
        path: 'admin/projects',
        element: (
          <AdminGuard>
            <Outlet />
          </AdminGuard>
        ),
        handle: { crumb: crumbsHandlers.adminProjectList },
        children: [
          {
            index: true,
            element: <Navigate to={'/'} />,
          },
          {
            path: ':projectId/*',
            element: <Outlet />,
            handle: { crumb: crumbsHandlers.adminProject },
            children: [
              {
                index: true,
                path: '*',
                element: <NavigateToProject />,
              },
              {
                path: 'subproject/:subProjectId/*',
                element: <Outlet />,
                handle: { crumb: crumbsHandlers.adminSubProject },
                children: [
                  {
                    index: true,
                    path: '*',
                    element: <AdminSubProject />,
                  },
                ],
              },
            ],
          },
          {
            path: 'create',
            element: <ProjectsList isCreateRoute={true} />,
            handle: { crumb: crumbsHandlers.adminProjectCreate },
          },
        ],
      },
      {
        path: 'admin/data-onboarding',
        element: (
          <AdminGuard>
            <Outlet />
          </AdminGuard>
        ),
        handle: { crumb: crumbsHandlers.adminDataOnboarding },
        children: [
          {
            index: true,
            element: <AdminDataOnboardingWrapper />,
          },
          {
            path: 'batch/:batchId/*',
            element: <AdminDataOnboardingBatchWrapper />,
            handle: { crumb: crumbsHandlers.adminDataOnboardingBatch },
          },
          {
            path: 'upload-slides/*',
            element: <AdminUploadSlidesWrapper />,
            handle: { crumb: crumbsHandlers.adminUploadSlides },
          },
          {
            path: 'transfer-slides',
            element: <AdminTransferCloudSlidesWrapper />,
            handle: { crumb: crumbsHandlers.adminSelectSlides },
          },
          {
            path: 'batch/:batchId/edit',
            element: <AdminEditSlidesWrapper />,
            handle: { crumb: crumbsHandlers.adminBatchEditSlide },
          },
        ],
      },
      {
        path: 'admin/slide-library',
        element: (
          <AdminGuard>
            <Outlet />
          </AdminGuard>
        ),
        handle: { crumb: crumbsHandlers.adminSlideLibrary },
        children: [
          {
            index: true,
            element: <SlideLibraryWrapper />,
          },
        ],
      },
      {
        path: 'annotation-management',
        element: (
          <AnnotationManagementGuard>
            <Outlet />
          </AnnotationManagementGuard>
        ),
        handle: { crumb: crumbsHandlers.adminAnnotationManagement },
        children: [
          {
            index: true,
            path: '*',
            element: <AdminAnnotationManagement />,
          },
        ],
      },
    ],
  },
];

export const router = sentryCreateBrowserRouter(routes, {
  basename: appConfig.routerBasename,
});
