import React, { type ReactElement } from 'react';
import styled from 'styled-components';

const $ApplyAllHeaderCell = styled.span`
  margin-left: 0;
`;

export const CustomCellApplyAllValues = (): ReactElement => (
  <$ApplyAllHeaderCell>Apply values to all slides</$ApplyAllHeaderCell>
);
