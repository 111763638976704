import type { ICellRendererParams } from 'ag-grid-enterprise';
import React, { type ReactElement } from 'react';
import { ReadOnlyTableEntryActions } from '../../../../components/ReadOnly/OnboardingBatch.ReadOnlyTableEntryActions.component';
import type { BatchesGridContext, GridDataBatches } from '../BatchesGrid.types';

export const ActionsCell = ({
  data,
  context: {
    role,
    currentUser,
    isValidating,
    isValidationSuccessfullyTriggered,
    validateOnboardingBatch,
    ...context
  },
}: ICellRendererParams<
  GridDataBatches,
  unknown,
  BatchesGridContext
>): ReactElement | null => {
  if (!data || data.type !== 'batch-entry') {
    return null;
  }

  return (
    <ReadOnlyTableEntryActions
      onboardingBatch={context.getOnboardingBatch(data.batchId)}
      isLatestBatchEntry={context.isThisLatestBatchEntry(data.batchId)}
      onBatchValidationTrigger={validateOnboardingBatch}
      isValidating={isValidating}
      isValidationSuccessfullyTriggered={isValidationSuccessfullyTriggered}
      role={role}
      currentUser={currentUser}
    />
  );
};
