import { gql } from '@apollo/client';

export const GET_ADMIN_PROJECT_DETAILS = gql`
  query GET_ADMIN_PROJECT_DETAILS($projectId: ID!, $isAdminView: Boolean) {
    project(id: $projectId, isAdminView: $isAdminView) {
      id
      name
      description
      isVisible
    }
  }
`;
