import { Pill, prettyFormatBytes } from '@aignostics/components';
import { ColDef, ColSpanParams, ValueGetterParams } from 'ag-grid-enterprise';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';
import { ACCEPTED_WSI_FILE_TYPES } from '../../../../../utils/ACCEPTED_FILE_TYPES';
import { CloudFileInfo } from '../../../utils/getFileInfoFromSource';
import { CloudAsset } from '../../types/CloudAsset';
import { CloudFileInfoWithMrxsFile } from '../getMrxsFileSizeChecksum';
import {
  SelectSlideCell,
  SelectSlideHeader,
  SlideNameCell,
} from './components';

interface Params {
  files: CloudAsset[];
  selectedFiles: CloudFileInfo[];
  onSelectedFilesUpdate: (updatedSelectedFiles: CloudFileInfo[]) => void;
  onPathUpdate: (path: string) => void;
}

export const getCloudSlideListColumnDef = ({
  files,
  selectedFiles,
  onSelectedFilesUpdate,
  onPathUpdate,
}: Params): ColDef<CloudAsset>[] => {
  const filteredValidFiles = files.filter(
    (file): file is CloudFileInfoWithMrxsFile =>
      file.type === 'remote' &&
      !file.checksum.includes('BADCHKSM') &&
      ACCEPTED_WSI_FILE_TYPES.some((v) => file.filename.endsWith(v))
  );

  return [
    {
      headerName: 'Name',
      field: 'filename',
      sortable: false,
      flex: 2,
      cellRenderer: (props: CustomCellRendererProps<CloudAsset>) => (
        <SlideNameCell {...props} onPathUpdate={onPathUpdate} />
      ),
    },
    {
      headerName: 'Checksum',
      field: 'checksum',
      sortable: false,
      flex: 1,
      colSpan: (params: ColSpanParams<CloudAsset>) =>
        params.data?.type === 'remote' &&
        params.data?.missingMrxsFolderErrorMsg?.length
          ? 3
          : 1,
      cellRenderer: ({ data: file }: CustomCellRendererProps<CloudAsset>) => {
        if (file?.type !== 'remote') return null;

        if (file?.missingMrxsFolderErrorMsg) {
          return <Pill icon="Info" text={file?.missingMrxsFolderErrorMsg} />;
        }

        return <p>{file.checksum}</p>;
      },
    },
    {
      headerName: 'Size',
      field: 'size',
      sortable: false,
      flex: 1,
      valueGetter: ({ data: file }: ValueGetterParams<CloudAsset>) =>
        file?.type === 'remote' && file?.size
          ? prettyFormatBytes(file.size)
          : null,
    },
    {
      headerName: 'Select',
      sortable: false,
      flex: 1,
      cellRenderer: (props: CustomCellRendererProps<CloudAsset>) => (
        <SelectSlideCell
          {...props}
          onSelectedFilesUpdate={onSelectedFilesUpdate}
          selectedFiles={selectedFiles}
        />
      ),
      headerComponent: (props: CustomCellRendererProps<CloudAsset>) => (
        <SelectSlideHeader
          {...props}
          files={files}
          filteredValidFiles={filteredValidFiles}
          selectedFiles={selectedFiles}
          onSelectedFilesUpdate={onSelectedFilesUpdate}
        />
      ),
    },
  ] satisfies ColDef<CloudAsset>[];
};
