import {
  AGGridTable,
  Button,
  IconButton,
  Tooltip,
  useDisclosure,
  VStack,
} from '@aignostics/components';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import * as uuid from 'uuid';
import { AnnotationCategorySetModule } from '../../../../types';
import {
  $AnnotationCategorySetModulesBlock,
  $AnnotationCategorySetModulesBlockToggle,
  $AnnotationCategorySetModulesBlockToggleText,
  $ButtonContainer,
} from './AnnotationCategorySetForm.styles';

const ANNOTATION_CATEGORY_SET_MODULE_NAME_TAKEN_ERROR_TEXT =
  'This module name is already taken.';

const REMOVE_MODULE_TOOLTIP_TEXT =
  'You cannot delete this module if it has attached categories.';

const ToolTip = (): ReactElement => {
  return <div>{REMOVE_MODULE_TOOLTIP_TEXT}</div>;
};

export interface AnnotationCategorySetFormModulesProps {
  mode: 'create' | 'edit';
  modules: AnnotationCategorySetModule[];
  onAddModule: (module: AnnotationCategorySetModule) => void;
  onUpdateModuleName: (index: number, newName: string) => void;
  onRemoveModule: (index: number) => void;
}

type AnnotationSetsAndModulesData = AnnotationCategorySetModule;
export const AnnotationCategorySetFormModules = ({
  mode,
  modules = [],
  onAddModule,
  onUpdateModuleName,
  onRemoveModule,
}: AnnotationCategorySetFormModulesProps): ReactElement => {
  const { isOpen, close, open } = useDisclosure(true);
  const gridRef = useRef<AgGridReact>(null);
  const theme = useTheme();
  const columnDefs: ColDef<AnnotationCategorySetModule>[] = useMemo(() => {
    const columns: ColDef<AnnotationCategorySetModule>[] = [
      {
        headerName: 'Module',
        field: 'moduleName',
        editable: true,
        cellEditor: 'agTextCellEditor',
        enableCellChangeFlash: true,
        onCellValueChanged: (props) => {
          // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
          if (props.node !== null && props.node.rowIndex !== null) {
            onUpdateModuleName(props.node.rowIndex, props.newValue ?? '');
          }
        },
        cellStyle: (props) => {
          const module = props?.data;

          const allOtherModuleNames = modules
            .filter(({ moduleId }) => moduleId !== module?.moduleId)
            .map(({ moduleName }) => moduleName);

          const isValid =
            module?.moduleName &&
            !allOtherModuleNames.includes(module?.moduleName);

          return isValid
            ? null
            : {
                color: theme.colors.error,
              };
        },
        tooltipValueGetter: (props) => {
          const module = props?.data;

          if (module) {
            const allOtherModuleNames = modules
              .filter(({ moduleId }) => moduleId !== module.moduleId)
              .map(({ moduleName }) => moduleName);

            const isValid = !allOtherModuleNames.includes(module.moduleName);

            return isValid
              ? ''
              : ANNOTATION_CATEGORY_SET_MODULE_NAME_TAKEN_ERROR_TEXT;
          }

          return '';
        },
      },

      {
        headerName: 'Action',
        cellRenderer: (
          props: CustomCellRendererProps<
            AnnotationSetsAndModulesData & { index: number },
            string
          >
        ) => {
          const { data } = props;

          const cannotDeleteModule =
            data?.categoryCount !== undefined && data.categoryCount > 0;

          return (
            <div>
              {cannotDeleteModule ? (
                <Tooltip content={<ToolTip />}>
                  <RemoveModuleButton
                    disabled={cannotDeleteModule}
                    index={data.index}
                    onRemoveModule={onRemoveModule}
                  />
                </Tooltip>
              ) : (
                <RemoveModuleButton
                  disabled={cannotDeleteModule}
                  index={data?.index || 0}
                  onRemoveModule={onRemoveModule}
                />
              )}
            </div>
          );
        },
        sortable: false,
      },
    ];

    if (mode === 'edit') {
      columns.splice(1, 0, {
        field: 'categoryCount',
        headerName: 'Categories',
      } as ColDef<AnnotationCategorySetModule>);
    }

    return columns;
  }, [mode, modules, onRemoveModule, onUpdateModuleName, theme.colors.error]);

  const modulesData = useMemo(() => {
    return modules.map((module, index) => ({ ...module, index }));
  }, [modules]);

  return (
    <VStack spacing="16" style={{ width: '100%' }}>
      <$AnnotationCategorySetModulesBlockToggle
        small
        icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
        onClick={() => {
          if (isOpen) {
            close();
          } else {
            open();
          }
        }}
        variant="ghost"
        aria-expanded={isOpen}
      >
        <$AnnotationCategorySetModulesBlockToggleText>
          Modules
        </$AnnotationCategorySetModulesBlockToggleText>
      </$AnnotationCategorySetModulesBlockToggle>

      <AnimatePresence initial={isOpen}>
        {isOpen && (
          <$AnnotationCategorySetModulesBlock
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            {modules.length > 0 ? (
              <div style={{ height: '30vh', width: '100%' }}>
                <AGGridTable
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    resizable: true,
                    sortable: false,
                    filter: false,
                    suppressHeaderMenuButton: true,
                  }}
                  pagination={false}
                  rowData={modulesData}
                  rowModelType={'clientSide'}
                  getRowId={(params) => params.data.moduleId}
                  suppressCellFocus={false}
                  stopEditingWhenCellsLoseFocus={true}
                  persistParamsInUrl={false}
                />
              </div>
            ) : null}
            <Button
              small
              variant="ghost"
              onClick={() => {
                onAddModule({
                  moduleId: uuid.v4(),
                  moduleName: '',
                });
              }}
              disabled={modules.some(
                ({ moduleName }) =>
                  !moduleName || moduleName.trim().length === 0
              )}
            >
              Add new module
            </Button>
          </$AnnotationCategorySetModulesBlock>
        )}
      </AnimatePresence>
    </VStack>
  );
};

const RemoveModuleButton = ({
  onRemoveModule,
  index,
  disabled,
  ...rest
}: {
  onRemoveModule: (index: number) => void;
  index: number;
  disabled: boolean;
}) => {
  return (
    <$ButtonContainer {...rest}>
      <IconButton
        aria-label="remove"
        icon="MinusCircle"
        onClick={() => {
          onRemoveModule(index);
        }}
        disabled={disabled}
        style={{ height: '28px' }}
      />
    </$ButtonContainer>
  );
};
