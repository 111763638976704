import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { mapStatusToMessage } from '../onboardingBatches';
import {
  $OnboardingStatusCompletedWarningIcon,
  $StatusMessage,
} from './BatchListColumn.styles';

export const StatusCell = (
  props: CustomCellRendererProps<OnboardingBatchItem>
): ReactElement | null => {
  const batchItem = props.data;
  if (!batchItem) {
    return null;
  }
  const { status } = batchItem;
  return (
    <$StatusMessage
      aria-label="Batch Status"
      color={mapStatusToMessage[status].color}
    >
      {mapStatusToMessage[status].message}
      {status === 'failed' && (
        <$OnboardingStatusCompletedWarningIcon
          icon="AlertCircle"
          size="small"
          color="error"
        />
      )}
    </$StatusMessage>
  );
};
