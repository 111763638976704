import { overrideHEStaining } from '../../Form';
import { MULTIPLE_STAININGS_SEPARATOR } from '../const';
import { CSVParserInputWSIRow, CSVWSIRow, ParserSelectData } from '../types';
import { isEmptyValue, IsMatchingCsvColumns } from './getCsvParserResults';
import { NormalizedEntities } from './normalizeAllEntities';

export const isMatchingColumnCsv = (
  csvRow: CSVWSIRow,
  wsi: CSVParserInputWSIRow,
  normalizedSelectData: NormalizedEntities,
  selectData: ParserSelectData
): IsMatchingCsvColumns => {
  const {
    normalizedCancerSites,
    normalizedDiseases,
    normalizedMorphologies,
    normalizedPreparationTypes,
    normalizedSampleTypes,
    normalizedScanners,
    normalizedStainings,
    normalizedTissues,
  } = normalizedSelectData;
  const isMatchingTissue = normalizedTissues.has(
    csvRow.Localization.toLowerCase()
  );

  // map he to h&e
  csvRow.Staining =
    overrideHEStaining(selectData.stainings, csvRow.Staining) ?? '';
  const csvStainings = csvRow.Staining.split(MULTIPLE_STAININGS_SEPARATOR);
  const wsiStainings = (wsi.Staining ?? '').split(MULTIPLE_STAININGS_SEPARATOR);
  let isMatchingStainings: boolean;
  if (csvStainings.length !== wsiStainings.length) {
    // add warning when count of stainings for multichannel slide
    // file differs from the one we have in the data upload ui
    isMatchingStainings = false;
  } else {
    isMatchingStainings = csvStainings.every((s) =>
      normalizedStainings.has(s.toLowerCase())
    );
  }

  // only check for mismatch if value is not empty, if it is empty shouldn't show a warning
  const isMatchingDisease = !isEmptyValue(csvRow.Disease)
    ? normalizedDiseases.has(csvRow.Disease.toLowerCase())
    : true;

  const isMatchingPreparationType = !isEmptyValue(csvRow['Preparation Type'])
    ? normalizedPreparationTypes.has(csvRow['Preparation Type'].toLowerCase())
    : true;

  const isMatchingSampleType = !isEmptyValue(csvRow['Sample Type'])
    ? normalizedSampleTypes.has(csvRow['Sample Type'].toLowerCase())
    : true;
  const isMatchingMorphology = !isEmptyValue(csvRow['Morphology'])
    ? normalizedMorphologies.has(csvRow['Morphology'].toLowerCase())
    : true;
  const isMatchingCancerSite = !isEmptyValue(csvRow['Cancer Site'])
    ? normalizedCancerSites.has(csvRow['Cancer Site'].toLowerCase())
    : true;
  const isMatchingScanner = normalizedScanners.has(
    csvRow.Scanner.toLowerCase()
  );
  return {
    isMatchingTissue,
    isMatchingStainings,
    isMatchingScanner,
    isMatchingDisease,
    isMatchingPreparationType,
    isMatchingSampleType,
    isMatchingMorphology,
    isMatchingCancerSite,
  };
};
