import type {
  MultiChannelFormRow,
  SingleChannelFormRow,
  TissueBlockCaseIdTriple,
} from './form.state.types';

export const getFirstMatchingTripleOrWsisGroup = <
  T extends TissueBlockCaseIdTriple,
>(
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: T[]
): T | undefined => {
  const { tissue, block, caseName } =
    localSlide.type === 'single-channel'
      ? localSlide
      : localSlide.parentAssigned;

  return matchingWsisInDb.find(
    (group) =>
      group.tissue === tissue &&
      group.block === block &&
      // allow mixing tissues for slides with empty block
      block &&
      // TODO: [FE-5615]: Rename caseId to caseName in Onboarding API
      group.caseId === caseName
  );
};
