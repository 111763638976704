import { useSnackbarMutations } from '@aignostics/components';
import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { ADD_USER_TO_PROJECT } from './ADD_USER_TO_PROJECT';
import { INVITE_USER_TO_PROJECT } from './INVITE_USER_TO_PROJECT';
import { REMOVE_USER_FROM_PROJECT } from './REMOVE_USER_FROM_PROJECT';

const UPDATE_USER_IN_PROJECT = gql`
  fragment UpdateUserInProject on User {
    inParent(projectId: $projectId)
  }
`;

export const useProjectUsersMutations = ({
  projectId,
  refreshGrid,
}: {
  projectId: string;
  refreshGrid: () => void;
}): {
  error: ApolloError | undefined;
  loading: boolean;
  addUser: (userId: string) => void;
  addUserLoading: boolean;
  removeUser: (userId: string) => void;
  removeUserLoading: boolean;
  sendInvitation: (userId: string) => Promise<void>;
} => {
  const { addSnackbar } = useSnackbarMutations();

  /** Add user to given project */
  const [addUserToProject, { loading: addUserLoading, error: addUserError }] =
    useMutation<{ addUserToProject: string }>(ADD_USER_TO_PROJECT, {
      onError: (error: ApolloError) => {
        if (error.message === 'User is already assigned to project.') {
          refreshGrid();
        }
      },
      onCompleted: () => {
        refreshGrid();
      },
    });

  /** Remove user from given project */
  const [
    removeUserFromProject,
    { loading: removeUserLoading, error: removeUserError },
  ] = useMutation(REMOVE_USER_FROM_PROJECT, {
    onCompleted: () => {
      refreshGrid();
    },
  });

  /** Send invitation to a user for given project */
  const [inviteUserToProject, { loading: inviteUserLoading }] = useMutation(
    INVITE_USER_TO_PROJECT
  );

  /** Component error state requires setter for catching mutation errors. */
  const [error, setError] = useState(addUserError || removeUserError);

  /** Request loading state */
  const loading = addUserLoading || removeUserLoading || inviteUserLoading;

  /** Error handler to wrap mutation promises. */
  const catchError = async (request: Promise<FetchResult>): Promise<void> => {
    try {
      await request;
    } catch (error) {
      if (!(error instanceof ApolloError)) return;
      Sentry.captureException(error);
      setError(error);
    }
  };

  const addUser = useCallback(
    (userId: string) => {
      void catchError(
        addUserToProject({
          variables: { userId, projectId },
          update: (cache) => {
            /** Update cached user entity */
            cache.writeFragment({
              id: `User:${userId}`,
              fragment: UPDATE_USER_IN_PROJECT,
              variables: { projectId },
              data: { inParent: true },
            });
            cache.modify({
              id: cache.identify({ __ref: `Project:${projectId}` }),

              fields: {
                usersCount(oldCount) {
                  return oldCount + 1;
                },
              },
            });
          },
        })
      );
    },
    [addUserToProject, projectId]
  );

  const removeUser = useCallback(
    (userId: string) => {
      void catchError(
        removeUserFromProject({
          variables: { userId, projectId },
          update: (cache) => {
            /** Update cached user entity */
            cache.writeFragment({
              id: `User:${userId}`,
              fragment: UPDATE_USER_IN_PROJECT,
              variables: { projectId },
              data: { inParent: false },
            });
            cache.modify({
              id: cache.identify({ __ref: `Project:${projectId}` }),

              fields: {
                usersCount(oldCount) {
                  return Math.max(oldCount - 1, 0);
                },
              },
            });
          },
        })
      );
    },
    [projectId, removeUserFromProject]
  );

  const sendInvitation = useCallback(
    async (userId: string) => {
      try {
        await inviteUserToProject({
          variables: {
            userId,
            projectId,
          },
        });
        addSnackbar({
          type: 'success',
          message: `Invitation sent`,
        });
      } catch (error) {
        Sentry.captureException(error);
        addSnackbar({
          type: 'error',
          message: 'Error sending invitation',
        });
      }
    },
    [addSnackbar, inviteUserToProject, projectId]
  );

  return {
    error,
    loading,
    addUser,
    addUserLoading,
    removeUser,
    removeUserLoading,
    sendInvitation,
  };
};
