import {
  Button,
  Input,
  Modal,
  useSnackbarMutations,
} from '@aignostics/components';
import { User } from '@aignostics/core';
import { useMutation } from '@apollo/client';
import React, { FormEvent, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { cacheCreateProject } from '../../../utils';
import { CREATE_PROJECT } from '../graphql/CREATE_PROJECT';

export const $CreateProjectModalBody = styled.div`
  padding: ${({ theme }) => theme.spacings[4]}px
    ${({ theme }) => theme.spacings[32]}px;
  text-align: left;
`;

export const $ProjectFieldLabel = styled.p`
  margin-bottom: ${({ theme }) => theme.spacings[4]}px;
  font-size: 13px;
`;

export const $SubprojectFieldLabel = styled.p`
  margin-top: ${({ theme }) => theme.spacings[16]}px;
  margin-bottom: ${({ theme }) => theme.spacings[4]}px;
  font-size: 13px;
  opacity: 0.4;
`;

interface CreateProjectModalProps {
  isVisible?: boolean;
  onClose: () => void;
  isCreateRoute: boolean;
  organizationUuid: string;
  currentUser: User;
}

export const CreateProjectModal = ({
  isVisible = true,
  onClose,
  isCreateRoute = false,
  organizationUuid,
  currentUser,
}: CreateProjectModalProps): ReactElement => {
  const [isVisibleState, setIsVisible] = useState(isVisible);
  const [projectName, setProjectName] = useState('');
  const [subProjectName, setSubProjectName] = useState('');
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarMutations();
  const onCloseWrapper = () => {
    setIsVisible(false);
    if (isCreateRoute) {
      void navigate(`/${organizationUuid}/admin/projects/`);
    }
    onClose();
  };
  /** Create project */
  const [createProject, { loading: createProjectLoading }] = useMutation<{
    createProject: { id: string; subProjectId: string };
  }>(CREATE_PROJECT);

  /** Create or update project */
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!projectName.length) {
      addSnackbar({
        type: 'error',
        message: `Project name should be defined`,
        closesAfter: 3000,
      });
      return;
    }
    const variables = { name: projectName, subProjectName };
    return createProject({
      variables,
      update: cacheCreateProject(
        { name: projectName },
        { name: subProjectName },
        currentUser
      ),
    })
      .then(({ data }) => {
        const projectId = data?.createProject.id;
        if (subProjectName?.length > 0) {
          const subProjectId = data?.createProject.subProjectId;
          addSnackbar({
            type: 'success',
            message: 'Project and Sub Project created successfully',
            closesAfter: 3000,
          });
          void navigate(
            `/${organizationUuid}/admin/projects/${projectId}/subproject/${subProjectId}`
          );
          return;
        }
        addSnackbar({
          type: 'success',
          message: 'Project created successfully',
        });
        void navigate(`/${organizationUuid}/admin/projects/${projectId}/`);
      })
      .catch((error) => {
        addSnackbar({
          type: 'error',
          message: `Error creating project: ${error}`,
        });
      });
  };
  return (
    <Modal
      footer={
        <div style={{ padding: '32px' }}>
          <Button
            name="Create"
            form="createProject"
            disabled={createProjectLoading}
            loading={createProjectLoading}
          >
            Create
          </Button>
          <Button
            name="Cancel"
            disabled={createProjectLoading}
            variant="primaryOutline"
            style={{ marginLeft: '16px' }}
            onClick={() => {
              onCloseWrapper();
            }}
          >
            Cancel
          </Button>
        </div>
      }
      hasCloseButton={!createProjectLoading}
      header={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '28px',
            margin: '32px',
          }}
        >
          <h1
            style={{
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '28px',
              margin: 0,
            }}
          >
            Create new project
          </h1>
        </div>
      }
      isVisible={isVisibleState}
      onClose={() => {
        onCloseWrapper();
      }}
      shouldCloseOnBackdropClick={!createProjectLoading}
      size={'small'}
    >
      <form id="createProject" onSubmit={handleSubmit}>
        <$CreateProjectModalBody>
          <$ProjectFieldLabel>Project name*</$ProjectFieldLabel>
          <Input
            autoFocus
            name="ProjectName"
            value={projectName}
            id="projectName"
            placeholder="Enter project name"
            onChange={(value) => {
              setProjectName(value);
            }}
          />
          <$SubprojectFieldLabel>Subproject Name</$SubprojectFieldLabel>
          <Input
            name="SubProjectName"
            value={subProjectName}
            id="subProjectName"
            placeholder="Enter subproject name"
            onChange={(value) => {
              setSubProjectName(value);
            }}
          />
        </$CreateProjectModalBody>
      </form>
    </Modal>
  );
};
