import {
  CancerSite,
  Disease,
  Morphology,
  PreparationType,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../../types';
import { ParserSelectData } from '../types';
import { displayScannerName } from './displayScannerName';

/* eslint-disable-next-line jsdoc/check-line-alignment */
/**
 * Normalizes passed entity to the map with lowercased value keys:
 *
 * Example:
 *
 * ```
 * Map {
 *   'bone marrow' => { id: '14', name: 'Bone Marrow' },
 *   'breast' => { id: '1', name: 'Breast' },
 *   'colon' => { id: '2', name: 'Colon' }
 * }
 * ```
 */
const normalizeEntities = <Entity, Prop extends keyof Entity>(
  entities: Entity[],
  field: Prop
): Map<Entity[Prop], Entity> => {
  const result = new Map<Entity[Prop], Entity>();

  for (const entity of entities) {
    const entityValue = entity[field];
    if (entityValue !== null) {
      const normalizedEntityValue =
        typeof entityValue === 'string'
          ? (entityValue.toLowerCase() as unknown as Entity[Prop])
          : entityValue;
      result.set(normalizedEntityValue, entity);
    }
  }

  return result;
};

export type NormalizedEntities = {
  normalizedTissues: Map<string, Tissue>;
  normalizedStainings: Map<string, Staining>;
  normalizedScanners: Map<string, Scanner>;
  normalizedDiseases: Map<string, Disease>;
  normalizedPreparationTypes: Map<string, PreparationType>;
  normalizedSampleTypes: Map<string, SampleType>;
  normalizedMorphologies: Map<string, Morphology>;
  normalizedCancerSites: Map<string, CancerSite>;
};
export const normalizeAllEntities = (
  selectData: ParserSelectData
): NormalizedEntities => {
  const {
    tissues,
    stainings,
    scanners,
    diseases,
    // TODO: [FE-5563]: Unify naming for samplePreparations and preparationTypes
    samplePreparations: preparationTypes,
    sampleTypes,
    morphologies,
    cancerSites,
  } = selectData;

  const normalizedTissues = normalizeEntities(tissues, 'name');
  const normalizedStainings = normalizeEntities(stainings, 'name');
  const normalizedScanners = normalizeEntities(
    scanners.map((scanner) => ({
      ...scanner,
      displayName: displayScannerName(scanner),
    })),
    'displayName'
  );
  const normalizedDiseases = normalizeEntities(diseases, 'name');
  const normalizedPreparationTypes = normalizeEntities(
    preparationTypes,
    'name'
  );
  const normalizedSampleTypes = normalizeEntities(sampleTypes, 'name');
  // morphologies can be matched by display name and code only
  const normalizedMorphologies = new Map([
    ...normalizeEntities(morphologies, 'displayName'),
    ...normalizeEntities(morphologies, 'code'),
  ]);
  const normalizedCancerSites = normalizeEntities(cancerSites, 'name');

  return {
    normalizedTissues,
    normalizedStainings,
    normalizedScanners,
    normalizedDiseases,
    normalizedPreparationTypes,
    normalizedSampleTypes,
    normalizedMorphologies,
    normalizedCancerSites,
  };
};
