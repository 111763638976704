import {
  Header,
  HStack,
  Icon,
  IconButton,
  Tooltip,
} from '@aignostics/components';
import { useViewport } from '@aignostics/hooks';
import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from 'react';
import { useTheme } from 'styled-components';
import { type SideNav } from '../../__Layout/Layout/Layout.Context';
import { UserFeedback } from '../../__Layout/UserFeedback';
import { useImpersonation } from '../../App/ImpersonationProvider';
import Crumbs from '../Crumbs/Crumbs';
import {
  $AppSubHeader,
  $MenuSidebarToggleContainer,
  $WarningIconContainer,
} from './AppHeader.styles';
import { UserManualLink } from './UserManualLink.component';

interface AppHeaderProps {
  children?: ReactNode;
  sideNav: SideNav;
  setSideNav: Dispatch<SetStateAction<SideNav>>;
}

/**
 * Global application header for all views.
 */
const AppHeader = ({
  sideNav,
  setSideNav,
  children,
}: AppHeaderProps): ReactElement => {
  const theme = useTheme();
  const sideNavActive = sideNav === 'menu';
  const { impersonatedUserEmail } = useImpersonation();

  const toggleSideNav = (value: SideNav) => {
    setSideNav(sideNav === value ? null : value);
  };

  const { FULL_EXTENDED_DATA_ONBOARDING: FULL } = useViewport(
    theme.breakpoints
  );

  return (
    <div>
      <Header
        size="button"
        color={theme.colors.dark}
        actionLeft={
          <Tooltip
            content={
              impersonatedUserEmail
                ? `Impersonating ${impersonatedUserEmail}`
                : ''
            }
          >
            <$MenuSidebarToggleContainer>
              <IconButton
                aria-label="Sidebar Navigation"
                description="Navigation"
                key={sideNavActive ? 'close' : 'open'}
                icon={sideNavActive ? 'X' : 'Menu'}
                onClick={() => {
                  toggleSideNav('menu');
                }}
              />
              {impersonatedUserEmail ? (
                <$WarningIconContainer>
                  <Icon color="warning" size="small" icon="AlertTriangle" />
                </$WarningIconContainer>
              ) : null}
            </$MenuSidebarToggleContainer>
          </Tooltip>
        }
        actionRight={
          <HStack>
            <UserManualLink />
            <UserFeedback />
          </HStack>
        }
      >
        <Crumbs />
        {/* e.g. <Magnifier/> on slide view */}
        {FULL && children}
      </Header>
      {!FULL && <$AppSubHeader>{children}</$AppSubHeader>}
    </div>
  );
};

export default AppHeader;
