import type { WsiMetadataChannel } from '@aignostics/extract-wsi-metadata';
import { pluralizeWithCount } from '@aignostics/utils';
import { type ValidationErrors } from 'final-form';
import { last as _last, uniqBy as _uniqBy } from 'lodash';
import type { FetchedOnboardingBatch } from '../../../graphql/queries/FETCH_ONBOARDING_WSIS';
import type { Scanner, Staining } from '../../../types';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../const';
import { countSucceededSlideInOnboardingBatch } from './countSucceededSlideInOnboardingBatch';
import type {
  BatchReadOnlyForm,
  CreateOnboardingBatchWsiRecord,
  FormErrors,
  MultiChannelFormRow,
  MultiChannelFormRowFilled,
  OnboardingFileWithMetadata,
  SingleChannelFormRow,
  SingleChannelFormRowFilled,
  StainingsMismatchErrorsWithSlideIndex,
  UpdateOnboardingBatchWsiRecord,
} from './form.state.types';
import { getFirstMatchingPairOrWsisGroup } from './getFirstMatchingPairOrWsisGroup';
import { sortSlidesAlphabeticallyByFileName } from './sortSlidesAlphabeticallyByFileName';
import type { MatchingWsisGroup } from './useMatchingWsis';

const getMultiChannelMetadataFromFile = (file: OnboardingFileWithMetadata) =>
  (file.metadata.fileType === 'czi' || file.metadata.fileType === 'qptiff') &&
  file.metadata.channels?.[0]
    ? JSON.stringify({
        [file.metadata.fileType]: {
          channel_name: file.metadata.channels?.[0].name,
          index: file.metadata.type === 'he-rgb' ? null : 0,
          original_staining: file.metadata.channels?.[0].staining,
        },
      })
    : null;

/** Convert state to a form which can be submitted by the API */
export const convertStateToRecords = (
  files: OnboardingFileWithMetadata[],
  slides: Array<SingleChannelFormRowFilled | MultiChannelFormRowFilled>
): CreateOnboardingBatchWsiRecord[] =>
  slides.flatMap((slide) =>
    slide.type === 'single-channel'
      ? {
          caseId: slide.caseName,
          block: slide.block,
          section: slide.section,
          patientExternalId: slide.patientExternalId,
          checksum: slide.slideFile.checksum,
          filename: slide.slideFile.filename,
          size: slide.slideFile.size,
          tissue: slide.tissue,
          staining: slide.staining,
          scannerId: slide.scannerId,
          disease: slide.disease,
          samplePreparation: slide.samplePreparation,
          sampleType: slide.sampleType,
          morphology: slide.morphology,
          cancerSite: slide.cancerSite,
          relatedSlide: null,
          relationType: 'no_parent',
          source: slide.slideFile.source,
          metadata: getMultiChannelMetadataFromFile(
            files[slide.slideFile.fileIndex]
          ),
          parentTmaRow: slide.parentTmaRow,
          parentTmaCol: slide.parentTmaCol,
          parentSlidePosX: slide.parentSlidePosX,
          parentSlidePosY: slide.parentSlidePosY,
          parentWsiUuid: slide.parentWsiUuid,
          caseUuid: slide.caseUuid,
          wsiUuid: slide.wsiUuid,
        }
      : slide.channels.map(
          (channel, index): CreateOnboardingBatchWsiRecord => ({
            caseId: slide.parentAssigned.caseName,
            block: slide.parentAssigned.block,
            section: slide.parentAssigned.section,
            patientExternalId: slide.parentAssigned.patientExternalId,
            checksum: slide.slideFile.checksum,
            filename: slide.slideFile.filename,
            size: slide.slideFile.size,
            tissue: slide.parentAssigned.tissue,
            staining: channel.staining,
            disease: slide.parentAssigned.disease,
            samplePreparation: slide.parentAssigned.samplePreparation,
            sampleType: slide.parentAssigned.sampleType,
            morphology: slide.parentAssigned.morphology,
            cancerSite: slide.parentAssigned.cancerSite,
            scannerId: slide.parentAssigned.scannerId,
            metadata: JSON.stringify({
              [files[slide.slideFile.fileIndex].metadata.fileType]: {
                channel_name: channel.name,
                index,
                original_staining: channel.originalStaining,
              },
            }),
            relatedSlide: slide.parentAssigned.name,
            relationType: 'temporary_parent',
            source: slide.slideFile.source,
            parentTmaRow: slide.parentAssigned.parentTmaRow,
            parentTmaCol: slide.parentAssigned.parentTmaCol,
            parentSlidePosX: slide.parentAssigned.parentSlidePosX,
            parentSlidePosY: slide.parentAssigned.parentSlidePosY,
            parentWsiUuid: slide.parentAssigned.parentWsiUuid,
            caseUuid: slide.parentAssigned.caseUuid,
            wsiUuid: slide.parentAssigned.wsiUuid,
          })
        )
  );

export const convertStateToUpdateWsiRecords = (
  slides: Array<SingleChannelFormRowFilled | MultiChannelFormRowFilled>
): UpdateOnboardingBatchWsiRecord[] =>
  slides.flatMap((slide) =>
    slide.type === 'single-channel'
      ? {
          wsiUuid: slide.wsiUuid ?? slide.id,
          originalWsiUuid: slide.originalWsiUuid!,
          patientExternalId: slide.patientExternalId,
          caseId: slide.caseName,
          block: slide.block,
          section: slide.section,
          scannerId: slide.scannerId,
          staining: slide.staining,
          tissue: slide.tissue,
          disease: slide.disease,
          samplePreparation: slide.samplePreparation,
          sampleType: slide.sampleType,
          morphology: slide.morphology,
          cancerSite: slide.cancerSite,
          parentTmaRow: slide.parentTmaRow,
          parentTmaCol: slide.parentTmaCol,
          parentSlidePosX: slide.parentSlidePosX,
          parentSlidePosY: slide.parentSlidePosY,
          parentWsiUuid: slide.parentWsiUuid,
          caseUuid: slide.caseUuid,
        }
      : slide.channels.map((channel) => ({
          wsiUuid: channel.id,
          originalWsiUuid: channel.id!,
          patientExternalId: slide.parentAssigned.patientExternalId,
          caseId: slide.parentAssigned.caseName,
          block: slide.parentAssigned.block,
          section: slide.parentAssigned.section,
          scannerId: slide.parentAssigned.scannerId,
          staining: channel.staining,
          tissue: slide.parentAssigned.tissue,
          disease: slide.parentAssigned.disease,
          samplePreparation: slide.parentAssigned.samplePreparation,
          sampleType: slide.parentAssigned.sampleType,
          morphology: slide.parentAssigned.morphology,
          cancerSite: slide.parentAssigned.cancerSite,
          parentTmaRow: slide.parentAssigned.parentTmaRow,
          parentTmaCol: slide.parentAssigned.parentTmaCol,
          parentSlidePosX: slide.parentAssigned.parentSlidePosX,
          parentSlidePosY: slide.parentAssigned.parentSlidePosY,
          parentWsiUuid: slide.parentAssigned.parentWsiUuid,
          caseUuid: slide.parentAssigned.caseUuid,
        }))
  );

export const getScannerId = (
  scannerModel: string,
  scannerType: string | null
): string => {
  if (scannerType) {
    return `${scannerModel} ${scannerType}`;
  }
  return scannerModel;
};
export const convertRecordsToState = ({
  id,
  batchName,
  associationName,
  species,
  status,
  createdAt,
  createdBy,
  wsis,
  totalSlideFiles,
  metadataUsageRestriction,
}: FetchedOnboardingBatch): BatchReadOnlyForm => {
  const groupedWsis: [string, FetchedOnboardingBatch['wsis']][] = [];
  let prevWsiPath: string | null = null;
  for (const wsi of wsis) {
    if (prevWsiPath !== wsi.path) {
      prevWsiPath = wsi.path;
      groupedWsis.push([wsi.path, [wsi]]);
    } else {
      _last(groupedWsis)![1].push(wsi);
    }
  }
  const slides: BatchReadOnlyForm['slides'] = [];
  const onboardingErrors: BatchReadOnlyForm['onboardingErrors'] = [];

  for (const [wsiIndex, [path, wsis]] of groupedWsis.entries()) {
    const firstWsi = wsis[0];
    // collect onboarding progress errors for successful wsis with errors
    const successfulWsisOnboardingErrors = firstWsi.onboardProgress.find(
      (stage) => {
        const stepHasErrors = stage.errors && stage.errors.length > 0;
        return (stepHasErrors && stage.status === 'success') ?? undefined;
      }
    );
    if (successfulWsisOnboardingErrors) {
      onboardingErrors.push({
        filename: firstWsi.originalFilename ?? path,
        errors: successfulWsisOnboardingErrors.errors,
      });
    }
    const slideInfo = {
      caseName: firstWsi.caseId,
      block: firstWsi.block,
      section: firstWsi.section,
      patientExternalId: firstWsi.patientExternalId,
      scannerId: getScannerId(firstWsi.scannerModel, firstWsi.scannerType),
      tissue: firstWsi.tissue,
      disease: firstWsi.disease ?? '',
      samplePreparation: firstWsi.samplePreparation ?? '',
      sampleType: firstWsi.sampleType ?? '',
      morphology: firstWsi.morphology ?? '',
      cancerSite: firstWsi.cancerSite ?? '',
      id: firstWsi.uuid,
      parentTmaRow: firstWsi.parentTmaRow,
      parentTmaCol: firstWsi.parentTmaCol,
      parentSlidePosX: firstWsi.parentSlidePosX,
      parentSlidePosY: firstWsi.parentSlidePosY,
      parentWsiUuid: firstWsi.parentWsiUuid,
      caseUuid: firstWsi.caseUuid,
      wsiUuid: firstWsi.uuid,
    };
    slides.push(
      wsis.length === 1
        ? {
            type: 'single-channel',
            slideFile: {
              filename: firstWsi.originalFilename ?? path,
              size: firstWsi.fileSize,
              checksum: 'stub',
              fileIndex: wsiIndex,
              type: 'stub',
              source: 'stub',
            },
            name: firstWsi.name,
            staining: firstWsi.staining,
            uploadProgress: firstWsi.uploadProgress,
            onboardProgress: firstWsi.onboardProgress,
            ...slideInfo,
          }
        : {
            type: 'multi-channel',
            parentAssigned: {
              type: 'temporary-parent',
              name: firstWsi.name ?? path,
              ...slideInfo,
            },

            slideFile: {
              filename: firstWsi.originalFilename ?? path,
              size: firstWsi.fileSize,
              checksum: 'stub',
              fileIndex: wsiIndex,
              type: 'stub',
              source: 'stub',
            },
            channels: wsis.map((wsi) => ({
              name:
                wsi.metadata?.czi?.channel_name ??
                wsi.metadata?.qptiff?.channel_name ??
                '',
              staining: wsi.staining,
              originalStaining: 'stub',
              uploadProgress: wsi.uploadProgress,
              onboardProgress: wsi.onboardProgress,
              id: wsi.uuid,
            })),
          }
    );
  }

  return {
    batch_id: id,
    batch_name: batchName,
    association: associationName,
    species,
    metadataUsageRestriction,
    status:
      onboardingErrors.length > 0 && status === 'completed'
        ? 'completed-with-errors'
        : status,
    createdAt,
    slides: slides.slice().sort(sortSlidesAlphabeticallyByFileName),
    succeededSlidesCount: countSucceededSlideInOnboardingBatch(slides),
    onboardingErrors,
    createdBy,
    totalSlideFiles,
  };
};

export const getMultiChannelStainingErrors = (
  slidesErrors: FormErrors,
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): StainingsMismatchErrorsWithSlideIndex =>
  slidesErrors?.reduce(
    (acc: StainingsMismatchErrorsWithSlideIndex, slideErrors, slideIndex) => {
      if (slideErrors && 'channels' in slideErrors && slideErrors.channels) {
        slideErrors.channels.forEach((channel, channelIndex) => {
          if (channel?.staining) {
            acc.push({
              slideIndex,
              channelIndex,
              value: (slides[slideIndex] as MultiChannelFormRow).channels[
                channelIndex
              ].staining,
            });
          }
        });
      }

      return acc;
    },
    []
  ) || [];

export const countValidSlides = (
  errors: ValidationErrors,
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): number => {
  const errorCount =
    errors?.slides?.filter(
      (slide: SingleChannelFormRow | MultiChannelFormRow) => slide !== undefined
    ).length ?? 0;
  return slides.length - errorCount;
};

/**
 * overrides select value with H&E if HE is selected, and he is not available
 * staining, and h&e is available
 */
export const overrideHEStaining = (
  stainings: Staining[] | null,
  staining: string | null
): string | null => {
  if (stainings === null || staining === null || !/^he$/i.test(staining)) {
    return staining;
  }
  const h_and_e = stainings.find(
    (staining) => staining.name.toLowerCase() === 'h&e'
  );

  return h_and_e ? h_and_e.name : staining;
};

export const generateInitialSlides = (
  stainings: Staining[] | null,
  uploadedFiles: OnboardingFileWithMetadata[]
): (SingleChannelFormRow | MultiChannelFormRow)[] => {
  const result: (SingleChannelFormRow | MultiChannelFormRow)[] = [];
  for (const [fileIndex, uploadedFile] of uploadedFiles.entries()) {
    const { filename, size, checksum, metadata, type, source } = uploadedFile;
    const slideFile = {
      checksum,
      filename,
      fileIndex,
      size,
      type,
      source,
    };
    const slideInfo = {
      caseName: '',
      block: '',
      section: '',
      patientExternalId: '',
      scannerId: null,
      staining: null,
      tissue: null,
      disease: DEFAULT_DISEASE_VALUE,
      samplePreparation: DEFAULT_PREPARATION_TYPE_VALUE,
      sampleType: DEFAULT_SAMPLE_TYPE_VALUE,
      morphology: DEFAULT_MORPHOLOGY_VALUE,
      cancerSite: DEFAULT_CANCER_SITE_VALUE,
      parentTmaRow: null,
      parentTmaCol: null,
      parentSlidePosX: null,
      parentSlidePosY: null,
      parentWsiUuid: null,
      caseUuid: null,
      wsiUuid: null,
    };
    if (metadata.type !== 'multiplex') {
      const slide: SingleChannelFormRow = {
        type: 'single-channel',
        slideFile,
        ...slideInfo,
      };
      if (metadata.fileType === 'czi' || metadata.fileType === 'qptiff') {
        slide.staining = overrideHEStaining(
          stainings,
          metadata.channels?.[0]?.staining?.toLowerCase() ?? null
        );
      }
      result.push(slide);
    } else {
      result.push({
        type: 'multi-channel',
        parentAssigned: {
          type: 'temporary-parent',
          name: slideFile.filename,
          ...slideInfo,
        },
        slideFile,
        channels: metadata.channels.map(
          (channelMetadata: WsiMetadataChannel) => ({
            name: channelMetadata.name,
            originalStaining: channelMetadata.staining ?? null,
            staining: overrideHEStaining(
              stainings,
              channelMetadata.staining?.toLowerCase() ?? null
            ),
          })
        ),
      });
    }
  }

  return result;
};

export const generateMatchingWsisSummary = (
  localSlide: SingleChannelFormRow | MultiChannelFormRow,
  matchingWsisInDb: MatchingWsisGroup[]
): string | null => {
  const matchingWsisGroup = getFirstMatchingPairOrWsisGroup(
    localSlide,
    matchingWsisInDb
  );

  return matchingWsisGroup && matchingWsisGroup.matchingWsisCount > 0
    ? `${pluralizeWithCount('file', matchingWsisGroup.matchingWsisCount)} in our Database with the same Case Name`
    : null;
};

export const mapSlidesToSlidesWithScannerIds = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  scanners: Scanner[] | undefined
): Array<SingleChannelFormRow | MultiChannelFormRow> =>
  slides.map((slide) => {
    const scannerId =
      scanners?.find((scanner) => {
        const slideData =
          slide.type === 'single-channel' ? slide : slide.parentAssigned;

        return (
          slideData.scannerId === getScannerId(scanner.vendor, scanner.model)
        );
      })?.id ?? null;

    return {
      ...slide,
      ...(slide.type === 'single-channel'
        ? { originalWsiUuid: slide.wsiUuid! }
        : {}),
      ...(slide.type === 'multi-channel'
        ? {
            parentAssigned: {
              ...slide.parentAssigned,
              originalWsiUuid: slide.parentAssigned.wsiUuid!,
              scannerId,
            },
          }
        : { scannerId }),
    };
  });

export const extractUniqueSlideIndexesFromStainingMismatchErrors = (
  errors: StainingsMismatchErrorsWithSlideIndex
): number[] => _uniqBy(errors, 'slideIndex').map((v) => v.slideIndex);
