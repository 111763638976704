import {
  E_PROJECT_NOT_FOUND,
  E_PROJECT_UNAUTHORIZED,
  E_SUBPROJECT_NOT_FOUND,
  E_SUBPROJECT_UNAUTHORIZED,
  E_WSI_NOT_FOUND,
  E_WSI_UNAUTHORIZED,
} from '@aignostics/core';
import { type ApolloError } from '@apollo/client';
import { CrumbProps } from './Crumb';
/**
 * Converts ApolloError into normal one, ignoring `E_PROJECT_UNAUTHORIZED`,
 * `E_PROJECT_NOT_FOUND`, `E_SUBPROJECT_UNAUTHORIZED`, `E_SUBPROJECT_NOT_FOUND`,
 * `E_WSI_UNAUTHORIZED` and `E_WSI_NOT_FOUND`.
 */
export const sanitizeError = (
  apolloError: ApolloError | undefined
): Error | null => {
  if (!apolloError) {
    return null;
  }

  const errors = apolloError.graphQLErrors.filter(
    ({ message }) =>
      ![
        E_PROJECT_UNAUTHORIZED,
        E_PROJECT_NOT_FOUND,
        E_SUBPROJECT_UNAUTHORIZED,
        E_SUBPROJECT_NOT_FOUND,
        E_WSI_UNAUTHORIZED,
        E_WSI_NOT_FOUND,
      ].includes(message)
  );

  if (errors.length === 0) {
    return null;
  }

  return new Error(errors.map(({ message }) => message).join('\n'));
};

export type CrumbsData = {
  project?: { id: string; isAdminView: boolean; name: string };
  subProject?: { id: string; name: string };
  wsi?: { id: string; name: string };
  user?: { id: string; name?: string; email: string };
  batchId?: string;
  organizationUuid?: string;
};
export type CrumbsHandler = (v: CrumbsData) => CrumbProps | null;
export const crumbsHandlers: Record<string, CrumbsHandler> = {
  project: ({ project, organizationUuid }) =>
    project
      ? {
          title: project?.name ?? '',
          url: `/${organizationUuid}/project/${project?.id}`,
        }
      : null,
  subProject: ({ project, subProject, organizationUuid }) =>
    subProject
      ? {
          title: subProject?.name ?? '',
          url: `/${organizationUuid}/project/${project?.id}/subproject/${subProject?.id}`,
        }
      : null,
  wsi: ({ project, subProject, wsi, organizationUuid }) => {
    return wsi
      ? {
          title: wsi?.name ?? '',
          url: `/${organizationUuid}/project/${project?.id}/${subProject?.id}/wsi/${wsi?.id}`,
        }
      : null;
  },
  adminRoot: ({ organizationUuid }) => ({
    title: 'Admin',
    url: `/${organizationUuid}/admin`,
  }),
  adminWsi: ({ wsi, organizationUuid }) =>
    wsi
      ? {
          title: wsi?.name ?? '',
          url: `/${organizationUuid}/admin/wsi/${wsi?.id}`,
        }
      : null,
  adminUserList: ({ organizationUuid }) => ({
    title: 'Users',
    url: `/${organizationUuid}/admin/users`,
  }),
  adminUser: ({ user, organizationUuid }) =>
    user
      ? {
          title: user.name || user.email,
          url: `/${organizationUuid}/admin/users/${user.id}`,
        }
      : null,
  adminProjectList: ({ organizationUuid }) => ({
    title: 'Projects',
    url: `/${organizationUuid}/admin/projects`,
  }),
  adminProject: ({ project, organizationUuid }) =>
    project
      ? {
          title: project?.name ?? '',
          url: `/${organizationUuid}/admin/projects/${project?.id}`,
        }
      : null,
  adminProjectCreate: ({ organizationUuid }) => ({
    title: 'Create Project',
    url: `/${organizationUuid}/admin/projects/create`,
  }),
  adminSubProject: ({ project, subProject, organizationUuid }) =>
    subProject
      ? {
          title: subProject?.name ?? '',
          url: `/${organizationUuid}/admin/projects/${project?.id}/subproject/${subProject?.id}`,
        }
      : null,
  adminDataOnboarding: ({ organizationUuid }) => ({
    title: 'Onboard Slides',
    url: `/${organizationUuid}/admin/data-onboarding`,
  }),
  adminDataOnboardingBatch: ({ batchId, organizationUuid }) =>
    batchId
      ? {
          title: 'Data Onboarding Batch',
          url: `/${organizationUuid}/admin/data-onboarding/batch/${batchId}`,
        }
      : null,
  adminBatchEditSlide: ({ batchId, organizationUuid }) =>
    batchId
      ? {
          title: 'Edit Slides',
          url: `/${organizationUuid}/admin/data-onboarding/batch/${batchId}/edit`,
        }
      : null,
  adminSelectSlides: ({ organizationUuid }) => ({
    title: 'Select Slides',
    url: `/${organizationUuid}/admin/data-onboarding/transfer-slides`,
  }),
  adminUploadSlides: ({ organizationUuid }) => ({
    title: 'Upload Slides',
    url: `/${organizationUuid}/admin/data-onboarding/upload-slides`,
  }),
  adminSlideLibrary: ({ organizationUuid }) => ({
    title: 'Slide Library',
    url: `/${organizationUuid}/admin/slide-library`,
  }),
  adminAnnotationManagement: ({ organizationUuid }) => ({
    title: 'Annotation Management',
    url: `/${organizationUuid}/annotation-management`,
  }),
};
