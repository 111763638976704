import {
  HStack,
  IconButton,
  Modal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { AnnotationCategoryForm } from '../../../../components';
import {
  AnnotationCategory,
  AnnotationCategoryInput,
  AnnotationCategorySet,
} from '../../../../types';
import { FETCH_ANNOTATION_CATEGORY_SETS } from '../../CategorySets';
import { FETCH_CAN_DELETE_ANNOTATION_CATEGORY } from '../grahphql/FETCH_CAN_DELETE_ANNOTATION_CATEGORY';
import { UPDATE_ANNOTATION_CATEGORY } from '../grahphql/UPDATE_ANNOTATION_CATEGORY';
import { ConfirmUpdateAnnotationCategoryModal } from './ConfirmUpdateAnnotationCategoryModal/ConfirmUpdateAnnotationCategoryModal.component';
import { DeleteAnnotationCategoryModal } from './DeleteAnnotationCategoryModal/DeleteAnnotationCategoryModal.component';

export type CanDeleteAnnotationCategory = {
  canDelete: boolean;
  hasAnnotation: boolean;
  isAssignedToSubproject: boolean;
};

export const AnnotationCategoriesTableRowActions = ({
  annotationCategory,
  onRefresh,
}: {
  annotationCategory: AnnotationCategory;
  onRefresh: () => void;
}): ReactElement => {
  const deleteAnnotationCategoryDisclosure = useDisclosure();
  const editAnnotationCategoryDisclosure = useDisclosure();
  const confirmUpdateAnnotationCategoryDisclosure = useDisclosure();

  const [
    updateAnnotationCategoryMutationVariables,
    setUpdateAnnotationCategoryMutationVariables,
  ] = useState<Partial<AnnotationCategoryInput> | null>(null);

  const { addSnackbar } = useSnackbarMutations();

  const [fetchCanDeleteAnnotationCategory, { data, loading }] = useLazyQuery<{
    canDeleteAnnotationCategory: {
      canDelete: boolean;
      hasAnnotation: boolean;
      isAssignedToSubproject: boolean;
    };
  }>(FETCH_CAN_DELETE_ANNOTATION_CATEGORY, {
    variables: {
      annotationCategoryId: annotationCategory.id,
    },
  });

  useQuery<{
    annotationCategorySets: {
      nodes: AnnotationCategorySet[];
    };
  }>(FETCH_ANNOTATION_CATEGORY_SETS, {});

  const canDeleteAnnotationCategory = data?.canDeleteAnnotationCategory ?? null;

  const [
    updateAnnotationCategory,
    { loading: updateAnnotationCategoryLoading },
  ] = useMutation(UPDATE_ANNOTATION_CATEGORY, {
    onCompleted: () => {
      addSnackbar({
        type: 'success',
        message: 'Annotation category was successfully updated.',
      });
      editAnnotationCategoryDisclosure.close();
      onRefresh();
    },
    onError: (error) => {
      addSnackbar({
        type: 'error',
        message: error.message,
      });
    },
  });

  const handleUpdateAnnotationCategory = async ({
    name,
    color,
    id,
    annotationCategorySet,
  }: AnnotationCategoryInput) => {
    const updatedAnnotationCategory = {
      name,
      color,
      id,
      annotationCategorySet,
    };
    if (name !== annotationCategory.name) {
      setUpdateAnnotationCategoryMutationVariables(updatedAnnotationCategory);
      confirmUpdateAnnotationCategoryDisclosure.open();
    } else {
      await updateAnnotationCategory({
        variables: {
          annotationCategory: updatedAnnotationCategory,
        },
      });
    }
  };

  const handleConfirmUpdateAnnotationCategory = async () => {
    confirmUpdateAnnotationCategoryDisclosure.close();
    await updateAnnotationCategory({
      variables: {
        annotationCategory: updateAnnotationCategoryMutationVariables,
      },
    });
  };

  const shouldFetchCanDeleteAnnotationCategory =
    !loading && !canDeleteAnnotationCategory;

  return (
    <>
      <HStack spacing="16">
        <IconButton
          icon="Edit"
          aria-label="Edit Category"
          onClick={async () => {
            editAnnotationCategoryDisclosure.open();
            if (shouldFetchCanDeleteAnnotationCategory) {
              await fetchCanDeleteAnnotationCategory();
            }
          }}
        />
        <IconButton
          icon="Trash"
          aria-label="Delete Category"
          onClick={async () => {
            deleteAnnotationCategoryDisclosure.open();
            if (shouldFetchCanDeleteAnnotationCategory) {
              await fetchCanDeleteAnnotationCategory();
            }
          }}
        />
      </HStack>
      {deleteAnnotationCategoryDisclosure.isOpen ? (
        <DeleteAnnotationCategoryModal
          onDeleteAnnotationCategory={() => {
            deleteAnnotationCategoryDisclosure.close();
            onRefresh();
          }}
          onClose={() => {
            deleteAnnotationCategoryDisclosure.close();
          }}
          annotationCategoryId={annotationCategory.id}
          loading={loading}
          canDeleteAnnotationCategory={canDeleteAnnotationCategory}
        />
      ) : null}
      {editAnnotationCategoryDisclosure.isOpen ? (
        <>
          <Modal
            onClose={editAnnotationCategoryDisclosure.close}
            isVisible={editAnnotationCategoryDisclosure.isOpen}
            shouldCloseOnEscKey
            hasCloseButton
          >
            <AnnotationCategoryForm
              initialValues={annotationCategory}
              mode="edit"
              onSubmit={handleUpdateAnnotationCategory}
            />
          </Modal>
          <ConfirmUpdateAnnotationCategoryModal
            isOpen={confirmUpdateAnnotationCategoryDisclosure.isOpen}
            onClose={confirmUpdateAnnotationCategoryDisclosure.close}
            loading={updateAnnotationCategoryLoading}
            canDeleteAnnotationCategory={canDeleteAnnotationCategory}
            onUpdateAnnotationCategory={handleConfirmUpdateAnnotationCategory}
          />
        </>
      ) : null}
    </>
  );
};
