import { useDisclosure } from '@aignostics/components';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { CSVTemplateDownloadButton } from '../../../../components/OnboardingBatches/CSVTemplateDownloadButton.component';
import TransferCloudSlidesModal from '../../../TransferCloudSlides/TransferCloudSlidesModal.component';
import UploadSlidesModal from '../../../UploadSlides/UploadSlidesModal.component';
import { $OnboardingSlidesButtonsContainer } from './OnboardingSlideButtons.styles';
import { TransferCloudSlidesButton } from './TransferCloudSlidesButton';
import { UploadSlidesButton } from './UploadSlidesButton';

export function OnboardingSlideButtons({
  externalBucket,
}: {
  externalBucket: string | null;
}): ReactElement {
  const location = useLocation();
  const uploadSlidesModal = useDisclosure(
    location.state?.shouldOpenDataUploadModal === true
  );
  const selectSlidesModal = useDisclosure(
    location.state?.shouldOpenDataSelectModal === true
  );

  const showTransferFromCloudButton = Boolean(externalBucket);
  return (
    <>
      <$OnboardingSlidesButtonsContainer>
        <CSVTemplateDownloadButton />
        {showTransferFromCloudButton && (
          <TransferCloudSlidesButton onClick={selectSlidesModal.open} />
        )}
        <UploadSlidesButton onClick={uploadSlidesModal.open} />
      </$OnboardingSlidesButtonsContainer>

      {/* modal */}
      <TransferCloudSlidesModal
        isVisible={selectSlidesModal.isOpen}
        onClose={selectSlidesModal.close}
        externalBucket={externalBucket}
      />
      <UploadSlidesModal
        isVisible={uploadSlidesModal.isOpen}
        onClose={uploadSlidesModal.close}
      />
    </>
  );
}
