import type { OrganizationRole, User } from '@aignostics/core';
import {
  CellSelectionModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowGroupingModule,
  RowStyleModule,
  SelectEditorModule,
  TextEditorModule,
  themeQuartz,
  TooltipModule,
  TreeDataModule,
  ValidationModule,
  type Theme as AGGridTheme,
  type ColDef,
  type ColDefField,
  type GetDataPath,
  type GetRowIdFunc,
  type GridReadyEvent,
  type IsGroupOpenByDefaultParams,
} from 'ag-grid-enterprise';
import type { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, type RefObject } from 'react';
import { useTheme } from 'styled-components';
import type { ValidateOnboardingBatchFunc } from '../../../hooks';
import type { Morphology } from '../../../types';
import { useSelectedOrganizationUuid } from '../../../utils/useSelectedOrganizationUuid';
import type { BatchReadOnlyForm } from '../../AgGridSetFileMetadataStep/Form';
import { CHANNEL_ENABLED_FOR_EDITING_COLID } from '../../AgGridSetFileMetadataStep/MetadataGrid/MetadataGrid.utils';
import { convertOnboardingBatchesToRowData } from './BatchesGrid.dataFormatter';
import type { BatchesGridContext, GridDataBatches } from './BatchesGrid.types';
import { ActionsCell, GroupCell } from './CustomCellRenderers';

const modules = [
  CellSelectionModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowStyleModule,
  TextEditorModule,
  TooltipModule,
  RowGroupingModule,
  ValidationModule,
  SelectEditorModule,
  TreeDataModule,
];

const defaultColDef: ColDef<GridDataBatches> = {
  resizable: true,
  sortable: false,
  suppressHeaderMenuButton: true,
  filter: false,
  suppressMovable: true,
};

/** Common properties for row spanning/column spanning & styling. */
const COMMON_RULES: ColDef<GridDataBatches> = {
  cellClassRules: {
    'channel-first-row': (params) => {
      if (params.node.parent?.data?.type === 'multi-channel') {
        return params.node.childIndex === 0;
      }
      return false;
    },
    'channel-last-row': (params) => {
      if (
        params.node.childrenAfterSort &&
        params.node.parent?.data?.type === 'multi-channel'
      ) {
        return (
          params.node.childIndex === params.node.childrenAfterSort.length - 1
        );
      }
      return false;
    },
    'channel-disabled-cell': (params) => params.data?.type === 'channel',
  },
  rowSpan: (params) => {
    if (
      params.data &&
      params?.node?.parent?.childrenAfterSort &&
      params.data.type === 'channel'
    ) {
      return (
        params.node.parent.childrenAfterSort.length - params.node.childIndex
      );
    }
    return 1;
  },
  colSpan: (params) => {
    if (params.data && params.data.type === 'channel') {
      const displayedColIds = params.api
        .getDisplayedCenterColumns()
        .map((column) => column.getColId());
      const currentColIdx = displayedColIds.indexOf(params.column.getColId());

      let spanLength = 0;
      for (let x = currentColIdx; x < displayedColIds.length; x++) {
        if (displayedColIds[x] === CHANNEL_ENABLED_FOR_EDITING_COLID) {
          break;
        }
        spanLength++;
      }
      return spanLength;
    }

    return 1;
  },
};

const GENERATE_COMMON_COLUMN = (
  headerName: string,
  field: ColDefField<GridDataBatches>
): ColDef<GridDataBatches> => ({
  ...COMMON_RULES,
  headerName,
  field,
});

export type BatchesGridHookParams = {
  onboardingBatches: BatchReadOnlyForm[];
  currentUser: User;
  role: OrganizationRole;
  isValidating: boolean;
  isValidationSuccessfullyTriggered: boolean;
  validateOnboardingBatch: ValidateOnboardingBatchFunc;
  morphologies: Morphology[];
};

/** This hook returns all props for the ag-grid table. */
export const useBatchesGridProps = ({
  onboardingBatches,
  currentUser,
  role,
  isValidating,
  isValidationSuccessfullyTriggered,
  validateOnboardingBatch,
  morphologies,
}: BatchesGridHookParams): AgGridReactProps<GridDataBatches> & {
  ref: RefObject<AgGridReact<GridDataBatches>>;
} => {
  const organizationUuid = useSelectedOrganizationUuid();
  const gridRef = useRef<AgGridReact<GridDataBatches>>(null);

  const context: BatchesGridContext = useMemo(
    () => ({
      organizationUuid,
      role,
      currentUser,
      isValidating,
      isValidationSuccessfullyTriggered,
      validateOnboardingBatch,
      getOnboardingBatch: (batchId: string) =>
        onboardingBatches.find((batch) => batch.batch_id === batchId)!,
      isThisLatestBatchEntry: (batchId: string) =>
        onboardingBatches[0].batch_id === batchId,
    }),
    [
      organizationUuid,
      role,
      currentUser,
      isValidating,
      isValidationSuccessfullyTriggered,
      validateOnboardingBatch,
      onboardingBatches,
    ]
  );

  const autoGroupColumnDef: ColDef<GridDataBatches> = {
    headerName: 'Slide Name',
    pinned: 'left',
    cellRendererParams: {
      suppressCount: true,
      innerRenderer: GroupCell,
    },
  };

  const columnDefs = useMemo<ColDef<GridDataBatches>[]>(
    () => [
      {
        headerName: 'Staining*',
        field: 'staining',
        colSpan: (params) => {
          if (params.data?.type === 'batch-entry') {
            return 100;
          }
          return 1;
        },
        cellClassRules: {
          'channel-disabled-cell': (params) =>
            params.data?.type === 'batch-entry',
        },
      },
      GENERATE_COMMON_COLUMN('Scanner*', 'scannerId'),
      GENERATE_COMMON_COLUMN('Localization*', 'tissue'),
      GENERATE_COMMON_COLUMN('Patient ID', 'patientExternalId'),
      GENERATE_COMMON_COLUMN('Case Name*', 'caseName'),
      GENERATE_COMMON_COLUMN('Block', 'block'),
      GENERATE_COMMON_COLUMN('Section', 'section'),
      GENERATE_COMMON_COLUMN('Disease*', 'disease'),
      GENERATE_COMMON_COLUMN('Preparation Type*', 'samplePreparation'),
      GENERATE_COMMON_COLUMN('Sample Type*', 'sampleType'),
      {
        ...GENERATE_COMMON_COLUMN('Morphology*', 'morphology'),
        valueFormatter: ({ value }) => {
          const selectedMorphology = morphologies.find(
            (morphology) => morphology.code === value
          );
          return selectedMorphology?.displayName ?? value;
        },
      },
      GENERATE_COMMON_COLUMN('Cancer Site*', 'cancerSite'),
      {
        headerName: 'Actions',
        pinned: 'right',
        cellClassRules: {
          'channel-disabled-cell': (params) =>
            params.data?.type !== 'batch-entry',
        },
        rowSpan: (params) => {
          if (
            params.data &&
            params?.node?.parent?.childrenAfterSort &&
            params.data.type !== 'batch-entry'
          ) {
            return (
              params.node.parent.childrenAfterSort.length -
              params.node.childIndex
            );
          }
          return 1;
        },
        cellRenderer: ActionsCell,
      },
    ],
    [morphologies]
  );

  const rowData = useMemo<GridDataBatches[]>(
    () => convertOnboardingBatchesToRowData(onboardingBatches),
    [onboardingBatches]
  );

  // refresh cells on row data update
  useEffect(() => {
    const api = gridRef.current?.api;
    if (api && rowData.length > 0) {
      api.refreshCells({ force: true });
    }
  }, [rowData]);

  const theme = useTheme();
  const gridTheme = useMemo<AGGridTheme>(
    () => themeQuartz.withParams(theme.gridTheme),
    [theme]
  );

  const onGridReady: (event: GridReadyEvent<GridDataBatches>) => void =
    useCallback(({ api }) => {
      api.autoSizeAllColumns();
    }, []);

  const getRowId: GetRowIdFunc<GridDataBatches> = useCallback(
    ({ data }) => data.rowId,
    []
  );

  const getDataPath: GetDataPath<GridDataBatches> = useCallback(
    ({ path }) => path,
    []
  );

  const isGroupOpenByDefault = useCallback(
    ({ rowNode }: IsGroupOpenByDefaultParams<GridDataBatches>): boolean => {
      if (!rowNode.data) {
        return false;
      }

      const firstBatch = onboardingBatches[0];

      return firstBatch && firstBatch.batch_id === rowNode.data.batchId;
    },
    [onboardingBatches]
  );

  return {
    treeData: true,
    showOpenedGroup: false,
    context,
    defaultColDef,
    autoGroupColumnDef,
    columnDefs,
    suppressRowTransform: true,
    rowData,
    modules,
    theme: gridTheme,
    onGridReady,
    getRowId,
    getDataPath,
    isGroupOpenByDefault,
    ref: gridRef,
  };
};
