import { ApolloClient } from '@apollo/client';
import { ColDef, SetFilterValuesFuncParams } from 'ag-grid-enterprise';
import { CustomCellRendererProps } from 'ag-grid-react';
import React from 'react';
import { AnnotationCategory } from '../../../../types';
import { AnnotationCategoriesTableRowActions } from '../components/AnnotationCategoriesTableRowActions.component';
import {
  FETCH_MODULE_NAMES,
  FETCH_SET_CODES,
  FETCH_SET_NAMES,
} from '../grahphql/FETCH_ANNOTATION_CATEGORIES_FILTER_DATA';
import { AnnotationCategoryData } from '../hooks/useServerSideDatasource';

/**
 * Get column definitions for the annotation categories table
 */
export function getAnnotationCategoriesColumnDefs(
  client: ApolloClient<object>,
  refreshGrid: () => void
): ColDef<AnnotationCategoryData>[] {
  // Custom comparator function to always sort "Empty" to the top
  const emptyFirstComparator = (valueA: string, valueB: string) => {
    if (valueA === 'Empty') return -1;
    if (valueB === 'Empty') return 1;
    return valueA.localeCompare(valueB);
  };

  return [
    {
      headerName: 'Category Name',
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Category Color',
      field: 'color',
      cellRenderer: (
        params: CustomCellRendererProps<AnnotationCategoryData, string>
      ) => (
        <input
          type="color"
          disabled
          value={`#${params?.value?.replace('#', '')}`}
          style={{ width: '50px' }}
        />
      ),
      sortable: false,
    },
    {
      headerName: 'Set Identifier',
      field: 'setCode',
      sortable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams) => {
          const { data } = await client.query({
            query: FETCH_SET_CODES,
            fetchPolicy: 'network-only',
          });

          if (!data?.annotationCategorySets?.nodes) {
            params.success([]);
            return;
          }

          const setCodes = data.annotationCategorySets.nodes.map(
            (set: { code: string }) => set.code
          );

          params.success(['Empty', ...setCodes]);
        },
        comparator: emptyFirstComparator,
      },
      valueFormatter: (params) => params.value,
    },
    {
      headerName: 'Annotation Set',
      field: 'setName',
      sortable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams) => {
          const { data } = await client.query({
            query: FETCH_SET_NAMES,
            fetchPolicy: 'network-only',
          });

          if (!data?.annotationCategorySets?.nodes) {
            params.success([]);
            return;
          }

          const setNames = data.annotationCategorySets.nodes.map(
            (set: { name: string }) => set.name
          );

          params.success(['Empty', ...setNames]);
        },
        comparator: emptyFirstComparator,
      },
      valueFormatter: (params) => params.value,
    },
    {
      headerName: 'Module',
      field: 'setModule',
      sortable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        refreshValuesOnOpen: true,
        values: async (params: SetFilterValuesFuncParams) => {
          const { data } = await client.query({
            query: FETCH_MODULE_NAMES,
            fetchPolicy: 'network-only',
          });

          if (!data?.annotationCategorySets?.nodes) {
            params.success([]);
            return;
          }

          const modules = data.annotationCategorySets.nodes.flatMap(
            (set: { modules: Array<{ moduleName: string }> }) =>
              set.modules.map((module) => module.moduleName)
          );

          params.success(['Empty', ...modules]);
        },
        comparator: emptyFirstComparator,
      },
      valueFormatter: (params) => params.value,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      cellRenderer: (
        params: CustomCellRendererProps<AnnotationCategoryData, string>
      ) => {
        if (!params.data) return null;
        return (
          <AnnotationCategoriesTableRowActions
            annotationCategory={
              {
                id: params.data.id,
                name: params.data.name,
                color: params.data.color,
                setName: params.data.setName,
                setId: params.data.setId,
                setCode: params.data.setCode,
                setModule: params.data.setModule,
              } as AnnotationCategory
            }
            onRefresh={refreshGrid}
          />
        );
      },
    },
  ] satisfies ColDef<AnnotationCategoryData>[];
}
