import { useSnackbarMutations } from '@aignostics/components';
import { User } from '@aignostics/core';
import { useMutation, useQuery } from '@apollo/client';
import { useReducer } from 'react';
import { cacheCreateProject } from '../../../utils';
import { CREATE_PROJECT } from '../../ProjectList/graphql/CREATE_PROJECT';
import { CREATE_SUB_PROJECT } from '../../SubProject/Admin/AdminSubProjectDetails/CREATE_SUB_PROJECT.queries';
import { ADD_SLIDES_TO_SUB_PROJECT } from './ADD_SLIDES_TO_SUB_PROJECT';
import {
  ActionTypes,
  AssignSlidesModalState,
  initialState,
  reducer,
} from './AssignSlidesModal.reducer';
import { FETCH_ADMIN_SUB_PROJECT_DETAILS } from './FETCH_ADMIN_SUBPROJECT_DETAILS';
import {
  FETCH_PROJECTS_LIST,
  FETCH_SUBPROJECTS_LIST,
} from './FETCH_PROJECTS_LIST';
import { FETCH_SUBPROJECT_WSIS } from './FETCH_SUBPROJECT_WSIS';
import {
  READ_SUBPROJECT_SLIDES,
  READ_SUBPROJECT_SLIDES_VARIABLES,
} from './READ_SUBPROJECT_SLIDES.queries';

type ProjectType = {
  value: string;
  label: string;
};

type UseAssignSlidesToProject = {
  changeProject: (projectId: string | null) => void;
  setProjectName: (projectName: string) => void;
  setSubprojectName: (subprojectName: string) => void;
  resetState: () => void;
  toggleCreateSubproject: () => void;
  toggleCreateProject: () => void;
  changeSubproject: (subprojectId: string | null) => void;
  assignToProject: () => Promise<void>;
  subprojectsData: {
    subprojectsList: ProjectType[];
    loadingSubprojects: boolean;
  };
  projectsData: {
    projectsList: ProjectType[];
    loadingProjects: boolean;
  };
  state: AssignSlidesModalState;
};

export const ADMIN_ASSIGNED_SLIDES_PAGE_SIZE = 20;

function useAssignSlidesToProject(
  slides: string[] | [],
  currentUser: User
): UseAssignSlidesToProject {
  const { addSnackbar } = useSnackbarMutations();
  const [{ assignmentComplete, project, subproject, isSubmitting }, dispatch] =
    useReducer(reducer, initialState);

  const { data: projectsList, loading: loadingProjects } = useQuery(
    FETCH_PROJECTS_LIST,
    {
      variables: {
        isAdminView: true,
      },
    }
  );

  const [createProjectMutation] = useMutation<{
    createProject: {
      id: string;
      subProjectId: string;
    };
  }>(CREATE_PROJECT, {
    refetchQueries: [FETCH_SUBPROJECT_WSIS],
    onError: (e) => {
      addSnackbar({
        message: e.message,
        type: 'error',
      });
    },
  });

  const [createSubprojectMutation] = useMutation<{ createSubProject: string }>(
    CREATE_SUB_PROJECT,
    {
      onError: (e) => {
        addSnackbar({
          message: e.message,
          type: 'error',
        });
      },
    }
  );

  const { data: subprojectsList, loading: loadingSubprojects } = useQuery(
    FETCH_SUBPROJECTS_LIST,
    {
      skip: !project.id,
      variables: { projectId: project.id, isAdminView: true },
      fetchPolicy: 'no-cache',
    }
  );

  const [addSlidesToSubprojectMutation] = useMutation<void>(
    ADD_SLIDES_TO_SUB_PROJECT,
    {
      onCompleted: () => {
        dispatch({ type: ActionTypes.setAssignmentComplete });
      },
      onError: (e) => {
        dispatch({ type: ActionTypes.setSubmitting, isSubmitting: false });

        addSnackbar({
          message: e.message,
          type: 'error',
        });
      },
    }
  );

  const createProject = async (variables: {
    name: string;
    subProjectName: string;
    isVisible: boolean;
  }): Promise<{ id: string; subProjectId: string } | undefined> => {
    const { name, subProjectName } = variables;
    const newProject = await createProjectMutation({
      variables,
      update: cacheCreateProject(
        { name },
        { name: subProjectName },
        currentUser
      ),
    });

    return newProject.data?.createProject;
  };

  const createSubproject = async (variables: {
    name: string;
    projectId: string;
    isVisible: boolean;
  }): Promise<string | undefined> => {
    const newSubproject = await createSubprojectMutation({
      variables,
    });
    return newSubproject.data?.createSubProject;
  };

  const addSlidesToSubproject = async (
    subprojectId: string | null | undefined
  ) => {
    if (!subprojectId || !slides.length) return;

    const readSubprojectSlidesVariables: READ_SUBPROJECT_SLIDES_VARIABLES = {
      subProjectId: subprojectId,
      page: 1,
      pageSize: ADMIN_ASSIGNED_SLIDES_PAGE_SIZE,
      search: '',
      associations: [],
      batches: [],
      case: '',
      sortBy: 'name',
      isAsc: true,
      objectivePowers: [],
      scanners: [],
      stainings: [],
      tissues: [],
      diseases: [],
    };

    await addSlidesToSubprojectMutation({
      awaitRefetchQueries: true,
      variables: {
        slides,
        subprojectId,
      },
      refetchQueries: [
        {
          query: READ_SUBPROJECT_SLIDES,
          variables: readSubprojectSlidesVariables,
        },
        {
          query: FETCH_ADMIN_SUB_PROJECT_DETAILS,
          variables: { subprojectId },
        },
      ],
    });
  };

  const assignToProject = async () => {
    dispatch({ type: ActionTypes.setSubmitting, isSubmitting: true });

    if (project.isNew) {
      if (!project.name || !subproject.name) return;
      const newProject = await createProject({
        name: project.name,
        subProjectName: subproject.name,
        isVisible: true,
      });

      if (!newProject) return;

      dispatch({ type: ActionTypes.setProjectId, projectId: newProject.id });

      if (!newProject.subProjectId) return;

      dispatch({
        type: ActionTypes.setSubprojectId,
        subprojectId: newProject.subProjectId,
      });

      return addSlidesToSubproject(newProject.subProjectId);
    }
    if (subproject.isNew && !project.isNew) {
      if (!subproject.name || !project.id) return;

      const newSubproject = await createSubproject({
        name: subproject.name,
        projectId: project.id,
        isVisible: true,
      });

      if (!newSubproject) return;

      dispatch({
        type: ActionTypes.setSubprojectId,
        subprojectId: newSubproject,
      });

      return addSlidesToSubproject(newSubproject);
    }
    return addSlidesToSubproject(subproject.id);
  };

  const changeProject = (projectId: string | null) => {
    dispatch({ type: ActionTypes.setProjectId, projectId });
  };

  const setProjectName = (projectName: string) => {
    dispatch({ type: ActionTypes.setProjectName, projectName });
  };
  const setSubprojectName = (subprojectName: string) => {
    dispatch({ type: ActionTypes.setSubprojectName, subprojectName });
  };

  const resetState = () => {
    dispatch({ type: ActionTypes.reset });
  };

  const toggleCreateSubproject = () => {
    dispatch({ type: ActionTypes.toggleCreateSubproject });
  };

  const toggleCreateProject = () => {
    dispatch({ type: ActionTypes.toggleCreateProject });
  };
  const changeSubproject = (subprojectId: string | null) => {
    dispatch({
      type: ActionTypes.setSubprojectId,
      subprojectId,
    });
  };

  return {
    changeProject,
    setProjectName,
    setSubprojectName,
    resetState,
    toggleCreateSubproject,
    toggleCreateProject,
    changeSubproject,
    assignToProject,
    subprojectsData: {
      subprojectsList: subprojectsList?.project?.subProjects.nodes,
      loadingSubprojects,
    },
    projectsData: {
      projectsList: projectsList?.projects.nodes,
      loadingProjects,
    },
    state: { isSubmitting, assignmentComplete, project, subproject },
  };
}

export default useAssignSlidesToProject;
