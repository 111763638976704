import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../const';
import { MultiChannelFormRow, SelectData, SingleChannelFormRow } from '../Form';
import {
  csvParser,
  CSVParserInputWSIRow,
  MULTIPLE_STAININGS_SEPARATOR,
  ParserResult,
} from '../parser';

export const processCsvFile = async (
  csvFile: File,
  selectData: SelectData,
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): Promise<ParserResult> => {
  const wsis: CSVParserInputWSIRow[] = [];
  for (const slide of slides.values()) {
    const slideFile =
      slide.type === 'single-channel' ? slide : slide.parentAssigned;
    wsis.push({
      type: slide.type,
      Filename: slide.slideFile.filename,
      Localization: slideFile.tissue,
      Staining:
        slide.type === 'single-channel'
          ? slide.staining
          : slide.channels
              .map((channel) => channel.staining)
              .join(MULTIPLE_STAININGS_SEPARATOR),
      scannerId: slideFile.scannerId,
      'Case Name': slideFile.caseName,
      'Patient ID': slideFile.patientExternalId,
      Block: slideFile.block,
      Section: slideFile.section,
      Disease: slideFile.disease ?? DEFAULT_DISEASE_VALUE,
      'Preparation Type':
        slideFile.samplePreparation ?? DEFAULT_PREPARATION_TYPE_VALUE,
      'Sample Type': slideFile.sampleType ?? DEFAULT_SAMPLE_TYPE_VALUE,
      Morphology: slideFile.morphology ?? DEFAULT_MORPHOLOGY_VALUE,
      'Cancer Site': slideFile.cancerSite ?? DEFAULT_CANCER_SITE_VALUE,
      parent_tma_row: slideFile.parentTmaRow,
      parent_tma_col: slideFile.parentTmaCol,
      parent_slide_pos_x: slideFile.parentSlidePosX,
      parent_slide_pos_y: slideFile.parentSlidePosY,
      parent_wsi_uuid: slideFile.parentWsiUuid,
      wsi_uuid: slideFile.wsiUuid,
      case_uuid: slideFile.caseUuid,
    });
  }

  const result = await csvParser(csvFile, wsis, selectData);
  return result;
};
