import { overrideHEStaining } from '../../Form';
import { MULTIPLE_STAININGS_SEPARATOR } from '../const';
import {
  CSVParseResultWSIRow,
  CSVParserInputWSIRow,
  CSVWSIRow,
  ParserSelectData,
} from '../types';
import { checkValueTypeCorrectness } from './checkValueTypeCorrectness';
import { generateWarningsFromLinks } from './generateWarningsFromLinks';
import { CsvLinesWarnings, getCsvLineWarnings } from './getCsvLineWarnings';
import {
  IsMatchingCsvColumns,
  getCsvParserResults,
  isEmptyValue,
} from './getCsvParserResults';
import { isMatchingColumnCsv } from './isMatchingColumnCsv';
import { normalizeAllEntities } from './normalizeAllEntities';

type ProcessParsedRowsResult =
  | {
      parsedWsiRows: CSVParseResultWSIRow[];
      csvLineMapping: (number | null)[];
      warnings: string[];
    }
  | {
      error: string;
    };
/**
 * Processes parsed CSV rows and generates warnings
 */
export const processParsedRows = (
  parsedCsvRows: CSVWSIRow[],
  inputWsiRows: CSVParserInputWSIRow[],
  csvColumns: string[],
  selectData: ParserSelectData,
  csvIndexOffset: 2 | 3
): ProcessParsedRowsResult => {
  const normalizedDropdownData = normalizeAllEntities(selectData);
  const csvFilenames = parsedCsvRows.map((row) => row.Filename);
  const warnings: string[] = [];

  // Prepare results by removing the `type` property from input WSI rows
  const parsedWsiRows: CSVParseResultWSIRow[] = inputWsiRows.map((wsi) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...rest } = wsi; // Exclude `type` property
    return rest;
  });

  const csvLineMapping: (number | null)[] = [];

  let warningLinks: CsvLinesWarnings = {
    tissues: [],
    stainings: [],
    wrongCountOfStainings: [],
    scanners: [],
    diseases: [],
    preparationTypes: [],
    sampleTypes: [],
    morphologies: [],
    cancerSites: [],
  };

  // Process each input WSI row
  for (const [wsiIndex, wsi] of inputWsiRows.entries()) {
    const csvRowIndex = csvFilenames.indexOf(wsi.Filename);

    if (csvRowIndex === -1) {
      csvLineMapping.push(null); // No matching row found
      continue;
    }

    const csvRow = parsedCsvRows[csvRowIndex];
    const valueTypeError = checkValueTypeCorrectness(
      csvRow,
      csvRowIndex,
      csvIndexOffset
    );

    if (valueTypeError) {
      return {
        error: valueTypeError,
      }; // Will be handled by the caller
    }

    const columnMatchStatus: IsMatchingCsvColumns = isMatchingColumnCsv(
      csvRow,
      wsi,
      normalizedDropdownData,
      selectData
    );

    warningLinks = getCsvLineWarnings(
      { ...warningLinks },
      columnMatchStatus,
      csvRowIndex,
      csvIndexOffset,
      csvRow,
      wsi
    );

    // Map "HE" to "H&E" staining
    csvRow.Staining =
      overrideHEStaining(selectData.stainings, csvRow.Staining) ?? '';

    const wsiStainings = (wsi.Staining ?? '').split(
      MULTIPLE_STAININGS_SEPARATOR
    );
    csvLineMapping.push(csvRowIndex + csvIndexOffset);

    const isUuidSetForMultiChannel =
      wsi.type === 'multi-channel' && !isEmptyValue(csvRow.wsi_uuid);
    if (isUuidSetForMultiChannel) {
      warnings.push(
        `Can't set wsi_uuid for multichannel slidefile "${csvRow.Filename}"`
      );
    }

    const hasMorphologyColumn = csvColumns.includes('Morphology');
    const hasCancerSiteColumn = csvColumns.includes('Cancer Site');

    parsedWsiRows[wsiIndex] = getCsvParserResults(
      csvRow,
      normalizedDropdownData,
      columnMatchStatus,
      wsiStainings,
      hasMorphologyColumn,
      hasCancerSiteColumn,
      isUuidSetForMultiChannel
    );
  }

  // Add warnings from warningLinks
  warnings.push(...generateWarningsFromLinks(warningLinks));

  return { parsedWsiRows, csvLineMapping, warnings };
};
