/**
 * @param prefixes        a string array of prefixes
 * @param mrxsFolderPaths a string array of .mrxs folder paths
 * @returns               a filtered array of folders in the shape of OnboardingRemoteFile ( without mrxs folders )
 */

export type CloudFolderInfo = {
  type: 'remote-folder';
  filename: string;
  path: string;
};

export const generateFolders = (
  prefixes: string[],
  mrxsFolderPaths: string[]
): CloudFolderInfo[] => {
  const mappedPrefixes: CloudFolderInfo[] = prefixes.map((prefix) => ({
    filename: prefix.split('/')[prefix.split('/').length - 2],
    type: 'remote-folder',
    path: prefix,
  }));

  return mappedPrefixes.filter(
    (file) => !mrxsFolderPaths.some((v) => file.path.includes(v))
  );
};
