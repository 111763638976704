type Step = {
  title: string;
  body: string;
  next?: string;
  nextStep?: Steps;
  previous?: string;
  previousStep?: Steps;
};

export enum Steps {
  ONBOARDING = 'onboarding',
  METADATA = 'metadata',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export const steps: Record<Steps, Step> = {
  [Steps.ONBOARDING]: {
    title: 'Adding slides to Portal',
    body: `Firstly, you can transfer it from the dedicated data space that Aignostics provides for your organization by using the "Transfer from Cloud" button. Alternatively, you can upload slides directly from your computer using the "Upload Slides" button. In either case, you will need to select the specific slides you wish to add to make them accessible to your organization.
    
    File formats we support: .svs, .tif, .vms, .vmu, .ndpi, .scn, .mrxs, .tiff, svslide, .bif, .qp-tiff, .czi.`,
    next: 'Slides metadata',
    nextStep: Steps.METADATA,
  },

  [Steps.METADATA]: {
    title: 'Slides metadata',
    body: `Once you choose the slides you need, we will ask you to provide basic (meta)data. You can do that by entering information for each slide separately on the input form, or for all your slides by using CSV document upload. To upload slides via CSV upload, use our CSV template. Please do not change the template (system will report errors if new columns are added or headers renamed). Note the that there are: 

• mandatory fields (*) for each group of slides: Batch name and Species 
• mandatory fields (*) for each slide: Case Name, Scanner, Localization and Staining (including the specific information for each fluorescence channel)

If you have multiple stainings of a same slide, and you want to see them all in one-slide view, please make sure caseID, block and section information is the same for all these files.

Additionally, for each file, you can open and see whole slide label images, and use the presented data to double-check against the entered values.
`,
    previous: 'Adding slides to Portal',
    nextStep: Steps.UPLOADING,
    next: 'Uploading slides',
    previousStep: Steps.ONBOARDING,
  },

  [Steps.UPLOADING]: {
    title: 'Uploading slides',
    body: `During this phase, we will transfer your slides to our platform. You will still be able to change slides’ (meta)data. If you decide to abandon uploading slides process, your slides will not be available for use in project setup.`,
    previous: 'Slides metadata',
    next: 'Batch uploaded',
    nextStep: Steps.UPLOADED,
    previousStep: Steps.METADATA,
  },

  [Steps.UPLOADED]: {
    title: 'Batch uploaded',
    body: `This status means that transfer has been completed and now is time to process the slides. It is your last chance to correct the (meta)data. Once you are sure the (meta)data is correct, you must click on the ‘Process slide’ button in order to continue with slide onboarding.`,
    previous: 'Uploading slides',
    next: 'Processing slides',
    nextStep: Steps.PROCESSING,
    previousStep: Steps.UPLOADING,
  },

  [Steps.PROCESSING]: {
    title: 'Processing slides',
    body: `The slides will be prepared for portal use and checked for issues. This might take a while. Each file will be checked for blur detection, fluorescence channel extraction and matching, zoom levels will be defined and slides will be properly stored with all its metadata. As of this point, you will not be able to change any information about your slides.`,
    previous: 'Batch uploaded',
    next: 'Completed',
    nextStep: Steps.COMPLETED,
    previousStep: Steps.UPLOADED,
  },

  [Steps.COMPLETED]: {
    title: 'Completed',
    body: `Once you have successfully onboarded slides, you can add them to your project. Simply click on the 'Assign to project' button and follow the subsequent steps to view your slides within the Aignostics Portal. Note that slides which are not marked as successful will not be visible in your project. In the event that none of the slides were successfully onboarded, an error has occurred, and unfortunately, you will be unable to use those specific slides. In such cases, you can try onboarding them again. If the error persists, please reach out to Aignostics for assistance in resolving the issue.`,
    previous: 'Processing slides',
    previousStep: Steps.PROCESSING,
  },
};
