import { AG_GRID_LICENSE_KEY } from '@aignostics/components';
import { useApolloClient } from '@apollo/client';
import {
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  GetRowIdParams,
  LicenseManager,
  themeQuartz,
  type Theme as AGGridTheme,
} from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from 'styled-components';
import { CloudFolderInfo } from '../utils/generateFolders';
import { CloudFileInfo } from '../utils/getFileInfoFromSource';
import { $GridWrapper } from './CloudSlideList.styles';
import { getCloudSlideListColumnDef } from './helpers/getCloudSlideListColumnDef';
import { getMrxsFileSizeChecksum } from './helpers/getMrxsFileSizeChecksum';
import { CloudAsset } from './types/CloudAsset';

interface CloudSlideListProps {
  files: (CloudFileInfo | CloudFolderInfo)[];
  selectedFiles: CloudFileInfo[];
  onSelectedFilesUpdate: (updatedSelectedFiles: CloudFileInfo[]) => void;
  onPathUpdate: (path: string) => void;
}

const GRID_MODULES = [ClientSideRowModelApiModule, ClientSideRowModelModule];

export function CloudSlideList({
  files,
  selectedFiles,
  onPathUpdate,
  onSelectedFilesUpdate,
}: CloudSlideListProps): ReactElement {
  LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
  const client = useApolloClient();
  const [rowData, setRowData] = useState<CloudAsset[]>(files);
  const [error, setError] = useState<string>('');
  const colDefs = useMemo(
    () =>
      getCloudSlideListColumnDef({
        files: rowData,
        selectedFiles,
        onSelectedFilesUpdate,
        onPathUpdate,
      }),
    [rowData, selectedFiles, onSelectedFilesUpdate, onPathUpdate]
  );

  // Fetch MRXS file size and checksum
  useEffect(() => {
    const filePromises = files.map((file) => {
      if (file.type === 'remote' && file.filename.endsWith('.mrxs')) {
        return getMrxsFileSizeChecksum(client, file);
      }
      // Return the file if it's not an MRXS file
      return Promise.resolve(file);
    });

    // Making parallel requests to fetch MRXS file data
    Promise.all(filePromises)
      .then((files) => {
        setRowData(files);
      })
      .catch(() => {
        setError('Error fetching MRXS file data');
      });
  }, [files, client]);

  // getRowId makes row rerender optimistically every time. Works like key in an list items
  const getRowId = useCallback(
    (params: GetRowIdParams<CloudAsset>) => String(params.data.filename),
    []
  );

  const theme = useTheme();
  const gridTheme = useMemo<AGGridTheme>(
    () => themeQuartz.withParams(theme.gridTheme),
    [theme]
  );

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <$GridWrapper>
      <AgGridReact<CloudAsset>
        rowData={rowData}
        columnDefs={colDefs}
        suppressCsvExport
        suppressColumnVirtualisation
        pagination={false}
        modules={GRID_MODULES}
        getRowId={getRowId}
        theme={gridTheme}
      />
    </$GridWrapper>
  );
}
