import styled from 'styled-components';

export const $GridWrapperBase = styled.div`
  height: calc(100vh - ${72 + 32 + 48 + 32 + 44}px);

  .ag-row-header {
    .ag-cell {
      background-color: ${({ theme }) => theme.gridTheme.headerBackgroundColor};
      font-weight: bold;
      &:not(.ag-cell-focus) {
        border-right: none;
      }
    }
  }

  .ag-cell.channel-disabled-cell {
    background-color: ${({ theme }) => theme.colors.lighter};
    &.ag-cell-focus {
      border: none;
    }
  }
`;

export const $MetadataGridWrapper = styled($GridWrapperBase)`
  .ag-header-cell.ag-column-first {
    padding-left: ${
      ({ theme }) => theme.spacings[56] - 1 /* -1px to compensate for border */
    }px;
  }

  .slide-name[role='gridcell'] {
    padding-left: ${
      ({ theme }) =>
        theme.spacings[56] / 2 - 1 /* -1 px to compensate for border */
    }px;
  }
`;
