/**
 * Generates a csv file with relevant metadata locally and triggers download.
 */

import { createBlob, createDownload } from './fileSaver';

export const downloadDataOnboardingTemplate = (): void => {
  const header: string[] = [
    'Filename',
    'Staining',
    'Scanner',
    'Localization',
    'Patient ID',
    'Case Name',
    'Block',
    'Section',
    'Disease',
    'Preparation Type',
    'Sample Type',
    'Morphology',
    'Cancer Site',
  ];

  const DEFAULT_DATA_ONBOARDING_TEMPLATE_CONTENT = [
    [
      '01.tif',
      'ae1',
      'ventana',
      'colon',
      'patient1',
      '00000000-0000-0000-0000-000000000001',
      'block1',
      'section1',
      'NSCLC',
      'FFPE',
      'Resection',
      'Other',
      'Other',
    ],
    [
      '02.tif',
      'ae1',
      'ventana',
      'colon',
      'patient2',
      '00000000-0000-0000-0000-000000000002',
      'block2',
      'section2',
      'NSCLC',
      'FFPE',
      'Resection',
      'Other',
      'Other',
    ],
    [
      '03.tif',
      'ae1',
      'ventana',
      'colon',
      'patient3',
      '00000000-0000-0000-0000-000000000003',
      'block3',
      'section3',
      'NSCLC',
      'FFPE',
      'Resection',
      'Other',
      'Other',
    ],
    [
      '04.tif',
      'ae1|ae2|dapi',
      'ventana',
      'colon',
      'patient4',
      '00000000-0000-0000-0000-000000000004',
      'block4',
      'section4',
      'NSCLC',
      'FFPE',
      'Resection',
      'Other',
      'Other',
    ],
  ];

  downloadCSV(
    [header, ...DEFAULT_DATA_ONBOARDING_TEMPLATE_CONTENT],
    'data-onboarding-template-example'
  );
};

type CsvField = string | number | null;
export type CsvRow = CsvField[];

/**
 * Generates a csv file and triggers download.
 * @param rows Content rows for csv file
 * @param name Filename of csv file
 */
const downloadCSV = (rows: CsvRow[], name = 'Unknown'): void => {
  // convert data to csv string
  const csvString = rows.map((e) => e.join(',')).join('\n');
  // convert string to blob
  const csvBlob = createBlob(csvString, 'text/csv');
  // trigger download
  createDownload(csvBlob, name);
};
