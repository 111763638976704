import { isEmpty as _isEmpty } from 'lodash';
import type { Staining } from '../../../types';
import type {
  AssignedParent,
  FormErrors,
  MultiChannelFormRow,
  MultiChannelFormRowErrors,
  PatientCaseIdPair,
  SingleChannelFormRow,
  SingleChannelFormRowErrors,
  TissueBlockCaseIdTriple,
} from './form.state.types';
import { getFirstMatchingPairOrWsisGroup } from './getFirstMatchingPairOrWsisGroup';
import { getFirstMatchingTripleOrWsisGroup } from './getFirstMatchingTripleOrWsisGroup';
import { getUniquePatientCaseIdPairs } from './getUniquePatientCaseIdPairs';
import { getUniqueTissueBlockCaseIdTriples } from './getUniqueTissueBlockCaseIdTriples';
import type {
  MatchingWsisByBlockTissue,
  MatchingWsisGroup,
} from './useMatchingWsis';

export const EMPTY_FIELD_ERROR = "Shouldn't be empty";
const E_MISMATCHING_PATIENT_CASE_ID_PAIRS_INTERNAL =
  'There seems to be more than one Case Name with different Patient ID (or the patient information is missing). Please check your input once again and make necessary changes or remove the slide.';
const E_MISMATCHING_BLOCK_CASE_ID_AND_TISSUE_INTERNAL =
  'There seems to be more than one Block & Case Name with different Localization. Please check your input once again and make necessary changes or remove the slide.';
const INVALID_STAINING_ERROR = 'Invalid staining';

/**
 * generates an array of mismatching patient id - case id pairs internally in an onboarding batch
 * @param patientCaseIdPairs an array of unique patient id - case id pairs, generated from slides in the batch
 * @returns                  an array of mismatching patient id - case id pairs
 */
const getPairsWithRepeatedCaseId = (patientCaseIdPairs: PatientCaseIdPair[]) =>
  patientCaseIdPairs.reduce((acc: PatientCaseIdPair[], pair, index) => {
    if (
      patientCaseIdPairs.find(
        ({ caseId }, currentIndex) =>
          pair.caseId === caseId && index !== currentIndex
      )
    ) {
      acc.push(pair);
    }

    return acc;
  }, []);
const getLocalPatientIdErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>
): Array<{ patientExternalId: string } | null> => {
  const uniquePairs = getUniquePatientCaseIdPairs(localSlides);
  const uniquePairsWithRepeatedCaseId = getPairsWithRepeatedCaseId(uniquePairs);

  return localSlides.map((slide) =>
    !getFirstMatchingPairOrWsisGroup(slide, uniquePairsWithRepeatedCaseId)
      ? null
      : { patientExternalId: E_MISMATCHING_PATIENT_CASE_ID_PAIRS_INTERNAL }
  );
};
/**
 * generates an array of mismatching tissue - block - case id triples internally in an onboarding batch
 * @param tissueBlockCaseIdTuples an array of unique tissue - block - case id triples, generated from slides in the batch
 * @returns                       an array of mismatching tissue - block - case id triples
 */
const getTriplesWithRepeatedTissues = (
  tissueBlockCaseIdTuples: TissueBlockCaseIdTriple[]
) =>
  tissueBlockCaseIdTuples.reduce(
    (acc: TissueBlockCaseIdTriple[], tuple, index) => {
      if (
        tissueBlockCaseIdTuples.find(
          ({ block, caseId }, currentIndex) =>
            tuple.block === block &&
            tuple.caseId === caseId &&
            index !== currentIndex
        )
      ) {
        acc.push(tuple);
      }

      return acc;
    },
    []
  );
const getLocalBlockTissueMismatchErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisByBlockTissueInDb: MatchingWsisByBlockTissue[]
): Array<{ tissue: string } | null> => {
  const uniqueTuples = getUniqueTissueBlockCaseIdTriples(localSlides);
  const uniquePairsWithRepeatedCaseId =
    getTriplesWithRepeatedTissues(uniqueTuples);

  return localSlides.map((slide) => {
    const { block, caseName } =
      slide.type === 'single-channel' ? slide : slide.parentAssigned;

    // if slide matching returned correct error for any given caseId-block pair,
    // ignore local matching for those slides
    if (
      matchingWsisByBlockTissueInDb.some(
        (dbMatched) =>
          dbMatched.message &&
          dbMatched.caseId === caseName &&
          dbMatched.block === block
      )
    ) {
      return null;
    }

    return !getFirstMatchingTripleOrWsisGroup(
      slide,
      uniquePairsWithRepeatedCaseId
    )
      ? null
      : { tissue: E_MISMATCHING_BLOCK_CASE_ID_AND_TISSUE_INTERNAL };
  });
};
/**
 * extracting patientExternalId errors from matching WSI group
 * @param localSlides      an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @param matchingWsisInDb an array of unique PatientCaseIdPairs
 * @returns                a mapped array of PatientCaseIdPairs from slides
 */
const getPatientIdErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisInDb: MatchingWsisGroup[]
): Array<{ patientExternalId: string } | null> =>
  localSlides.map((slide) => {
    const matchingGroup = getFirstMatchingPairOrWsisGroup(
      slide,
      matchingWsisInDb
    );

    return matchingGroup ? { patientExternalId: matchingGroup.message } : null;
  });
/**
 * extracting tissue errors from matching tissue block caseid validation triple
 * @param localSlides      an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @param matchingWsisInDb an array of unique TissueBlockCaseIdTriple
 * @returns                a mapped array of TissueBlockCaseIdTriple from slides
 */
const getBlockTissueMismatchErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisByBlockTissueInDb: MatchingWsisByBlockTissue[]
): Array<{ tissue: string } | null> =>
  localSlides.map((slide) => {
    const matchingTissue = getFirstMatchingTripleOrWsisGroup(
      slide,
      matchingWsisByBlockTissueInDb
    );

    return matchingTissue?.message ? { tissue: matchingTissue.message } : null;
  });

export const getSlidesErrors = (
  localSlides: Array<SingleChannelFormRow | MultiChannelFormRow>,
  matchingWsisGroup: MatchingWsisGroup[],
  matchingWsisByBlockTissue: MatchingWsisByBlockTissue[],
  stainings: Staining[]
): FormErrors => {
  const localPatientIdErrors = getLocalPatientIdErrors(localSlides);
  const localBlockTissueMismatchErrors = getLocalBlockTissueMismatchErrors(
    localSlides,
    matchingWsisByBlockTissue
  );

  const remotePatientIdErrors = getPatientIdErrors(
    localSlides,
    matchingWsisGroup
  );
  const remoteBlockTissueMismatchErrors = getBlockTissueMismatchErrors(
    localSlides,
    matchingWsisByBlockTissue
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const slidesErrors = localSlides.map((currentSlide, index) => {
    let row: SingleChannelFormRow | AssignedParent;
    let slideFilename: string;
    if (currentSlide.type === 'single-channel') {
      row = currentSlide;
      slideFilename = row.slideFile.filename;
    } else {
      row = currentSlide.parentAssigned;
      slideFilename = currentSlide.slideFile.filename;
    }

    const patientIdError =
      localPatientIdErrors[index]?.patientExternalId ||
      remotePatientIdErrors[index]?.patientExternalId;
    let remoteTissueError: string | undefined;
    if (remoteBlockTissueMismatchErrors[index]?.tissue) {
      remoteTissueError = `${slideFilename}: ${remoteBlockTissueMismatchErrors[index]?.tissue}`;
    }
    const tissueError =
      localBlockTissueMismatchErrors[index]?.tissue || remoteTissueError;
    const slideErrors: SingleChannelFormRowErrors = {
      ...(!row.caseName && {
        caseName: EMPTY_FIELD_ERROR,
      }),
      ...(Boolean(patientIdError) && {
        patientExternalId: patientIdError,
      }),
      ...(row.scannerId === null && {
        scannerId: EMPTY_FIELD_ERROR,
      }),
      ...(row.tissue === null && {
        tissue: EMPTY_FIELD_ERROR,
      }),
      ...(tissueError && {
        tissue: tissueError,
      }),
      ...(row.type === 'single-channel' &&
        row.staining === null && {
          staining: EMPTY_FIELD_ERROR,
        }),
      ...(['', undefined].includes(row.block) &&
        !['', undefined].includes(row.section) && {
          block:
            'Block information can not be empty with defined section information',
        }),
      ...(row.section &&
        row.section.length > 255 && {
          section: 'The "section" field should have less than 255 characters.',
        }),
    };

    if (currentSlide.type === 'multi-channel') {
      const stainingsNames = stainings.map((staining) => staining.name);
      let isChannelsErrors = false;
      const channelErrors = currentSlide.channels.map((channel) => {
        if (channel.staining && stainingsNames.includes(channel.staining)) {
          return undefined;
        }
        isChannelsErrors = true;
        return { staining: INVALID_STAINING_ERROR };
      });
      const multiSlideErrors: MultiChannelFormRowErrors = {
        ...(isChannelsErrors
          ? {
              channels: channelErrors,
            }
          : {}),
        ...(!_isEmpty(slideErrors) && {
          parentAssigned: {
            ...slideErrors,
          },
        }),
      };

      return !_isEmpty(multiSlideErrors) ? multiSlideErrors : null;
    }

    return !_isEmpty(slideErrors) ? slideErrors : null;
  });

  return slidesErrors.find((slide) => slide !== null) ? slidesErrors : null;
};
