export const checksumBtoa = (checksum: number): string => {
  return btoa(
    // prettier-ignore
    [
      (checksum >> 24) & 0xff,
      (checksum >> 16) & 0xff,
      (checksum >> 8) & 0xff,
      checksum & 0xff,
    ]
      .map((charCode) => String.fromCharCode(charCode))
      .join('')
  );
};
