import React, { useLayoutEffect, useState, } from 'react';
import { createPortal } from 'react-dom';
import Button from '../Button';
import { useDisclosure } from '../hooks';
import Icon from '../Icon/Icon.component';
import { Toggle } from '../Toggle';
import { Tooltip } from '../Tooltip';
import { $DropdownContainer, $DropdownMenu, $HorizontalDivider, $MenuItem, } from './Dropdown.styles';
const DropdownMenu = ({ menuLabel, menuItems, menuPosition = 'dropdown', withTooltip, }) => {
    const disclosure = useDisclosure(false);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const triggerRef = React.useRef(null);
    const menuRef = React.useRef(null);
    const open = () => {
        disclosure.open();
    };
    const close = () => {
        disclosure.close();
        triggerRef.current?.focus();
    };
    useLayoutEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                disclosure.close();
                document.removeEventListener('click', handleClickOutside, true);
            }
        };
        if (disclosure.isOpen) {
            menuRef.current?.focus();
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [disclosure]);
    useLayoutEffect(() => {
        const menuElement = menuRef.current;
        const triggerElement = triggerRef.current;
        if (!disclosure.isOpen || menuElement === null || triggerElement === null) {
            return;
        }
        const reposition = () => {
            const triggerBBox = triggerElement.getBoundingClientRect();
            const menuBBox = menuElement.getBoundingClientRect();
            menuElement.style.top =
                menuPosition === 'dropdown'
                    ? `${triggerBBox.bottom}px`
                    : `${triggerBBox.top - (menuBBox.height + 16)}px`;
            menuElement.style.left = `${triggerBBox.left}px`;
            menuElement.style.width = 'auto';
            menuElement.style.maxHeight = `${Math.max(triggerBBox.height, 200)}px`;
        };
        reposition();
        document.addEventListener('scroll', reposition, { capture: true });
        return () => {
            document.removeEventListener('scroll', reposition);
        };
    }, [disclosure, menuPosition]);
    const handleButtonKeydown = (event) => {
        switch (event.key) {
            case ' ':
            case 'Enter':
            case 'Down':
            case 'ArrowDown': {
                setActiveItemIndex(0);
                open();
                break;
            }
            case 'Up':
            case 'ArrowUp': {
                setActiveItemIndex(menuItems.length - 1);
                open();
                break;
            }
            case 'Esc':
            case 'Escape': {
                close();
                break;
            }
            default:
                return;
        }
        event.stopPropagation();
        event.preventDefault();
    };
    const handleMenuKeydown = (event) => {
        if (event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }
        if (event.key === 'Tab') {
            close();
            if (event.shiftKey) {
                // Avoid default behavior, this prevents focus from going to the last
                // focusable element in the page. Focus is given to the menu trigger
                // (button) by close()
                event.stopPropagation();
                event.preventDefault();
            }
            return;
        }
        switch (event.key) {
            case 'Down':
            case 'ArrowDown':
            case 'Right':
            case 'ArrowRight': {
                setActiveItemIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
                break;
            }
            case 'Up':
            case 'ArrowUp':
            case 'Left':
            case 'ArrowLeft': {
                setActiveItemIndex((prevIndex) => prevIndex !== 0 ? prevIndex - 1 : menuItems.length - 1);
                break;
            }
            case 'Home':
            case 'PageUp': {
                setActiveItemIndex(0);
                break;
            }
            case 'End':
            case 'PageDown': {
                setActiveItemIndex(menuItems.length - 1);
                break;
            }
            case 'Esc':
            case 'Escape': {
                close();
                break;
            }
            case ' ':
            case 'Enter': {
                close();
                menuItems[activeItemIndex].props.onClick();
                break;
            }
            default:
                return;
        }
        event.stopPropagation();
        event.preventDefault();
    };
    const getMenuItemAttributes = (props) => {
        if (props.variant === 'checkbox') {
            return {
                'aria-checked': props.isChecked,
                role: 'menuitemcheckbox',
                variant: 'checkbox',
            };
        }
        return { role: 'menuitem', variant: 'button' };
    };
    return (React.createElement($DropdownContainer, null,
        React.createElement(Button, { "aria-label": menuLabel.title, ...menuLabel.props, ...disclosure.controlProps, onClick: () => {
                if (disclosure.isOpen) {
                    close();
                }
                else {
                    open();
                }
            }, ref: triggerRef, variant: "ghost", role: "button", small: true, "aria-haspopup": "menu", "aria-activedescendant": `menuitem-${menuItems[activeItemIndex].id}`, onKeyDown: handleButtonKeydown },
            React.createElement("div", null, menuLabel.title),
            React.createElement($HorizontalDivider, null),
            React.createElement(Icon, { icon: menuPosition === 'dropdown' ? 'ChevronDown' : 'ChevronUp' })),
        createPortal(React.createElement($DropdownMenu, { role: "menu", ref: menuRef, tabIndex: -1, onKeyDown: handleMenuKeydown, style: {
                display: disclosure.isOpen ? undefined : 'none',
                overflowY: 'scroll',
            } }, menuItems.map((menuItem, index) => {
            const attrs = getMenuItemAttributes(menuItem);
            return (React.createElement("li", { key: menuItem.id }, React.createElement(Tooltip, { content: menuItem.props.disabled
                    ? withTooltip?.disabled
                    : withTooltip?.enabled },
                React.createElement($MenuItem, { "aria-label": menuItem.title, ...menuItem.props, ...attrs, variant: menuItem.variant, isActive: index === activeItemIndex, onMouseOver: () => {
                        setActiveItemIndex(index);
                    }, tabIndex: -1, id: `menuitem-${menuItem.id}`, onClick: () => {
                        menuItem.props.onClick?.();
                        close();
                    } },
                    menuItem.variant === 'checkbox' && (React.createElement(Toggle, { componentTheme: "transparent", active: menuItem.isChecked, onChange: () => {
                            menuItem.props.onClick?.();
                        } })),
                    menuItem.title))));
        })), document.body)));
};
export default DropdownMenu;
