import type { MultiChannelFormRow, SingleChannelFormRow } from '../Form';
import type {
  ChannelSlideFileChannelRow,
  GridData,
} from './MetadataGrid.types';

type TreeData = Array<
  (SingleChannelFormRow | MultiChannelFormRow | ChannelSlideFileChannelRow) & {
    rowId: string;
    path: string[];
  }
>;

export function buildGridTreeData(
  data: (SingleChannelFormRow | MultiChannelFormRow)[]
): TreeData {
  const result: TreeData = [];

  data.forEach((dataItem) => {
    const parentPath = [dataItem.slideFile.filename];

    if (dataItem.type === 'multi-channel') {
      const { parentAssigned, slideFile, channels } = dataItem;
      result.push({
        ...dataItem,
        rowId: slideFile.filename,
        path: parentPath,
      });
      if (channels && channels.length > 0) {
        channels.forEach((channel, index) => {
          // row id must be unique for every grid row, so here we are using
          // channel index. Channel index also is used by the onboarding
          // pipeline, to match database metadata records with channels from the
          // slide files.
          const rowId = `${parentAssigned.name}:${index}`; // filename:channelIndex
          result.push({
            id: channel.id,
            rowId,
            type: 'channel',
            // path consists of parent's rowId and channel's rowId
            // please refer to AG Grid's Tree Data → Supplying Data doc page
            path: [...parentPath, rowId],
            staining: channel.staining,
            originalStaining: channel.originalStaining,
            name: channel.name,
            isHeaderRow: false,
            sampleType: null,
            scannerId: null,
            tissue: null,
            disease: null,
            samplePreparation: null,
            morphology: null,
            cancerSite: null,
            caseName: null,
            patientExternalId: null,
            block: null,
            section: null,
            parentTmaRow: null,
            parentTmaCol: null,
            parentSlidePosX: null,
            parentSlidePosY: null,
            parentWsiUuid: null,
            caseUuid: null,
            wsiUuid: null,
          });
        });
      }
    }

    if (dataItem.type === 'single-channel') {
      result.push({
        ...dataItem,
        rowId: dataItem.slideFile.filename,
        path: parentPath,
      });
    }
  });

  return result;
}

export function flattenGridTreeData(treeData: TreeData): GridData[] {
  const flattened: GridData[] = [];

  treeData.forEach((item) => {
    switch (item.type) {
      case 'single-channel': {
        const singleChannelItem = item as SingleChannelFormRow & {
          rowId: string;
          path: string[];
        };
        flattened.push({
          ...singleChannelItem,
          type: 'single-channel',
          rowId: singleChannelItem.rowId,
          path: singleChannelItem.path,
          caseName: singleChannelItem.caseName ?? null,
          block: singleChannelItem.block ?? null,
          patientExternalId: singleChannelItem.patientExternalId ?? null,
        });
        break;
      }
      case 'multi-channel': {
        const multiChannelItem = item as MultiChannelFormRow & {
          rowId: string;
          path: string[];
        };
        flattened.push({
          ...multiChannelItem.parentAssigned,
          type: 'multi-channel',
          rowId: multiChannelItem.rowId,
          path: multiChannelItem.path,
          slideFile: multiChannelItem.slideFile,
          staining: null,
          caseName: multiChannelItem.parentAssigned.caseName ?? null,
          block: multiChannelItem.parentAssigned.block ?? null,
          patientExternalId:
            multiChannelItem.parentAssigned.patientExternalId ?? null,
        });
        break;
      }
      case 'channel': {
        flattened.push({
          type: 'channel',
          id: item.id,
          name: item.name,
          originalStaining: item.originalStaining,
          path: item.path,
          rowId: item.rowId,
          staining: item.staining,
          sampleType: null,
          isHeaderRow: false,
          scannerId: null,
          tissue: null,
          disease: null,
          samplePreparation: null,
          morphology: null,
          cancerSite: null,
          caseName: null,
          patientExternalId: null,
          block: null,
          section: null,
          parentTmaRow: null,
          parentTmaCol: null,
          parentSlidePosX: null,
          parentSlidePosY: null,
          parentWsiUuid: null,
          caseUuid: null,
          wsiUuid: null,
        });
        break;
      }
      default:
        break;
    }
  });

  return flattened;
}

export function convertSlidesToGridData(
  slides: (SingleChannelFormRow | MultiChannelFormRow)[]
): GridData[] {
  return flattenGridTreeData(buildGridTreeData(slides));
}

export function convertGridDataToSlides(
  gridData: GridData[] | null
): (SingleChannelFormRow | MultiChannelFormRow)[] {
  const multiChannelMap = new Map<string, MultiChannelFormRow>();

  const result: (SingleChannelFormRow | MultiChannelFormRow)[] = [];
  if (!gridData) {
    return result;
  }
  gridData.forEach((item) => {
    switch (item.type) {
      case 'single-channel': {
        if (
          item.slideFile === null ||
          item.caseName === null ||
          item.patientExternalId === null ||
          item.block === null ||
          item.section === null ||
          item.section === undefined ||
          item.slideFile === null ||
          item.slideFile === undefined
        ) {
          return;
        }
        const singleChannelRow: SingleChannelFormRow = {
          ...item,
          type: 'single-channel',
          slideFile: item.slideFile,
          caseName: item.caseName,
          block: item.block,
          section: item.section,
          patientExternalId: item.patientExternalId,
        };
        result.push(singleChannelRow);
        break;
      }
      case 'multi-channel': {
        if (
          item.slideFile === null ||
          item.slideFile === undefined ||
          item.caseName === null ||
          item.patientExternalId === null ||
          item.block === null ||
          item.section === null ||
          item.section === undefined
        ) {
          return;
        }
        const multiChannelRow: MultiChannelFormRow = {
          type: 'multi-channel',
          slideFile: item.slideFile,
          parentAssigned: {
            ...item,
            type: 'temporary-parent',
            caseName: item.caseName,
            block: item.block,
            section: item.section,
            patientExternalId: item.patientExternalId,
            scannerId: item.scannerId,
            tissue: item.tissue,
            disease: item.disease,
            samplePreparation: item.samplePreparation,
            sampleType: item.sampleType,
            morphology: item.morphology,
            cancerSite: item.cancerSite,
            csvLineMapping: item.csvLineMapping,
            id: item.id,
            parentTmaCol: item.parentTmaCol,
            parentTmaRow: item.parentTmaRow,
            parentSlidePosX: item.parentSlidePosX,
            parentSlidePosY: item.parentSlidePosY,
            parentWsiUuid: item.parentWsiUuid,
            caseUuid: item.caseUuid,
            wsiUuid: item.wsiUuid,
            originalWsiUuid: item.originalWsiUuid,
            name: item.slideFile.filename,
          },
          channels: [],
        };
        multiChannelMap.set(item.rowId, multiChannelRow);
        result.push(multiChannelRow);
        break;
      }

      case 'channel': {
        const parentMulti = multiChannelMap.get(item.path[0]);
        if (!parentMulti) {
          return;
        }
        parentMulti.channels.push({
          id: item.id,
          name: item.name ?? '',
          staining: item.staining ?? null,
          originalStaining: item.originalStaining ?? null,
        });
        break;
      }

      default:
        break;
    }
  });

  return result;
}
