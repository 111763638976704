import { gql } from '@apollo/client';

export const FETCH_ADMIN_PROJECTS_LIST = gql`
  query GET_ADMIN_PROJECTS_LIST(
    $page: Int!
    $pageSize: Int!
    $search: String
    $createdBy: [String!]
    $sortBy: sortByProjectValues
    $sortDirection: sortDirection
    $isAdminView: Boolean!
  ) {
    projects(
      page: $page
      pageSize: $pageSize
      search: $search
      createdBy: $createdBy
      sortBy: $sortBy
      sortDirection: $sortDirection
      isAdminView: $isAdminView
    ) {
      nodes {
        id
        name
        subProjectsCount
        usersCount
        isVisible
        createdBy {
          id
          name
        }
        createdAt
        updatedAt
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
    }
  }
`;
