import { formatDate, pluralize } from '@aignostics/utils';
import { OnboardingWsiOnboardProgress } from '../../../graphql/api.types';
import type {
  BatchReadOnlyForm,
  MultiChannelFormRowFilledReadOnly,
  MultiChannelSlideFileChannelFilledReadOnly,
  SingleChannelFormRowFilledReadOnly,
} from '../../AgGridSetFileMetadataStep/Form';
import { convertSlidesToGridData } from '../../AgGridSetFileMetadataStep/MetadataGrid/MetadataGrid.dataFormatter';
import type { GridData } from '../../AgGridSetFileMetadataStep/MetadataGrid/MetadataGrid.types';
import type { GridDataBatches } from './BatchesGrid.types';

type MapFilenameToSingleChannel = Map<
  string,
  SingleChannelFormRowFilledReadOnly
>;
type MapFilenameToMultiChannel = Map<string, MultiChannelFormRowFilledReadOnly>;
type MapChannelIDToChannel = Map<
  string,
  MultiChannelSlideFileChannelFilledReadOnly
>;

/**
 * Converts normalized onboarding batches data into grid data required for the
 * onboarding batch view table to work with.
 */
export const convertOnboardingBatchesToRowData = (
  onboardingBatches: BatchReadOnlyForm[]
): GridDataBatches[] =>
  onboardingBatches
    .map((onboardingBatch) => {
      const { singleChannelSlideMap, multiChannelSlideMap, channelsMap } =
        generateMaps(onboardingBatch);

      return [
        // add batch header entry with processing date, and actions
        generateBatchEntryDatum(onboardingBatch),
        // convert to grid data as it used in metadata entry
        ...convertSlidesToGridData(
          onboardingBatch.slides.map((slide) => ({
            ...slide,
            rowId: slide.slideFile.filename,
          }))
        ).map((gridDatum) =>
          // map over collection to add references to onboarding progress,
          // batch status, proper tree data references with nested batches
          convertGridDataToGridDataBatches(
            onboardingBatch,
            singleChannelSlideMap,
            multiChannelSlideMap,
            channelsMap,
            gridDatum
          )
        ),
      ];
    })
    .flat();

/** Generate hashmaps for lookups later in the {@link convertGridDataToGridDataBatches}. */
export const generateMaps = (
  onboardingBatch: BatchReadOnlyForm
): {
  singleChannelSlideMap: MapFilenameToSingleChannel;
  multiChannelSlideMap: MapFilenameToMultiChannel;
  channelsMap: MapChannelIDToChannel;
} => {
  const singleChannelSlideMap: MapFilenameToSingleChannel = new Map();
  const multiChannelSlideMap: MapFilenameToMultiChannel = new Map();
  const channelsMap: MapChannelIDToChannel = new Map();

  for (const slide of onboardingBatch.slides) {
    const { filename } = slide.slideFile;
    switch (slide.type) {
      case 'single-channel':
        singleChannelSlideMap.set(filename, slide);
        break;
      case 'multi-channel':
        multiChannelSlideMap.set(filename, slide);
        for (const channel of slide.channels) {
          channelsMap.set(channel.id!, channel);
        }
        break;
    }
  }

  return { singleChannelSlideMap, multiChannelSlideMap, channelsMap };
};

/**
 * Generates entry record for table row with batch entry (contains number of
 * slides, upload date and action buttons).
 */
export const generateBatchEntryDatum = ({
  batch_id,
  status,
  slides: { length: slideFilesCount },
  totalSlideFiles: totalSlideFilesCount,
  createdAt,
}: BatchReadOnlyForm): GridDataBatches => {
  const truncatedResultSuffix =
    slideFilesCount !== totalSlideFilesCount
      ? ` (out of ${totalSlideFilesCount} total)`
      : '';
  const batchName =
    `${slideFilesCount}${truncatedResultSuffix} ` +
    `${pluralize('entry', totalSlideFilesCount, 'entries')}` +
    `, uploaded on ${formatDate(createdAt)}`;

  return {
    batchId: batch_id,
    batchName,
    batchStatus: status,
    type: 'batch-entry' as const,
    rowId: batch_id,
    path: [batch_id],
    parentTmaRow: null,
    parentTmaCol: null,
    parentSlidePosX: null,
    parentSlidePosY: null,
    parentWsiUuid: null,
    caseUuid: null,
    wsiUuid: null,
    isHeaderRow: false,
    sampleType: null,
    scannerId: null,
    tissue: null,
    disease: null,
    samplePreparation: null,
    morphology: null,
    cancerSite: null,
    caseName: null,
    patientExternalId: null,
    block: null,
    section: null,
    name: null,
    staining: null,
    originalStaining: null,
  };
};

const convertGridDataToGridDataBatches = (
  onboardingBatch: BatchReadOnlyForm,
  singleChannelSlideMap: MapFilenameToSingleChannel,
  multiChannelSlideMap: MapFilenameToMultiChannel,
  channelsMap: MapChannelIDToChannel,
  gridDatum: GridData
): GridDataBatches => {
  // for single-channel & multi-channel entries we need to add reference to
  // the underlying slide record
  let matchingSlide:
    | SingleChannelFormRowFilledReadOnly
    | MultiChannelFormRowFilledReadOnly
    | undefined;
  // for single-channel & channel entries we need to add reference to the
  // underlying onboarding progress object
  let onboardingProgress: OnboardingWsiOnboardProgress[] | undefined;

  switch (gridDatum.type) {
    case 'single-channel':
      matchingSlide = singleChannelSlideMap.get(gridDatum.slideFile.filename)!;
      onboardingProgress = matchingSlide.onboardProgress;
      break;

    case 'multi-channel':
      matchingSlide = multiChannelSlideMap.get(gridDatum.slideFile.filename);
      break;

    case 'channel':
      onboardingProgress = channelsMap.get(gridDatum.id!)!.onboardProgress;
      break;
  }

  return {
    ...gridDatum,
    batchId: onboardingBatch.batch_id,
    rowId: `${onboardingBatch.batch_id}:${gridDatum.rowId}`,
    path: [
      onboardingBatch.batch_id,
      ...gridDatum.path.map(
        (pathDatum) => `${onboardingBatch.batch_id}:${pathDatum}`
      ),
    ],
    batchStatus: onboardingBatch.status,
    batchName: onboardingBatch.batch_name,
    onboardingProgress,
    ...(matchingSlide ? { slide: matchingSlide } : null),
  };
};
