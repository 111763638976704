import { getAgGridPaginationConfig } from '@aignostics/components';
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import { FilterModel, IServerSideDatasource } from 'ag-grid-enterprise';
import { useMemo } from 'react';
import {
  FETCH_ONBOARDING_BATCHES,
  OnboardingBatchItem,
} from '../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
// create a server side datasource for the onboarding batches for AG-Grid
const createServerSideDatasource = (
  client: ApolloClient<object>,
  queryDocument: DocumentNode
): IServerSideDatasource<OnboardingBatchItem> => {
  return {
    getRows: async (params) => {
      try {
        const {
          startRow: maybeStartRow,
          endRow: maybeEndRow,
          filterModel,
        } = params.request;

        const { page, pageSize } = getAgGridPaginationConfig(
          maybeStartRow,
          maybeEndRow
        );

        const { createdAt, associationName, batchName, status, createdBy } =
          filterModel as FilterModel;

        const uploadingDate = createdAt
          ? `${createdAt?.dateFrom.split(' ')[0]} - ${createdAt?.dateTo.split(' ')[0]}`
          : '';

        const variables = {
          page,
          pageSize,

          createdBy: createdBy?.values,
          status: status?.values,
          uploadingDate,
          association: associationName?.values,
          search: batchName?.filter,
        };

        const { data, error } = await client.query({
          query: queryDocument,
          variables,
          fetchPolicy: 'network-only',
        });

        if (error) {
          throw error;
        }

        const rowData = data?.onboardingBatches?.nodes ?? [];
        const totalElements =
          data?.onboardingBatches?.pageInfo.totalElements ?? rowData.length;

        params.success({ rowData, rowCount: totalElements });
      } catch {
        params.fail();
      }
    },
  };
};

// Export a hook to get the server side datasource for the onboarding batches
export function useServerSideBatchDatasource(): IServerSideDatasource<OnboardingBatchItem> {
  const client = useApolloClient();
  const datasource = useMemo(
    () => createServerSideDatasource(client, FETCH_ONBOARDING_BATCHES),
    [client]
  );

  return datasource;
}
