import { pluralize } from '@aignostics/utils';
import { CsvLinesWarnings } from './getCsvLineWarnings';

/**
 * Generate warning messages from warning links
 */

export const generateWarningsFromLinks = (
  warningLinks: CsvLinesWarnings
): string[] => {
  const warnings: string[] = [];

  // Generate warnings for specific CSV lines
  type CsvLineWarning = [string, number[]];
  const csvLineWarnings: CsvLineWarning[] = [
    ['Localizations', warningLinks.tissues],
    ['Stainings', warningLinks.stainings],
    ['Scanners', warningLinks.scanners],
    ['Diseases', warningLinks.diseases],
    ['Preparation Types', warningLinks.preparationTypes],
    ['Sample Types', warningLinks.sampleTypes],
    ['Morphology', warningLinks.morphologies],
    ['Cancer Site', warningLinks.cancerSites],
  ];

  csvLineWarnings.forEach(([entity, warningLines]) => {
    if (warningLines.length > 0) {
      warnings.push(
        `Bad ${pluralize('value', warningLines.length)} for ${entity} on CSV ${pluralize(
          'line',
          warningLines.length
        )} ${warningLines.join(', ')}`
      );
    }
  });

  if (warningLinks.wrongCountOfStainings.length > 0) {
    warnings.push(
      `Mismatching count of channels for slide file on CSV ${pluralize(
        'line',
        warningLinks.wrongCountOfStainings.length
      )} ${warningLinks.wrongCountOfStainings.join(', ')}`
    );
  }

  return warnings;
};
