import { OnboardingBatchStatus } from '@aignostics/components';
import { User } from '@aignostics/core';
import { ValueFormatterParams } from 'ag-grid-enterprise';
import { format } from 'date-fns';
import { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { Association } from '../../../../types';
import { mapStatusToMessage } from '../onboardingBatches';
import { getAssociationLabel, getCreatedByLabel } from './helpers';

export const associationValueFormatter = (
  params: ValueFormatterParams<
    OnboardingBatchItem,
    OnboardingBatchItem['associationName']
  >
): string => {
  const associationName = params.value;
  return associationName ? getAssociationLabel({ name: associationName }) : '';
};

export const generateAssociationFilterFormatter = (
  associations: Association[]
) => {
  const associationsHash: Record<Association['id'], Association> =
    associations.reduce(
      (acc, association) => ({
        ...acc,
        [association.name]: association,
      }),
      {}
    );

  return (
    params: ValueFormatterParams<OnboardingBatchItem, Association['id']>
  ): string => {
    const associationId = params.value;
    const association = associationId ? associationsHash[associationId] : null;
    return getAssociationLabel(association);
  };
};

export const uploadingDateFormatter = (
  params: ValueFormatterParams<
    OnboardingBatchItem,
    OnboardingBatchItem['createdAt']
  >
): string => {
  const uploadingDate = params.value;
  return uploadingDate
    ? format(new Date(uploadingDate), 'yyyy-MM-dd HH:mm')
    : '';
};

export const uploadedByFormatter = (
  params: ValueFormatterParams<
    OnboardingBatchItem,
    OnboardingBatchItem['createdBy']
  >
): string => {
  const createdBy = params.value;
  return getCreatedByLabel(createdBy);
};

export const generateUploadedByFilterFormatter = (users: User[]) => {
  const usersHash: Record<User['id'], User> = users.reduce(
    (acc, user) => ({
      ...acc,
      [user.id]: user,
    }),
    {}
  );

  return (
    params: ValueFormatterParams<OnboardingBatchItem, User['id']>
  ): string => {
    const userId = params.value;
    const createdBy = userId ? usersHash[userId] : null;
    return getCreatedByLabel(createdBy);
  };
};

export const statusFormatter = (
  params: ValueFormatterParams<OnboardingBatchItem, OnboardingBatchStatus>
): string => {
  const status = params.value;
  return status ? mapStatusToMessage[status].message : '';
};
