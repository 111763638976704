import {
  Button,
  HStack,
  prettyFormatBytes,
  Section,
  UploadModal,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import * as Sentry from '@sentry/react';
import {
  FORM_ERROR,
  FormApi,
  ValidationErrors,
  type Mutator,
} from 'final-form';
import arrayMutators, { Mutators } from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  type CSSProperties,
  type ReactElement,
} from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { useTheme, type DefaultTheme } from 'styled-components';
import { CSVTemplateDownloadButton } from '../../components/OnboardingBatches/CSVTemplateDownloadButton.component';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import { Steps } from '../../components/OnboardingBatches/OnboardingTutorial/onboardingTutorial.data';
import type { Association } from '../../types';
import { useSelectedOrganizationUuid } from '../../utils/useSelectedOrganizationUuid';
import { BatchMetadataFieldset } from './BatchMetadataFieldset';
import {
  BatchCreateForm,
  BatchCreateFormFilled,
  BatchEditForm,
  BatchName,
  convertStateToRecords,
  convertStateToUpdateWsiRecords,
  CreateOnboardingBatchWsiRecord,
  generateGroupedOnboardingErrors,
  generateInitialSlides,
  getMatchingWsisTissueCaseIdAndBlocks,
  getUniquePatientCaseIdPairs,
  mapSlidesToSlidesWithScannerIds,
  OnboardingFileWithMetadata,
  useMatchingWsis,
  type SelectData,
} from './Form';
import {
  useCreateOnboardingBatch,
  type CreateOnboardingBatchMutationResult,
} from './hooks/useCreateOnboardingBatch';
import {
  UpdateOnboardingBatchMutationResults,
  useUpdateOnboardingBatchWsis,
} from './hooks/useUpdateOnboardingBatchWsis';
import { MetadataGrid } from './MetadataGrid/MetadataGrid.component';
import { convertGridDataToSlides } from './MetadataGrid/MetadataGrid.dataFormatter';
import type {
  MetadataGridProps,
  MetadataGridRefHandle,
  MismatchingStainingError,
} from './MetadataGrid/MetadataGrid.types';
import {
  $BatchMetadataFullWidthWrapper,
  $Container,
  $FileMetadataFileSetWrapper,
  $Form,
  $HeaderButtonsContainer,
  $SubHeader,
} from './SetFileMetadataStep.styles';
import { SetFileMetadataStepFooter } from './SetFileMetadataStepFooter';
import { TotalAndValidSlideCount } from './SetFileMetadataStepFooter/SetFileMetadataStepFooter.component';
import StainingsMismatchModal from './StainingsMismatchModal/StainingsMismatchModal.component';
import { processCsvFile } from './utils/processCsvFile';

export interface SetFileMetadataStepProps {
  csv: File | undefined;
  onSuccessfulCreate?: (args: {
    createOnboardingBatch: CreateOnboardingBatchMutationResult['createOnboardingBatch'];
    records: CreateOnboardingBatchWsiRecord[];
  }) => void;
  onSuccessfulUpdate?: (args: {
    updateOnboardingBatchWsis: UpdateOnboardingBatchMutationResults['updateOnboardingBatchWsis'];
    batch_id: string;
  }) => void;
  submitLabel: 'submit' | 'transfer' | 'update';
  files: OnboardingFileWithMetadata[];
  onboardingBatch?: BatchEditForm;
  authToken: string | null;
  apiUrl: string;
  userAssignedAssociation: Pick<Association, 'name'> | null;
  onboardingBatchNames: BatchName[];
  selectData: SelectData;
  /** Used for the automated tests. */
  supressAgGridVirtualization?: boolean;
}

export const SNACKBAR_TIMEOUT = 8_000;
export const GEN_FULL_WIDTH_SECTION_STYLE = (
  theme: DefaultTheme
): CSSProperties => ({
  paddingLeft: `${theme.spacings['56']}px`,
  paddingRight: `${theme.spacings['56']}px`,
});

export function AgGridSetFileMetadataStep({
  csv,
  files,
  onSuccessfulCreate,
  onSuccessfulUpdate,
  submitLabel,
  onboardingBatch,
  authToken,
  apiUrl,
  userAssignedAssociation,
  onboardingBatchNames,
  selectData: {
    associations,
    currentDate,
    species,
    metadataUsageRestrictions,
    stainings,
    scanners,
    tissues,
    diseases,
    samplePreparations,
    sampleTypes,
    morphologies,
    cancerSites,
  },
  supressAgGridVirtualization = false,
}: SetFileMetadataStepProps): ReactElement | null {
  const theme = useTheme();
  const FULL_WIDTH_SECTION_STYLE = useMemo(
    () => GEN_FULL_WIDTH_SECTION_STYLE(theme),
    [theme]
  );
  const uploadCsvModal = useDisclosure();
  const stainingsErrorsModal = useDisclosure();
  const organizationUuid = useSelectedOrganizationUuid();
  const { addSnackbar, clearSnackbar } = useSnackbarMutations();

  const [csvProcessed, setCsvProcessed] = useState(false);
  // TODO: will be addressed in [FE-5182]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, sonarjs/no-unused-vars, sonarjs/no-dead-store
  const [csvIsProcessing, setCsvIsProcessing] = useState(false);
  const [selectedCsvFile, setSelectedCsvFile] = useState(csv ?? null);
  const [stainingsMismatchErrors, setStainingsMismatchErrors] = useState<
    MismatchingStainingError[]
  >([]);
  const [updatedStainingsValues, setUpdatedStainingsValues] = useState<
    MismatchingStainingError[]
  >([]);
  const gridRef = useRef<MetadataGridRefHandle>(null);
  const [gridReadiness, setGridReadiness] = useState(false);

  const handleGridReady = useCallback(() => {
    setGridReadiness(true);
  }, []);

  const assertGridReadiness: () => MetadataGridRefHandle = useCallback(() => {
    if (gridReadiness) {
      if (!gridRef.current) {
        throw new Error('grid should had been ready');
      }

      return gridRef.current;
    }

    throw new Error('grid is not ready');
  }, [gridReadiness]);
  const [totalAndValidSlideCount, setTotalAndValidSlideCount] =
    useState<TotalAndValidSlideCount | null>(null);
  const allSlideEntriesAreValid = totalAndValidSlideCount
    ? totalAndValidSlideCount.invalidFilenames.size === 0
    : false;

  const onFormReset = () => {
    clearSnackbar();
    setCsvProcessed(false);
  };
  const callbackOnRowDelete = useCallback(
    (form: FormApi<BatchCreateForm>) => (deletedFileNames: string[]) => {
      const formValues = form.getState().values;

      const slides = formValues.slides;
      const indexesOfDeletedSlides: number[] = [];
      deletedFileNames.forEach((deletedFileName) => {
        indexesOfDeletedSlides.push(
          slides.findIndex(
            (slide) => slide.slideFile.filename === deletedFileName
          )
        );
      });
      form.mutators.removeBatch('slides', indexesOfDeletedSlides);
    },
    []
  );

  const createOnboardingBatchMutation = useCreateOnboardingBatch({
    onboardingType: submitLabel === 'submit' ? 'local' : 'remote',
  });
  const updateOnboardingBatchWsisMutation = useUpdateOnboardingBatchWsis(
    onboardingBatch?.batch_id
  );

  const showValidationErrors = (errors: ValidationErrors) => {
    clearSnackbar();
    if (errors?.[FORM_ERROR]) {
      addSnackbar({
        type: 'error',
        message: errors[FORM_ERROR],
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
    for (const message of generateGroupedOnboardingErrors(errors)) {
      addSnackbar({
        type: 'error',
        message,
        closesAfter: SNACKBAR_TIMEOUT,
      });
    }
    if (!allSlideEntriesAreValid) {
      const invalidFilenames = Array.from(
        totalAndValidSlideCount!.invalidFilenames
      );
      addSnackbar({
        type: 'error',
        message: `Slide ${pluralize('file', invalidFilenames.length)} ${invalidFilenames.join(', ')} have incorrect values.`,
      });
    }
  };

  const handleDuplicateSlideAcceptance = useCallback(
    (duplicateSlideNames: string[]) => {
      const grid = assertGridReadiness();
      grid.deleteSlides(duplicateSlideNames);
    },
    [assertGridReadiness]
  );

  const isEditMode = Boolean(onboardingBatch);
  const initialValues: BatchCreateForm = useMemo(
    () =>
      onboardingBatch
        ? {
            ...onboardingBatch,
            areFieldsSyncing: false,
            slides: mapSlidesToSlidesWithScannerIds(
              onboardingBatch.slides,
              scanners
            ),
          }
        : {
            batch_name: '',
            association: null,
            species: null,
            metadataUsageRestriction: null,
            areFieldsSyncing: false,
            slides: generateInitialSlides(stainings, files),
          },
    [onboardingBatch, files, scanners, stainings]
  );

  const {
    handleCompletedRefetchMatchingWsisGroups,
    refetchMatchingWsis,
    refetchMatchingWsisLoadingStatus,
  } = useMatchingWsis();

  const displayStainingsMismatchDialog = useCallback<
    MetadataGridProps['displayStainingsMismatchDialog']
  >(
    (wrongChannels) => {
      setStainingsMismatchErrors(wrongChannels);
      setUpdatedStainingsValues(
        wrongChannels.map((channel) => ({
          rowId: channel.rowId,
          value: null,
        }))
      );
      stainingsErrorsModal.open();
    },
    [stainingsErrorsModal]
  );

  return (
    <>
      <FinalForm<BatchCreateForm>
        mutators={{
          ...(arrayMutators as unknown as Record<
            keyof Mutators,
            Mutator<BatchCreateForm>
          >),
          ...{
            setFieldTouched: setFieldTouched as Mutator<BatchCreateForm>,
          },
        }}
        onSubmit={async (values) => {
          const grid = assertGridReadiness();

          if (!allSlideEntriesAreValid) {
            return;
          }

          if (refetchMatchingWsisLoadingStatus) {
            addSnackbar({
              type: 'error',
              message: 'Validation is in progress, can not submit',
              closesAfter: 3000,
            });
            return;
          }

          const resultValues = {
            ...values,
            slides: convertGridDataToSlides(grid.getValues()),
          } as BatchCreateFormFilled;

          try {
            const batch_id = onboardingBatch?.batch_id;
            if (
              batch_id &&
              updateOnboardingBatchWsisMutation !== null &&
              onSuccessfulUpdate
            ) {
              const { updateOnboardingBatchWsis } =
                await updateOnboardingBatchWsisMutation({
                  batch_id,
                  wsis: convertStateToUpdateWsiRecords(resultValues.slides),
                  association: onboardingBatch.association,
                  metadataUsageRestriction:
                    resultValues.metadataUsageRestriction,
                });
              clearSnackbar();
              onSuccessfulUpdate({
                updateOnboardingBatchWsis,
                batch_id,
              });
            } else if (onSuccessfulCreate) {
              const records = convertStateToRecords(files, resultValues.slides);
              const { createOnboardingBatch } =
                await createOnboardingBatchMutation({
                  batch_name: resultValues.batch_name,
                  species: resultValues.species,
                  metadataUsageRestriction:
                    resultValues.metadataUsageRestriction,
                  association:
                    userAssignedAssociation !== null
                      ? userAssignedAssociation.name
                      : resultValues.association,
                  wsis: records,
                });
              clearSnackbar();
              onSuccessfulCreate({
                createOnboardingBatch,
                records,
              });
            }

            return;
          } catch (error) {
            Sentry.captureException(error, {
              extra: {
                values: resultValues,
              },
            });
            clearSnackbar();
            addSnackbar({
              type: 'error',
              message: String(error),
              closesAfter: SNACKBAR_TIMEOUT,
            });
          }
        }}
        initialValues={initialValues}
        subscription={{ submitting: true }}
        render={({ form, handleSubmit, submitting }) => (
          <>
            <Section
              background="white"
              loading={submitting}
              innerDontConstrainWidth
              style={FULL_WIDTH_SECTION_STYLE}
            >
              <HStack justifyContent="space-between">
                <span
                  aria-label="Form Metadata header text"
                  style={{
                    ...theme.fontStyles.displayBold,
                  }}
                >
                  {totalAndValidSlideCount
                    ? `${totalAndValidSlideCount.total} ${pluralize('slide', totalAndValidSlideCount.total)} - Total size: ${prettyFormatBytes(totalAndValidSlideCount.totalByteSize)}`
                    : '0 slides'}
                </span>
                <$HeaderButtonsContainer>
                  <CSVTemplateDownloadButton />
                  {!selectedCsvFile ? (
                    <Button onClick={uploadCsvModal.open} small>
                      Add CSV File
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setSelectedCsvFile(null);
                        onFormReset();
                        const grid = assertGridReadiness();
                        grid.reset();
                      }}
                      small
                    >{`Reset form & remove "${selectedCsvFile.name}"`}</Button>
                  )}
                </$HeaderButtonsContainer>
              </HStack>
            </Section>
            <Section
              aria-label="SetFileMetadataStep Section"
              innerDontConstrainWidth
              style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}
              innerStyle={{
                flexGrow: 1,
              }}
            >
              <$Form onSubmit={handleSubmit} noValidate>
                {/*
                 * without this button first Button click in the form will be triggered
                 * which is currently ToggleExtraFields
                 * see: https://stackoverflow.com/a/51507806
                 */}
                <button type="submit" disabled style={{ display: 'none' }} />
                <$Container>
                  <$SubHeader>
                    <span>*Mandatory data</span>
                  </$SubHeader>
                  <$BatchMetadataFullWidthWrapper>
                    <div style={{ width: theme.breakpoints.FULL }}>
                      <FormSpy<BatchCreateForm>
                        subscription={{ values: true }}
                        render={({ values, form }) => (
                          <BatchMetadataFieldset
                            isDisabled={Boolean(onboardingBatch)}
                            availableOnboardingBatchNames={onboardingBatchNames}
                            availableAssociations={associations}
                            userAssignedAssociation={userAssignedAssociation}
                            availableSpecies={species}
                            availableMetadataUsageRestrictions={
                              metadataUsageRestrictions
                            }
                            formSlides={values.slides}
                            formBatchName={
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              values.batchName
                            } // TODO: rename batch_name to batchName and fix relevant types in BatchCreateForm | FE-5562
                            formChange={form.change}
                            initialState={initialValues}
                            onSlideDuplicationAcceptance={(
                              _,
                              duplicateSlideNames
                            ) => {
                              callbackOnRowDelete(form)(duplicateSlideNames); // TODO: remove this when we remove the react-final-form | FE-5550
                              handleDuplicateSlideAcceptance(
                                duplicateSlideNames
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                  </$BatchMetadataFullWidthWrapper>
                  <$FileMetadataFileSetWrapper>
                    <MetadataGrid
                      ref={gridRef}
                      stainings={stainings}
                      scanners={scanners}
                      tissues={tissues}
                      diseases={diseases}
                      preparationTypes={samplePreparations}
                      sampleTypes={sampleTypes}
                      morphologies={morphologies}
                      cancerSites={cancerSites}
                      initialValues={initialValues.slides}
                      apiUrl={apiUrl}
                      authToken={authToken}
                      organizationUuid={organizationUuid}
                      updateValidCount={setTotalAndValidSlideCount}
                      onGridReady={handleGridReady}
                      supressAgGridVirtualization={supressAgGridVirtualization}
                      onRowDelete={callbackOnRowDelete(form)}
                      displayStainingsMismatchDialog={
                        displayStainingsMismatchDialog
                      }
                      isEditMode={isEditMode}
                    />
                  </$FileMetadataFileSetWrapper>
                </$Container>
                <SetFileMetadataStepFooter
                  onReset={() => {
                    const grid = assertGridReadiness();
                    onFormReset();
                    grid.reset();
                  }}
                  onSubmit={showValidationErrors}
                  totalAndValidSlideCount={totalAndValidSlideCount}
                />
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values: { slides, association } }) => {
                    const associationParam =
                      association ?? userAssignedAssociation?.name;
                    const matchingWsisTissueCaseIdAndBlocks =
                      getMatchingWsisTissueCaseIdAndBlocks(slides);

                    if (
                      (getUniquePatientCaseIdPairs(slides).length !== 0 ||
                        matchingWsisTissueCaseIdAndBlocks.length !== 0) &&
                      Boolean(associationParam)
                    ) {
                      void refetchMatchingWsis({
                        variables: {
                          association: associationParam,
                          subqueries: getUniquePatientCaseIdPairs(slides),
                          blockCaseIdTissuesTriples:
                            getMatchingWsisTissueCaseIdAndBlocks(slides),
                        },
                        onCompleted(data) {
                          handleCompletedRefetchMatchingWsisGroups(
                            slides,
                            data
                          );
                        },
                      });
                    }
                  }}
                  render={() => null} // need this line just for avoiding error messages in unit tests
                />
                <FormSpy
                  subscription={{ values: true }}
                  render={() => {
                    /**
                       1. Using render instead of onChange because it requires access to the FormAPI.
                       2. This is triggered not only by changes in values but also by changes in selectData, selectedCsvFile,
                       and even when the uploadCsvModal opens or closes.
                       Thus, for this component, it functions similarly to useEffect, but also provides access to the FormAPI and the current values of slides.
                     */
                    if (gridReadiness && selectedCsvFile && !csvProcessed) {
                      const grid = assertGridReadiness();
                      const gridData = grid.getValues();
                      if (!gridData) {
                        return;
                      }
                      const slides = convertGridDataToSlides(gridData);
                      setCsvIsProcessing(true);
                      void processCsvFile(
                        selectedCsvFile,
                        {
                          associations,
                          currentDate,
                          species,
                          stainings,
                          scanners,
                          tissues,
                          diseases,
                          samplePreparations,
                          sampleTypes,
                          morphologies,
                          cancerSites,
                          metadataUsageRestrictions,
                        },
                        slides
                      ).then((csvProcessedResult) => {
                        clearSnackbar();
                        if (csvProcessedResult.ok) {
                          const grid = assertGridReadiness();
                          grid.processCsvEntries(csvProcessedResult);
                          csvProcessedResult.warnings.forEach((warning) =>
                            addSnackbar({
                              type: 'warning',
                              message: warning,
                              closesAfter: SNACKBAR_TIMEOUT,
                            })
                          );
                        } else {
                          addSnackbar({
                            type: 'error',
                            message: csvProcessedResult.error,
                            closesAfter: SNACKBAR_TIMEOUT,
                          });
                        }
                      });
                      setCsvIsProcessing(false);
                      setCsvProcessed(true);
                    }
                    return null;
                  }}
                />
                <StainingsMismatchModal
                  isOpen={stainingsErrorsModal.isOpen}
                  onCloseModal={() => {
                    stainingsErrorsModal.close();
                    const grid = assertGridReadiness();
                    // give focus back to grid where it was
                    grid.refocus();
                  }}
                  stainingsMismatchErrors={stainingsMismatchErrors}
                  updatedStainingsValues={updatedStainingsValues}
                  stainings={stainings}
                  setUpdatedStainingsValues={setUpdatedStainingsValues}
                  onUpdateStainings={() => {
                    const grid = assertGridReadiness();
                    grid.updateMismatchingStainings(
                      // drop out elements from the dialog without set staining
                      updatedStainingsValues.filter(
                        (updatedStaining) => updatedStaining.value
                      )
                    );
                  }}
                />
              </$Form>
            </Section>
          </>
        )}
      />
      <UploadModal
        isVisible={uploadCsvModal.isOpen}
        accept=".csv"
        acceptMultipleFiles={false}
        onClose={uploadCsvModal.close}
        onDropAccepted={([uploadedCsv]) => {
          setSelectedCsvFile(uploadedCsv);
          uploadCsvModal.close();
        }}
        title="Add your CSV document"
      />
      <OnboardingTutorialModal initialStep={Steps.METADATA} />
    </>
  );
}
