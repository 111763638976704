import {
  AGGridTable,
  Button,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { useApolloClient, useMutation } from '@apollo/client';
import { FirstDataRenderedEvent, GridApi } from 'ag-grid-enterprise';
import React, { ReactElement, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import {
  CREATE_ANNOTATION_CATEGORIES,
  CreateAnnotationCategoryModal,
} from '../../../components';
import { AnnotationCategoryInput } from '../../../types';
import { FETCH_ANNOTATION_CATEGORIES } from './grahphql/FETCH_ANNOTATION_CATEGORIES';
import {
  AnnotationCategoryData,
  useAnnotationCategoriesDatasource,
} from './hooks/useServerSideDatasource';
import { getAnnotationCategoriesColumnDefs } from './utils/getAnnotationCategoriesColumnDefs';

const ActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const AnnotationCategories = (): ReactElement => {
  const gridApiRef = useRef<GridApi | null>(null);
  const client = useApolloClient();
  const { addSnackbar } = useSnackbarMutations();
  const { open, close, isOpen } = useDisclosure();

  const refreshGrid = useCallback(() => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshServerSide({ purge: true });
    }
  }, []);

  const handleGridReady = useCallback(
    (params: FirstDataRenderedEvent<AnnotationCategoryData>) => {
      gridApiRef.current = params.api;
    },
    []
  );

  const [createAnnotationCategories] = useMutation(
    CREATE_ANNOTATION_CATEGORIES,
    {
      onError: (error) => {
        addSnackbar({
          type: 'error',
          message: error.message,
        });
      },
      onCompleted: () => {
        addSnackbar({
          type: 'success',
          message: 'Annotation category was successfully created!',
        });
        refreshGrid();
      },
    }
  );

  const handleCreateAnnotationCategory = async (
    newAnnotationCategory: AnnotationCategoryInput
  ) => {
    const { name, annotationCategorySet, color, id } = newAnnotationCategory;

    await createAnnotationCategories({
      variables: {
        annotationCategories: [
          {
            name,
            color,
            id,
            ...(annotationCategorySet?.kind !== 'unset'
              ? { annotationCategorySet }
              : null),
          },
        ],
      },
    });
    close();
  };

  const datasource = useAnnotationCategoriesDatasource(
    FETCH_ANNOTATION_CATEGORIES
  );

  const columnDefs = useMemo(
    () => getAnnotationCategoriesColumnDefs(client, refreshGrid),
    [client, refreshGrid]
  );

  return (
    <>
      <ActionButton>
        <Button onClick={open} name="Create Category" small icon="PlusCircle">
          Create Category
        </Button>
      </ActionButton>

      <AGGridTable
        columnDefs={columnDefs}
        serverSideDatasource={datasource}
        onFirstDataRendered={handleGridReady}
      />

      <CreateAnnotationCategoryModal
        isOpen={isOpen}
        onClose={close}
        onCreateAnnotationCategory={handleCreateAnnotationCategory}
      />
    </>
  );
};
