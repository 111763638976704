import React from 'react';
import ReactSelectCreatable from 'react-select/creatable';
import { useTheme } from 'styled-components';
import { FormControl } from '../FormControl';
import customStyles from './Select.styles';
import { useMenuWithAria } from './useMenuWithAria';
const constructInvalidSelectOption = (value) => ({
    value,
    label: value,
});
const getSelectedOption = (options, value, allowInvalidValue) => {
    if (value === null) {
        return null;
    }
    const result = options.find((option) => option.value === value);
    if (result === undefined && allowInvalidValue) {
        return constructInvalidSelectOption(value);
    }
    return result;
};
export const SelectCreatable = ({ icon, disabled, label, onChange, options, value, allowInvalidValue = true, id, isInvalid = false, helperMessage, required, onBlur, onFocus, autoFocus = false, menuPosition, menuPlacement = 'auto', labelPosition = 'top', isClearable, }) => {
    const normalizedOptions = () => {
        if (allowInvalidValue) {
            const hasInvalidOption = value
                ? !options.map((o) => o.value).includes(value)
                : false;
            const invalidOption = constructInvalidSelectOption(value || '');
            if (hasInvalidOption) {
                return [invalidOption, ...options];
            }
        }
        return options;
    };
    const selectedOption = getSelectedOption(normalizedOptions(), value, allowInvalidValue);
    const theme = useTheme();
    let styles = customStyles(theme, isInvalid);
    // https://react-select.com/advanced#portaling
    styles = {
        ...styles,
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
    const Menu = useMenuWithAria(false, label);
    return (React.createElement(FormControl, { id: id, label: label, icon: icon, isRequired: required, isInvalid: isInvalid, helperMessage: helperMessage, labelPosition: labelPosition },
        React.createElement(ReactSelectCreatable, { id: id, isDisabled: disabled, isMulti: false, onChange: onChange, options: normalizedOptions(), styles: styles, value: selectedOption, onBlur: onBlur, onFocus: onFocus, isOptionDisabled: (option) => option.disabled ?? false, autoFocus: autoFocus, menuPlacement: menuPlacement, menuPosition: menuPosition, isClearable: isClearable, menuPortalTarget: document.body, "aria-label": label, components: { Menu } })));
};
