import { Button } from '@aignostics/components';
import { pluralize } from '@aignostics/utils';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement, useMemo } from 'react';
import { CloudAsset } from '../../../types/CloudAsset';
import { CloudFileInfoWithMrxsFile } from '../../getMrxsFileSizeChecksum';

export type SelectSlideHeaderParams = CustomCellRendererProps<CloudAsset> & {
  files: CloudAsset[];
  selectedFiles: CloudFileInfoWithMrxsFile[];
  onSelectedFilesUpdate: (files: CloudFileInfoWithMrxsFile[]) => void;
  filteredValidFiles: CloudFileInfoWithMrxsFile[];
};
export const SelectSlideHeader = ({
  files,
  filteredValidFiles,
  selectedFiles,
  onSelectedFilesUpdate,
}: SelectSlideHeaderParams): ReactElement | null => {
  const isAllFilesSelected = useMemo(() => {
    // if the number of files and selected files are different, not all files are selected
    if (files.length !== selectedFiles.length) {
      return false;
    }

    // create a hash of selected files for quick lookup
    const selectedFilesHash = selectedFiles.reduce<Record<string, boolean>>(
      (acc, file) => {
        acc[file.path] = true;
        return acc;
      },
      {}
    );
    // check if all files are selected
    return files.every((f) => selectedFilesHash[f.path]);
  }, [files, selectedFiles]);

  // if all files are selected, show clear selection button
  if (isAllFilesSelected) {
    return (
      <Button
        role="button"
        onClick={() => {
          onSelectedFilesUpdate([]);
        }}
        small
      >
        Clear selection ({selectedFiles.length}{' '}
        {pluralize('file', selectedFiles.length)} )
      </Button>
    );
  }

  // if there are valid files, show add all button
  if (filteredValidFiles.length > 0) {
    return (
      <Button
        role="button"
        onClick={() => {
          onSelectedFilesUpdate(filteredValidFiles);
        }}
        small
      >
        Add all
      </Button>
    );
  }

  // if there are no valid files, show empty header
  return null;
};
