import { gql } from '@apollo/client';

export const FETCH_SUBPROJECTS_LIST = gql`
  query GET_SUBPROJECTS_LIST(
    $projectId: ID!
    $page: Int
    $pageSize: Int
    $search: String
    $sortBy: sortBySubprojectValues
    $sortDirection: sortDirection
    $isAdminView: Boolean!
  ) {
    project(id: $projectId) {
      id
      name
      description
      assignedUsers {
        id
        name
      }
      isVisible
      subProjects(
        page: $page
        pageSize: $pageSize
        search: $search
        isAdminView: $isAdminView
        sortBy: $sortBy
        sortDirection: $sortDirection
      ) {
        nodes {
          id
          name
          wsisCount
          annotationFeature
          thumbnailWsiId
          annotatedUserIds
          isVisible
          createdBy {
            id
            email
            name
          }
        }
        pageInfo {
          page
          totalElements
          totalPages
        }
        collectionAttributes {
          availableSubProjectCount
        }
      }
    }
  }
`;

export const FETCH_SUBPROJECTS_NAMES_LIST = gql`
  query GET_SUBPROJECTS_LIST($projectId: ID!) {
    project(id: $projectId) {
      id
      subProjects(isAdminView: true) {
        nodes {
          id
          name
        }
        pageInfo {
          page
          totalElements
          totalPages
        }
      }
    }
  }
`;
