import { debounce } from 'lodash';
import React, { useEffect, useRef, useState, } from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
const $TableCellStyled = styled.td `
  ${({ theme, align: textAlign = 'left', numeric }) => ({
    height: theme.spacings.touch,
    minWidth: '36px',
    maxWidth: theme.spacings[96],
    wordWrap: 'break-word',
    padding: '0 6px',
    background: theme.colors.white,
    borderTop: `1px solid ${theme.colors.light}`,
    borderBottom: `1px solid ${theme.colors.light}`,
    '&:first-child': {
        borderLeft: `1px solid ${theme.colors.light}`,
    },
    '&:last-child': {
        borderRight: `1px solid ${theme.colors.light}`,
    },
    textAlign,
    fontVariantNumeric: numeric ? 'tabular-nums' : undefined,
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
})};
`;
function TableCell({ tooltipText, children, style, align, numeric, ...props }) {
    const cellRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    useEffect(() => {
        function checkOverflow() {
            const cell = cellRef.current;
            if (cell && cell.offsetWidth < cell.scrollWidth) {
                setIsOverflowing(true);
            }
            else {
                setIsOverflowing(false);
            }
        }
        const debouncedCheckOverflow = debounce(checkOverflow, 100);
        debouncedCheckOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [children, tooltipText]);
    return isOverflowing ? (React.createElement(Tooltip, { content: tooltipText },
        React.createElement($TableCellStyled, { ref: cellRef, numeric: numeric, align: align, ...props },
            React.createElement("div", { style: {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                } }, children)))) : (React.createElement($TableCellStyled, { ref: cellRef, style: style, align: align, ...props }, children));
}
export default TableCell;
