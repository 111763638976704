import {
  OnboardingReadOnlyBatchHeader,
  PageLayout,
  Section,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import { useQuery } from '@apollo/client';
import dateFormat from 'date-fns/format';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { OnboardingTutorialModal } from '../../components/OnboardingBatches/OnboardingTutorial/OnboardingTutorial.component';
import { OnboardingProgressErrorsModal } from '../../components/ReadOnly/OnboardingProgressErrorsModal.component';
import { ONBOARDING_BATCH_COMPLETED_STATUSES } from '../../components/ReadOnly/const';
import { useFetchOnboardingWsisQuery } from '../../hooks/useFetchOnboardingWsisQuery';
import useUserAssignedAssociation from '../../hooks/useUserAssignedAssociation';
import { useValidateOnboardingBatch } from '../../hooks/useValidateOnboardingBatch';
import type { BatchReadOnlyForm } from '../AgGridSetFileMetadataStep/Form';
import {
  selectDataQuery,
  type SelectData,
} from '../AgGridSetFileMetadataStep/Form/form.queries';
import { convertRecordsToState } from '../AgGridSetFileMetadataStep/Form/form.state';
import { GEN_FULL_WIDTH_SECTION_STYLE } from '../AgGridSetFileMetadataStep/SetFileMetadataStep.component';
import { useFilteredSelectData } from '../AgGridSetFileMetadataStep/hooks/useFilteredSelectData';
import {
  mapStatusToTutorialStep,
  renderOnboardingBatchSubHeader,
} from './Admin.DataOnboardingBatch.utils';
import { BatchesGrid } from './BatchesGrid';
import { useOnboardingCompletedNotification } from './useOnboardingCompletedNotification';

const APOSTROPHE = '\u2019';

type AdminDataOnboardingProps = {
  role: OrganizationRole;
  currentUser: User;
};

const AdminDataOnboardingBatch = ({
  role,
  currentUser,
}: AdminDataOnboardingProps): ReactElement => {
  const { batchId } = useParams() as { batchId: string };
  const onboardingProgressErrorsDialog = useDisclosure();
  const { addSnackbar } = useSnackbarMutations();
  const theme = useTheme();

  const FULL_WIDTH_SECTION_STYLE = useMemo(
    () => GEN_FULL_WIDTH_SECTION_STYLE(theme),
    [theme]
  );
  const FULL_WIDTH_SECTION_STYLE_ZERO_BOTTOM_PADDING = useMemo(
    () => ({ ...GEN_FULL_WIDTH_SECTION_STYLE(theme), paddingBottom: '0' }),
    [theme]
  );

  const {
    addBatchSucceededSnackbar,
    addBatchSucceededWithErrorsSnackbar,
    addBatchFailedSnackbar,
  } = useOnboardingCompletedNotification();

  const { data, loading, error } = useFetchOnboardingWsisQuery({
    variables: { batchId, injectBlackLayerReferences: false },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.onboardingBatch?.length) {
        const { status, onboardingErrors, succeededSlidesCount, slides } =
          convertRecordsToState(data.onboardingBatch[0]);

        if (ONBOARDING_BATCH_COMPLETED_STATUSES.includes(status)) {
          if (status !== 'failed') {
            if (onboardingErrors.length) {
              addBatchSucceededWithErrorsSnackbar(() => {
                onboardingProgressErrorsDialog.open();
              });
            } else {
              addBatchSucceededSnackbar(succeededSlidesCount === slides.length);
            }
          } else {
            addBatchFailedSnackbar();
          }
        }
      }
    },
  });

  const onboardingBatches: BatchReadOnlyForm[] = data
    ? data.onboardingBatch.map(convertRecordsToState)
    : [];

  const latestBatchId = onboardingBatches[0]
    ? onboardingBatches[0].batch_id
    : null;

  const addSelectDataErrorSnackbar = useCallback(() => {
    addSnackbar({
      message: 'An error has occurred loading morphologies',
      type: 'error',
    });
  }, [addSnackbar]);

  const selectData = useFilteredSelectData(
    useQuery<SelectData>(selectDataQuery, {
      onError: addSelectDataErrorSnackbar,
      onCompleted: (data) => {
        if (!data) {
          addSelectDataErrorSnackbar();
        }
      },
    })
  );

  const { userAssignedAssociation, userAssignedAssociationLoading } =
    useUserAssignedAssociation();
  const showAssociations =
    userAssignedAssociation === null && !userAssignedAssociationLoading;

  const { validateOnboardingBatch, isValidating } = useValidateOnboardingBatch({
    onSuccess() {
      setValidationSuccessfullyTriggered(true);
    },
    batchId: latestBatchId,
  });

  const [
    isValidationSuccessfullyTriggered,
    setValidationSuccessfullyTriggered,
  ] = useState(false);

  if (
    loading ||
    error ||
    !data ||
    selectData.loading ||
    selectData.error ||
    !selectData.data
  ) {
    return (
      <PageLayout
        loading={loading || selectData.loading}
        error={error || selectData.error}
      />
    );
  }

  const { morphologies } = selectData.data;

  if (!data.onboardingBatch || data.onboardingBatch.length === 0) {
    return (
      <PageLayout
        error={new Error(`Couldn${APOSTROPHE}t find batch ${batchId}`)}
      />
    );
  }

  const {
    batch_name,
    species,
    association,
    status,
    createdAt,
    createdBy,
    onboardingErrors,
    metadataUsageRestriction,
  } = onboardingBatches[0];
  const { header, subHeader, headerIcon } =
    renderOnboardingBatchSubHeader(status);
  const formattedCreatedAt = dateFormat(new Date(createdAt), 'yyyy-MM-dd');
  const createdBySuffixMsg = createdBy
    ? ` by ${createdBy.name || createdBy.email}`
    : ' by Unknown User';

  return (
    <>
      <PageLayout
        title={batch_name}
        currentOrganization={role.organization.name}
        fullWidth
      >
        <Section
          background="white"
          loading={loading || (isValidating && status === 'pending')}
          innerDontConstrainWidth
          style={FULL_WIDTH_SECTION_STYLE}
        >
          <p>{`Batch uploaded on ${formattedCreatedAt}${createdBySuffixMsg}`}</p>
        </Section>

        <Section
          aria-label="Data Onboarding Batch Section"
          title={header}
          titleIcon={headerIcon}
          description={subHeader}
          innerDontConstrainWidth
          style={FULL_WIDTH_SECTION_STYLE_ZERO_BOTTOM_PADDING}
        >
          <OnboardingReadOnlyBatchHeader
            name={batch_name}
            species={species}
            association={association}
            showAssociations={showAssociations}
            metadataUsageRestriction={metadataUsageRestriction}
          />

          <BatchesGrid
            onboardingBatches={onboardingBatches}
            currentUser={currentUser}
            role={role}
            isValidating={isValidating}
            isValidationSuccessfullyTriggered={
              isValidationSuccessfullyTriggered
            }
            validateOnboardingBatch={validateOnboardingBatch}
            morphologies={morphologies}
          />
        </Section>
      </PageLayout>

      <OnboardingTutorialModal initialStep={mapStatusToTutorialStep(status)} />

      {onboardingErrors?.length > 0 && (
        <OnboardingProgressErrorsModal
          isVisible={onboardingProgressErrorsDialog.isOpen}
          onClose={onboardingProgressErrorsDialog.close}
          onboardingErrors={onboardingErrors}
        />
      )}
    </>
  );
};

export default AdminDataOnboardingBatch;
