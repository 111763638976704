import { User } from '@aignostics/core';
import { capitalize } from 'lodash';
import { FilterOptions } from '../../hooks';

export const getAssociationLabel = <T extends { name: string }>(
  association?: T | null
): string => (association ? capitalize(association.name) : '');

export const getAssociationFilterValues = (
  associations: FilterOptions['associations']
): string[] => associations.map((association) => association.name);

export const getUploadingDateFilterRange = (
  dateRange: FilterOptions['uploadDates']
): { from?: Date; to: Date } => {
  if (dateRange.length > 0) {
    return {
      from: new Date(parseInt(dateRange[0].created_at)),
      to: new Date(parseInt(dateRange[dateRange.length - 1].created_at)),
    };
  }
  // if no date range is provided, return the default range
  return {
    from: undefined,
    to: new Date(),
  };
};

export const getUploadedByFilterValues = (
  users: FilterOptions['users']
): User['id'][] => users.map((user) => user.id);

export const getCreatedByLabel = <
  T extends { name: string | null; email: string | null },
>(
  createdBy?: T | null
): string => {
  return createdBy?.name || createdBy?.email || 'Unknown User';
};
