import React from 'react';
import { Tooltip } from '../Tooltip';
import { $DiscreteProgressBarContainer, $DiscreteProgressBarTile, } from './DiscreteProgressBar.style';
/**
   Discrete Progress Bar provide status of different steps.
   There are no minimum amount of tiles required.
   An array of status must be provided.
   Each step must have an own status and might have a name.
 */
export const DiscreteProgressBar = ({ statusArray, }) => {
    const tiles = () => {
        const tileArray = [];
        const tileBackgroundColor = (status) => {
            switch (status) {
                case 'failed': {
                    return 'error';
                }
                case 'ongoing': {
                    return 'dark';
                }
                case 'pending': {
                    return 'light';
                }
                case 'success': {
                    return 'success';
                }
                case 'success-with-errors': {
                    return 'warning';
                }
            }
        };
        statusArray.forEach((status) => tileArray.push(React.createElement("div", { style: { display: 'flex', alignItems: 'center' }, key: status.name },
            React.createElement(Tooltip, { key: status.name, content: status.name },
                React.createElement($DiscreteProgressBarTile, { backgroundColor: tileBackgroundColor(status.status), key: status.name })))));
        return tileArray;
    };
    return (React.createElement($DiscreteProgressBarContainer, null, tiles()));
};
