import { Button } from '@aignostics/components';
import React, { ReactElement } from 'react';

export const TransferCloudSlidesButton = ({
  onClick,
}: {
  onClick: () => void;
}): ReactElement => {
  return (
    <Button
      name="Transfer from Cloud"
      small
      icon="UploadCloud"
      onClick={onClick}
    >
      Transfer from Cloud
    </Button>
  );
};
