import {
  Icon,
  LoaderBar,
  Modal,
  Pagination,
  Placeholder,
  RadioButton,
  SpreadFilterProps,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableSkeleton,
  Tooltip,
  VStack,
  getFiltersFromQueryParams,
  getPageFromQueryParams,
  useFilters,
  usePagination,
} from '@aignostics/components';
import { useDebounce } from '@aignostics/hooks';
import React, { ReactElement, useMemo } from 'react';
import { AdminSubProjectWsisFilters } from '../../AdminSubProjectWsisFilters.component';
import {
  FilterKeysWithAssociation,
  PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION,
  PAGE_FILTER_CONFIG_WITH_ASSOCIATION,
} from '../AdminSubprojectSlides.type';
import {
  $ContentWrapper,
  $CountContainer,
  $InfoTooltip,
  $LoadingContainer,
  $ModalTitle,
  $OverlayByContainer,
  $PaginationContainer,
  $TooltipList,
} from './EditStainingsModal.styles';
import { EditStainingsWsiRow } from './components/EditStainingsWsiRow';
import { EnableAllToggle } from './components/EnableAllToggle';
import { useEditStainings } from './useEditStainings';

interface EditStainingsModalProps {
  isVisible: boolean;
  onClose: () => void;
  showAssociations: boolean;
  subProjectId: string;
  organizationUuid: string;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
}

const PAGE_SIZE = 20;

const EditStainingsModal = ({
  isVisible,
  onClose,
  showAssociations,
  subProjectId,
  getToken,
  rasterTileServerUrl,
  organizationUuid,
}: EditStainingsModalProps): ReactElement => {
  const pageFilterConfig = useMemo(
    () =>
      showAssociations
        ? PAGE_FILTER_CONFIG_WITH_ASSOCIATION
        : PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION,
    [showAssociations]
  );
  const { filters, filterProps } = useFilters(
    pageFilterConfig,
    getFiltersFromQueryParams(pageFilterConfig)
  );
  const [page, setPage] = usePagination(getPageFromQueryParams());

  const debouncedFilters = useDebounce(filters, 200);
  const {
    subProject,
    loading,
    switchStaining,
    switchAll,
    switchingStainings,
    stainingsData,
    setSubProjectOverlayBy,
    overlayBy,
  } = useEditStainings(subProjectId, debouncedFilters, page, PAGE_SIZE);

  return (
    <>
      <Modal
        shouldCloseOnEscKey
        hasCloseButton
        isVisible={isVisible}
        onClose={onClose}
      >
        <$ContentWrapper id="modal-wrapper">
          <$LoadingContainer>
            <LoaderBar loading={loading || switchingStainings} />
          </$LoadingContainer>
          <$ModalTitle>Edit stainings</$ModalTitle>

          <AdminSubProjectWsisFilters
            filterProps={
              filterProps as SpreadFilterProps<FilterKeysWithAssociation>
            }
            subprojectId={subProjectId}
            onChange={(e) => {
              setPage(1);
              filterProps.onChange(e);
            }}
            showAssociations={showAssociations}
            withSlidesListFilters={false}
          />

          {loading && (
            <VStack style={{ marginTop: '16px' }}>
              <TableSkeleton rows={10} />
            </VStack>
          )}

          {!loading && (
            <>
              {!subProject?.wsis.nodes?.length ? (
                <Placeholder
                  title="No slides"
                  description="There are no slides matching the current filters"
                />
              ) : (
                <>
                  <$CountContainer>
                    <div>
                      Showing: {subProject?.wsis.nodes?.length}/
                      {subProject?.wsis.pageInfo.totalElements} slides
                    </div>
                    {subProject?.overlayBy && (
                      <$OverlayByContainer>
                        <Tooltip
                          content={
                            <$InfoTooltip>
                              <$TooltipList>
                                <li>
                                  Block option matches for same association,
                                  case and block values provided during
                                  onboarding
                                </li>
                                <li>
                                  Section option matches for same association,
                                  case, block and section values provided during
                                  onboarding
                                </li>
                              </$TooltipList>
                            </$InfoTooltip>
                          }
                        >
                          <div style={{ cursor: 'pointer' }}>
                            <Icon icon={'AlertCircle'} color="black" />
                          </div>
                        </Tooltip>
                        <div>Show matching stainings by</div>
                        <>
                          <RadioButton
                            labelPosition="right"
                            name="block"
                            id="overlay-block"
                            label="Block"
                            checked={subProject?.overlayBy === 'block'}
                            onChange={() => {
                              setSubProjectOverlayBy('block');
                            }}
                            value={subProject?.overlayBy}
                          />
                          <RadioButton
                            labelPosition="right"
                            name="section"
                            id="overlay-section"
                            label="Section"
                            checked={subProject?.overlayBy === 'section'}
                            onChange={() => {
                              setSubProjectOverlayBy('section');
                            }}
                            value={subProject?.overlayBy}
                          />
                        </>
                      </$OverlayByContainer>
                    )}
                  </$CountContainer>

                  <Table borderSpacing={0}>
                    <TableHead>
                      <TableRow>
                        <TableHeader style={{ width: '48px' }} />
                        <TableHeader style={{ width: '25%' }}>
                          Slide name
                        </TableHeader>
                        <TableHeader style={{ width: '25%' }}>
                          Scanner
                        </TableHeader>
                        <TableHeader style={{ width: '25%' }}>
                          Staining
                        </TableHeader>
                        <TableHeader style={{ width: 0 }} colSpan={2}>
                          <EnableAllToggle
                            stainingsData={stainingsData}
                            switchAll={switchAll}
                          />
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!loading &&
                        subProject?.wsis.nodes.map((wsi) => {
                          return (
                            <EditStainingsWsiRow
                              subproject={subProject}
                              wsi={wsi}
                              key={wsi.id}
                              switchStaining={switchStaining}
                              isLoading={loading || switchingStainings}
                              organizationUuid={organizationUuid}
                              rasterTileServerUrl={rasterTileServerUrl}
                              getToken={getToken}
                              overlayMode={overlayBy}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                  {subProject &&
                  subProject.wsis?.pageInfo.totalElements > PAGE_SIZE &&
                  subProject.wsis?.pageInfo.totalPages > 1 ? (
                    <$PaginationContainer>
                      <Pagination
                        currentPage={page}
                        totalPages={subProject.wsis?.pageInfo.totalPages}
                        onPageChanged={setPage}
                      />
                    </$PaginationContainer>
                  ) : null}
                </>
              )}
            </>
          )}
        </$ContentWrapper>
      </Modal>
    </>
  );
};

export default EditStainingsModal;
