import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import type { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { OnboardingBatchActionButton } from './BatchListActionButton';

export const ActionCell = (
  props: CustomCellRendererProps<OnboardingBatchItem> & {
    onAssignToProject: () => void;
    refreshGrid: () => void;
  }
): ReactElement | null => {
  const batchItem = props.data;
  if (!batchItem) {
    return null;
  }
  const { id, status } = batchItem;
  return (
    <OnboardingBatchActionButton
      ariaLabel="Batch Action"
      batchId={id}
      batchStatus={status}
      onAssignToProject={props.onAssignToProject}
      refreshGrid={props.refreshGrid}
    />
  );
};
