import { OnboardingCloudFile } from '../FETCH_ONBOARDING_CLOUD_FILES';

export function getFileName(source: string): string {
  return source.split('/').pop() || '';
}

export function getFilePath(source: string): string {
  return source.replace(/^gs:\/\/[^\\/]+\//, '');
}

export function getFileBucketName(source: string): string {
  const regex = /^gs:\/\/([^\\/]+)/;
  const match = regex.exec(source);
  return match ? match[1] : '';
}

export type CloudFileInfo = {
  checksum: string;
  size: number;
  filename: string;
  source: string;
  type: 'remote';
  bucket: string;
  path: string;
};
export function getFilesInfoFromSource(
  files: OnboardingCloudFile[]
): CloudFileInfo[] {
  const filesInfo = files.map((file) => {
    const filePath = getFilePath(file.source);
    const fileBucketName = getFileBucketName(file.source);
    const fileName = getFileName(file.source);
    return {
      ...file,
      path: filePath,
      bucket: fileBucketName,
      filename: fileName,
    };
  });
  return filesInfo;
}
