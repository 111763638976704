import { AGGridTable, useDisclosure } from '@aignostics/components';
import { OrganizationRole, User } from '@aignostics/core';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { AssignBatchModal } from '../../../../components/OnboardingBatches/AssignBatchModal/AssignBatchModal.component';
import { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { Association } from '../../../../types';
import { getBatchListColumnDef } from '../../helpers';
import {
  useDataOnboardingFiltersOptions,
  useGridAPI,
  useServerSideBatchDatasource,
} from '../../hooks';

type BatchListProps = {
  currentUser: User;
  role: OrganizationRole;
  userAssignedAssociation: Pick<Association, 'name'> | null;
};
export function BatchList({
  role,
  currentUser,
  userAssignedAssociation,
}: BatchListProps): ReactElement {
  const [gridAPI, setGridAPI] = useGridAPI<OnboardingBatchItem>();

  const refreshGrid = useCallback(() => {
    if (gridAPI) {
      gridAPI.refreshServerSide();
    }
  }, [gridAPI]);

  const assignBatchModal = useDisclosure<string>();
  const filterOptions = useDataOnboardingFiltersOptions();

  const batchListColumnDef = useMemo(
    () =>
      getBatchListColumnDef({
        userAssignedAssociation,
        onAssignToProject: assignBatchModal.open,
        filterOptions,
        refreshGrid,
      }),
    [userAssignedAssociation, assignBatchModal.open, filterOptions, refreshGrid]
  );
  const batchListDatasource = useServerSideBatchDatasource();

  return (
    <>
      <AGGridTable
        suppressCsvExport
        columnDefs={batchListColumnDef}
        serverSideDatasource={batchListDatasource}
        onFirstDataRendered={setGridAPI}
      />
      <AssignBatchModal
        role={role}
        currentUser={currentUser}
        batchId={assignBatchModal.data}
        isOpen={assignBatchModal.isOpen}
        onCloseModal={assignBatchModal.close}
      />
    </>
  );
}
