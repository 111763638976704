import { Button } from '@aignostics/components';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement, useCallback } from 'react';
import { ACCEPTED_WSI_FILE_TYPES } from '../../../../../../utils/ACCEPTED_FILE_TYPES';
import { CloudFileInfo } from '../../../../utils/getFileInfoFromSource';
import { CloudAsset } from '../../../types/CloudAsset';

export type SelectSlideCellParams = CustomCellRendererProps<CloudAsset> & {
  selectedFiles: CloudFileInfo[];
  onSelectedFilesUpdate: (updatedSelectedFiles: CloudFileInfo[]) => void;
};
export const SelectSlideCell = ({
  data: cloudAsset,
  selectedFiles,
  onSelectedFilesUpdate,
}: SelectSlideCellParams): ReactElement | null => {
  const isFileSelected = useCallback(
    (file: CloudAsset) => selectedFiles.some((f) => f.path === file.path),
    [selectedFiles]
  );

  const onRemoveFile = useCallback(
    (file: CloudFileInfo) => {
      const updatedSelectedFiles = selectedFiles.filter(
        (f) => f.path !== file.path
      );
      onSelectedFilesUpdate(updatedSelectedFiles);
    },
    [selectedFiles, onSelectedFilesUpdate]
  );

  const onAddFile = useCallback(
    (file: CloudFileInfo) => {
      const updatedSelectedFiles = [...selectedFiles, file];
      onSelectedFilesUpdate(updatedSelectedFiles);
    },
    [selectedFiles, onSelectedFilesUpdate]
  );

  if (
    !(
      cloudAsset?.type === 'remote' &&
      ACCEPTED_WSI_FILE_TYPES.some((v) => cloudAsset.filename.endsWith(v))
    )
  ) {
    return null;
  }

  // if the cloudFile is selected, return a remove button
  if (isFileSelected(cloudAsset)) {
    return (
      <Button
        small
        variant="primaryOutline"
        aria-label={`${cloudAsset.filename} remove button`}
        onClick={() => {
          onRemoveFile(cloudAsset);
        }}
      >
        Remove
      </Button>
    );
  }

  // if the cloudFile is not selected, return an add button
  return (
    <Button
      small
      variant="primary"
      aria-label={`${cloudAsset.filename} add button`}
      onClick={() => {
        onAddFile(cloudAsset);
      }}
    >
      Add
    </Button>
  );
};
