import type {
  MultiChannelFormRow,
  PatientCaseIdPair,
  SingleChannelFormRow,
} from './form.state.types';
import { getFirstMatchingPairOrWsisGroup } from './getFirstMatchingPairOrWsisGroup';

/**
 * generates an array of unique patientId - caseName pairs from an array of slides
 * @param slides an array of <SingleChannelSlideFormRow | MultiChannelGroupedFormRows>
 * @returns      an array of unique PatientCaseIdPair type
 */

export const getUniquePatientCaseIdPairs = (
  slides: Array<SingleChannelFormRow | MultiChannelFormRow>
): PatientCaseIdPair[] =>
  slides.reduce((acc: PatientCaseIdPair[], slide) => {
    const matchingPair = getFirstMatchingPairOrWsisGroup(slide, acc);
    const { patientExternalId, caseName } =
      slide.type !== 'single-channel' ? slide.parentAssigned : slide;
    // TODO: [FE-5615]: Rename caseId to caseName in Onboarding API
    return matchingPair
      ? acc
      : [...acc, { patientExternalId, caseId: caseName }];
  }, []);
