import { Button } from '@aignostics/components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const ROW_HEIGHT = 28;

export const $AnnotationCategorySetNameInputWrapper = styled.div`
  flex: 4;
`;

export const $AnnotationCategorySetCodeInputWrapper = styled.div`
  flex: 1;
`;

export const $AnnotationCategorySetModulesBlockToggle = styled(Button)`
  background-color: transparent;
`;

export const $AnnotationCategorySetModulesBlockToggleText = styled.span`
  ${({ theme }) => theme.fontStyles.smallBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const $AnnotationCategorySetModulesBlock = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => `${theme.spacings[8]}px`};
  flex-direction: column;
  align-items: start;
`;

export const $AnnotationCategorySetFormFooter = styled.div`
  box-shadow: 0px 0px 24px 0px #0000001a;
  width: 100%;
  justify-content: start;
  padding: ${({ theme }) => `${theme.spacings[16]}px ${theme.spacings[32]}px`};
`;

export const $ButtonContainer = styled.div`
  height: ${ROW_HEIGHT}px;
`;
