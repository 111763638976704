import styled from 'styled-components';

export const $Card = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.large}px;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.large}px;
`;

export const $DrawingTool = styled.div`
  background-color: ${({ theme }) => theme.colors.lighter};
  padding: ${({ theme }) => `${theme.spacings.large}px`};
`;

export const $AnnotationsFeatureSelectContainer = styled.div`
  gap: ${({ theme }) => `${2 * theme.spacings.large}px`};
  max-width: ${({ theme }) => `${theme.breakpoints.FULL}px`};
`;

export const $NoAnnotationDrawingToolSelectedWarning = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.spacings[16]}px`};
  gap: 10px;
  align-self: stretch;
  border-radius: ${({ theme }) => `${theme.spacings[2]}px`};
  border: ${({ theme }) => `1px solid ${theme.colors.error}66`};
  background: ${({ theme }) => `${theme.colors.error}1a`};
  ${({ theme }) => theme.fontStyles.small};
`;
