import { gql } from '@apollo/client';

export const LIST_SUB_PROJECT_SLIDES = gql`
  query GET_REMOVE_WSIS_MODAL_SUBPROJECT_SLIDES(
    $subProjectId: ID!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
    $page: Int!
    $pageSize: Int!
    $annotations: WsiFilterType!
    $overlays: WsiFilterType!
    $annotatedBy: [ID]
    $annotationCategory: [ID]
  ) {
    subProject(id: $subProjectId) {
      id
      projectId
      wsisCount
      wsis(
        page: $page
        pageSize: $pageSize
        associations: $associations
        batches: $batches
        case: $case
        objectivePowers: $objectivePowers
        scanners: $scanners
        stainings: $stainings
        tissues: $tissues
        searchSlides: $search
        diseases: $diseases
        sortBy: name
        isAsc: true
        annotations: $annotations
        overlays: $overlays
        annotatedBy: $annotatedBy
        annotationCategory: $annotationCategory
      ) {
        nodes {
          id
          name
          regionsOfInterestCount
          staining
          scanner {
            id
            vendor
            model
          }
        }
        pageInfo {
          totalElements
          totalPages
        }
      }
    }
  }
`;
