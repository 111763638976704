import React, { Dispatch, ReactElement } from 'react';
import { SlidesMetadataEditor } from '../AgGridSetFileMetadataStep';
import {
  OnboardingCloudFileWithMetadata,
  RequestMetadataStep,
} from './RequestMetadataStep.component';
import { TransferFilesStep } from './TransferFiles.component';
import { CloudFileInfo } from './utils/getFileInfoFromSource';

interface RequestMetadataStep {
  name: 'requestMetadata';
  files: CloudFileInfo[];
  title: 'Requesting Metadata';
}

interface SetFileMetadataStep {
  files: OnboardingCloudFileWithMetadata[];
  name: 'setFileMetadata';
  title: 'Slides Metadata';
}

interface TransferSlidesStep {
  name: 'transferFiles';
  title: 'Transfer Slides';
  batchId: string;
}

export type TransferCloudSlideStep =
  | RequestMetadataStep
  | SetFileMetadataStep
  | TransferSlidesStep;
/**
 * Renders the current step of the upload slides wizard. Handles step changing.
 */
export function TransferCloudSlidesSteps({
  step,
  onStepChange,
  authToken,
  apiUrl,
}: {
  step: TransferCloudSlideStep;
  onStepChange: Dispatch<TransferCloudSlideStep>;
  authToken: string | null;
  apiUrl: string;
}): ReactElement {
  switch (step.name) {
    case 'requestMetadata': // 1 step
      return (
        <RequestMetadataStep
          files={step.files}
          onFetchedMetadata={(filesWithMetadata) => {
            onStepChange({
              files: filesWithMetadata,
              name: 'setFileMetadata',
              title: 'Slides Metadata',
            });
          }}
        />
      );

    case 'setFileMetadata': // 2 step
      return (
        <SlidesMetadataEditor
          apiUrl={apiUrl}
          authToken={authToken}
          files={step.files}
          submitLabel="transfer"
          onSuccessfulCreate={({ createOnboardingBatch: { batchId } }) => {
            onStepChange({
              name: 'transferFiles',
              title: 'Transfer Slides',
              batchId,
            });
          }}
          csv={undefined}
        />
      );

    case 'transferFiles': // 3 step
      return <TransferFilesStep batchId={step.batchId} />;
  }
}
