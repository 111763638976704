import { gql } from '@apollo/client';

export const FETCH_PROJECT_CREATORS = gql`
  query GET_PROJECT_CREATORS($isAdminView: Boolean!) {
    projects(isAdminView: $isAdminView) {
      collectionAttributes {
        users {
          id
          name
        }
      }
    }
  }
`;
