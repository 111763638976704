import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';
export const $DiscreteProgressBarContainer = styled(motion.div) `
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
`;
const pulsatingBackgroundFromLightToDarkKeyframes = ({ theme, }) => keyframes `
  0% {
    background-color: ${theme.colors.light};
  }
  50% {
    background-color: ${theme.colors.dark};
  }
  100% {
    background-color: ${theme.colors.light};
  }
`;
const pulsatingBackgroundFromLightToDarkAnimation = css `
  animation: ${pulsatingBackgroundFromLightToDarkKeyframes} 1s linear infinite;
`;
export const $DiscreteProgressBarTile = styled(motion.div) `
  min-width: 5px;
  width: 5px;
  height: 5px;

  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  border-radius: 1px;
  ${({ backgroundColor }) => backgroundColor === 'dark'
    ? pulsatingBackgroundFromLightToDarkAnimation
    : ''}
`;
