import styled from 'styled-components';
export const ONBOARDING_STATUS_ICON_ICON_SIZE = 32;
export const $OnboardingReadOnlyListItemIconWrapper = styled.div `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${ONBOARDING_STATUS_ICON_ICON_SIZE}px;
  height: ${ONBOARDING_STATUS_ICON_ICON_SIZE}px;
  background: ${({ theme }) => theme.colors.lighter};
  border-radius: 2px;
  flex-shrink: 0;
`;
