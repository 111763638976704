import { ApolloClient } from '@apollo/client';
import { checksumBtoa } from '../../../../utils/checksumBtoa';
import FETCH_ONBOARDING_CLOUD_FILES from '../../FETCH_ONBOARDING_CLOUD_FILES';
import { checksumToBinary } from '../../utils/checksumToBinary';
import { CloudFileInfo } from '../../utils/getFileInfoFromSource';
const BAD_CHECKSUM = 'BADCHKSM';

export type OnboardingSlidesBucket = {
  path: string;
  prefixes: string[];
  files: CloudFileInfo[];
};

export interface CloudFileInfoWithMrxsFile extends CloudFileInfo {
  missingMrxsFolderErrorMsg?: string;
}

export async function getMrxsFileSizeChecksum(
  client: ApolloClient<object>,
  file: CloudFileInfo
): Promise<CloudFileInfoWithMrxsFile> {
  const mrxsFolderPath = `${file.path.split('.mrxs')[0]}/`;

  const { data, error } = await client.query<{
    onboardingListCloudFiles: OnboardingSlidesBucket;
  }>({
    query: FETCH_ONBOARDING_CLOUD_FILES,
    variables: {
      bucket: file.bucket,
      path: mrxsFolderPath,
    },
  });

  if (error) {
    throw error;
  }

  const isMrxsFolderExist =
    data && data.onboardingListCloudFiles.files.length > 0;

  // if folder not found, return the original file
  if (!isMrxsFolderExist) {
    return {
      ...file,
      missingMrxsFolderErrorMsg: `Folder ${mrxsFolderPath} not found!`,
    };
  }

  // calculate the total size and checksum of the folder
  const totalSizeChecksum = data?.onboardingListCloudFiles.files.reduce(
    (acc: { size: number; checksum: string }, key: CloudFileInfo) => {
      const acc_checksum_numerical_val = checksumToBinary(acc.checksum);
      const key_checksum_numerical_val = checksumToBinary(key.checksum);

      const checksum =
        key_checksum_numerical_val && acc_checksum_numerical_val
          ? checksumBtoa(
              (acc_checksum_numerical_val + key_checksum_numerical_val) &
                0xffffffff
            )
          : BAD_CHECKSUM;

      return {
        size: Number(acc.size) + Number(key.size),
        checksum,
      };
    },
    {
      size: file.size,
      checksum: file.checksum,
    }
  );

  return {
    ...file,
    checksum: totalSizeChecksum.checksum,
    size: totalSizeChecksum.size,
  };
}
