import { Icon } from '@aignostics/components';
import { Theme } from '@aignostics/theme';
import styled from 'styled-components';

export const $OnboardingStatusCompletedWarningIcon = styled(Icon)`
  margin-left: ${({ theme }) => `${theme.spacings[8]}px`};
  cursor: pointer;
`;

export const $UploadedRatioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.tiny}px;
  justify-content: flex-end;
`;

export const $StatusMessage = styled.div<{ color: keyof Theme['colors'] }>`
  ${({ theme }) => theme.fontStyles.smallBold}
  color: ${({ theme, color }) => theme.colors[color]};
  display: flex;
  align-items: center;
  height: 100%;
`;
