import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { FETCH_ONBOARDING_BATCHES } from '../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import useOnboardingBatchesSearchParams from '../../../hooks/useOnboardingBatchesSearchParams';
import { BatchCreateFormFilled, CreateOnboardingBatchWsiRecord } from '../Form';

export interface UploadMetadata {
  url: string;
  bucketName: string;
  objectPath: string;
}

export type PairUploadLink = {
  filename: string;
  uploadLink: UploadMetadata;
};

export interface CreateOnboardingBatchMutationResult {
  createOnboardingBatch: {
    batchId: string;
    batchName: string;
    pairedUploadLinks: PairUploadLink[];
    wsiUuids: string[];
  };
}

type CreateOnboardingBatch = ({
  batch_name,
  association,
  species,
  wsis,
}: Pick<
  BatchCreateFormFilled,
  'batch_name' | 'association' | 'species' | 'metadataUsageRestriction'
> & {
  wsis: CreateOnboardingBatchWsiRecord[];
}) => Promise<CreateOnboardingBatchMutationResult>;

export const createOnboardingBatchMutation = gql`
  mutation CREATE_ONBOARDING_BATCH(
    $batch_name: String!
    $association: String!
    $species: String!
    $wsis: [CreateUploadBatchInput]!
    $onboardingType: String!
    $metadataUsageRestriction: String!
  ) {
    createOnboardingBatch(
      batch_name: $batch_name
      association: $association
      species: $species
      wsis: $wsis
      onboardingType: $onboardingType
      metadataUsageRestriction: $metadataUsageRestriction
    ) {
      batchId
      batchName
      pairedUploadLinks {
        filename
        uploadLink {
          url
          bucketName
          objectPath
        }
      }
      wsiUuids
    }
  }
`;

/**
 * Returns a function to upload files metadata to the onboarding db
 */
export const useCreateOnboardingBatch = ({
  onboardingType,
}: {
  onboardingType: 'local' | 'remote';
}): CreateOnboardingBatch => {
  const {
    page,
    pageSize,
    association,
    createdBy,
    uploadingDate,
    status,
    search,
  } = useOnboardingBatchesSearchParams();

  const [execute] = useMutation<
    CreateOnboardingBatchMutationResult,
    {
      batch_name: BatchCreateFormFilled['batch_name'];
      association: BatchCreateFormFilled['association'];
      species: BatchCreateFormFilled['species'];
      metadataUsageRestriction: BatchCreateFormFilled['metadataUsageRestriction'];
      wsis: CreateOnboardingBatchWsiRecord[];
      onboardingType: 'local' | 'remote';
    }
  >(createOnboardingBatchMutation, {
    refetchQueries: [
      {
        query: FETCH_ONBOARDING_BATCHES,
        variables: {
          page,
          pageSize,
          association,
          search,
          createdBy,
          uploadingDate,
          status,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  return useCallback<CreateOnboardingBatch>(
    ({ batch_name, association, species, wsis, metadataUsageRestriction }) =>
      execute({
        variables: {
          batch_name,
          association,
          species,
          wsis,
          onboardingType,
          metadataUsageRestriction,
        },
      }).then((value) => {
        if (value.data === undefined || value.data === null) {
          throw new Error('Null data received from onboarding data upload.');
        }
        return value.data;
      }),
    [execute, onboardingType]
  );
};
