import { Button, Checkbox, Flex, HStack, Input } from '@aignostics/components';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import * as uuid from 'uuid';

const $AdminSubProjectSlidesImportFormNotice = styled.div`
  border-radius: ${({ theme }) => `${theme.spacings[2]}px`};
  background: rgba(255, 188, 55, 0.2);
  padding: ${({ theme }) => `${theme.spacings[16]}px`};
  ${({ theme }) => theme.fontStyles.small};
`;

interface UpdateCopySlides {
  srcSubProjectId: string;
  dstSubProjectId: string;
  includeAnnotations?: boolean;
  includeAnnotationCategories?: boolean;
  includeROIs?: boolean;
  includeConfiguration?: boolean;
}

const ADMIN_SUBPROJECT_SLIDES_IMPORT_FORM_NOTICE = `Interactive and static overlays are globally saved per slide and
        automatically included when the slides are imported.`;

const AdminSubProjectSlidesImportForm = ({
  subprojectId,
  onSubmit,
  onCancel,
}: {
  subprojectId: string;
  onSubmit: (values: UpdateCopySlides) => void;
  onCancel: () => void;
}): ReactElement => {
  const [includeAnnotations, setIncludeAnnotations] = useState(false);
  const [includeAnnotationCategories, setIncludeAnnotationCategories] =
    useState(false);
  const [includeROIs, setIncludeROIs] = useState(false);
  const [includeConfiguration, setIncludeConfiguration] = useState(false);
  const [srcSubProjectId, setSrcSubProjectId] = useState('');

  const handleSubmit = () => {
    const values: UpdateCopySlides = {
      srcSubProjectId,
      dstSubProjectId: subprojectId,
      includeAnnotations,
      includeAnnotationCategories,
      includeROIs,
      includeConfiguration,
    };
    onSubmit(values);
  };

  return (
    <Flex direction="column" gapY="5" role="form">
      <Checkbox
        name="includeAnnotations"
        label="Include Annotations"
        checked={includeAnnotations}
        onCheckedChange={(checked: boolean) => {
          setIncludeAnnotations(checked);
        }}
      />

      <Checkbox
        label="Include Annotation Categories"
        checked={includeAnnotationCategories}
        onCheckedChange={(checked: boolean) => {
          setIncludeAnnotationCategories(checked);
        }}
      />
      <Checkbox
        label="Include Regions of Interest"
        checked={includeROIs}
        onCheckedChange={(checked: boolean) => {
          setIncludeROIs(checked);
        }}
      />
      <Checkbox
        label="Include Stainings/Fluorescence"
        checked={includeConfiguration}
        onCheckedChange={(checked: boolean) => {
          setIncludeConfiguration(checked);
        }}
      />

      <$AdminSubProjectSlidesImportFormNotice>
        {ADMIN_SUBPROJECT_SLIDES_IMPORT_FORM_NOTICE}
      </$AdminSubProjectSlidesImportFormNotice>
      <Input
        label="Subproject ID"
        id="srcSubProjectId"
        type="text"
        placeholder="00000000-0000-0000-0000-000000000000"
        value={srcSubProjectId}
        onChange={setSrcSubProjectId}
        required
      />
      <HStack spacing="8">
        <Button
          disabled={!uuid.validate(srcSubProjectId)}
          type="button"
          variant="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button type="button" variant="primaryOutline" onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </Flex>
  );
};

export default AdminSubProjectSlidesImportForm;
