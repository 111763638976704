import { gql } from '@apollo/client';

export const FETCH_SET_CODES = gql`
  query GET_SET_CODES {
    annotationCategorySets {
      nodes {
        code
      }
    }
  }
`;

export const FETCH_SET_NAMES = gql`
  query GET_SET_NAMES {
    annotationCategorySets {
      nodes {
        name
      }
    }
  }
`;

export const FETCH_MODULE_NAMES = gql`
  query GET_MODULE_NAMES {
    annotationCategorySets {
      nodes {
        modules {
          moduleName
        }
      }
    }
  }
`;
