import {
  Link,
  OnboardingReadOnlyListItemStatusIndicator,
  prettyFormatBytes,
} from '@aignostics/components';
import type { GroupCellRendererParams } from 'ag-grid-enterprise';
import React, { type ReactElement } from 'react';
import { slideHasOnlySuccessfulOnboardingStages } from '../../../../components/ReadOnly/utils';
import type { BatchesGridContext, GridDataBatches } from '../BatchesGrid.types';
import {
  $Filesize,
  $SlideName,
  $StatusIconPlaceholder,
  ICON_WRAPPER_STYLES,
} from './GroupCell.styles';

// GroupCellRendererParams, unlike ICellRendererParams, doesn't take context
// generic param :(
export type GroupCellProps = Omit<
  GroupCellRendererParams<GridDataBatches>,
  'context'
> & {
  context: BatchesGridContext;
};

const BatchEntryCell = ({ batchName }: { batchName: string }): ReactElement => (
  <span>{batchName}</span>
);

const SlideCell = ({
  data,
  organizationUuid,
}: {
  data: GridDataBatches;
  organizationUuid: string;
}): ReactElement => {
  // Should render status icon for every single channel slide, but for
  // multi channel only when it's in uploading or pending statuses.
  // That's because when it's onboarding, completed or failed, we will display
  // discrete progress bar with status on channels instead.
  const shouldRenderStatusIcon =
    data.type === 'single-channel' ||
    (data.type === 'multi-channel' &&
      (data.batchStatus === 'uploading' || data.batchStatus === 'pending'));

  return (
    <div>
      {shouldRenderStatusIcon ? (
        <OnboardingReadOnlyListItemStatusIndicator
          batchStatus={data.batchStatus}
          {...(data.onboardingProgress
            ? { onboardingProgress: data.onboardingProgress }
            : null)}
          style={ICON_WRAPPER_STYLES}
        />
      ) : (
        <$StatusIconPlaceholder />
      )}
      <$SlideName>
        {slideHasOnlySuccessfulOnboardingStages(data.slide!) ? (
          <Link href={`/${organizationUuid}/admin/wsi/${data.id}`}>
            {data.slide!.slideFile.filename}
          </Link>
        ) : (
          <span>{data.slide!.slideFile.filename}</span>
        )}
        <$Filesize>{prettyFormatBytes(data.slide!.slideFile.size)}</$Filesize>
      </$SlideName>
    </div>
  );
};

const ChannelCell = ({ data }: { data: GridDataBatches }): ReactElement => (
  <div>
    {data.batchStatus === 'pending' || data.batchStatus === 'uploading' ? (
      <$StatusIconPlaceholder />
    ) : (
      <OnboardingReadOnlyListItemStatusIndicator
        batchStatus={data.batchStatus}
        {...(data.onboardingProgress
          ? { onboardingProgress: data.onboardingProgress }
          : null)}
        style={ICON_WRAPPER_STYLES}
      />
    )}

    <span>{data.name}</span>
  </div>
);

export const GroupCell = ({
  data,
  context: { organizationUuid },
}: GroupCellProps): ReactElement | null => {
  if (!data) {
    return null;
  }

  if (data.type === 'batch-entry') {
    return <BatchEntryCell batchName={data.batchName} />;
  }

  if (
    (data.type === 'single-channel' || data.type === 'multi-channel') &&
    data.slide
  ) {
    return <SlideCell data={data} organizationUuid={organizationUuid} />;
  }

  if (data.type === 'channel') {
    return <ChannelCell data={data} />;
  }

  return null;
};
