import styled from 'styled-components';

const BARCODE_IMAGE_DIMENSIONS = '160px';
export const SLIDENAME_ICON_GAP = 10;

export const $SlideNameContainer = styled.div`
  display: flex;
  gap: ${SLIDENAME_ICON_GAP}px;
`;

export const $OnboardingBarcodeImageContainer = styled.div`
  position: absolute;
  width: 225px;
  min-height: 150px;
  z-index: 1;
  display: none;
  background: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings[4]}px;
  margin-left: 30px;
  margin-top: -5px;
  border-radius: 2px;
  border: 1px solid var(--aignx-greys-light, #ededed);
  background: var(--aignx-greys-white, #fff);
  /* shadow/banner */
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
`;

export const $IconContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
  justify-self: center;
`;

export const $TextContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-self: center;
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
`;

export const $SlideNameTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[12]}px;
  padding: ${({ theme }) => theme.spacings[12]}px;
  border-radius: ${({ theme }) => theme.spacings[2]}px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid var(--aignx-greys-light, #ededed);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
`;
export const $BarcodeImageContainer = styled.img`
  width: ${BARCODE_IMAGE_DIMENSIONS};
  height: ${BARCODE_IMAGE_DIMENSIONS};
`;

export const $SlideName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  max-width: 100%;
`;

export const $CsvLine = styled.span`
  color: #aaaaaa;
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;
