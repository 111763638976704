import {
  getAgGridPaginationConfig,
  AGGridTable as Table,
} from '@aignostics/components';
import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client';
import {
  ColDef,
  FilterModel,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  TextFilterModel,
} from 'ag-grid-enterprise';
import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  AnnotationCategorySet,
  AnnotationCategorySetModule,
} from '../../../types';
import { AnnotationCategorySetsTableRowActions } from './AnnotationCategorySetsTableRowActions.component';
import {
  FETCH_ANNOTATION_CATEGORY_SET_MODULES,
  FETCH_ANNOTATION_CATEGORY_SETS,
} from './FETCH_ANNOTATION_CATEGORY_SETS';

const $TableLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
export interface AnnotationCategorySetsTableProps {
  annotationCategorySetCodes: string[];
  annotationCategorySetNames: string[];
  onRefresh?: (refreshFn: () => void) => void;
}

const generateGridFilterLink = ({
  setName,
  setCode,
  modules,
}: {
  setName?: string;
  setCode?: string;
  modules?: string[];
}): string => {
  const filterModel: Record<string, { values: string[]; filterType: 'set' }> =
    {};

  if (setName) {
    filterModel.setName = {
      values: [setName],
      filterType: 'set',
    };
  }

  if (setCode) {
    filterModel.setCode = {
      values: [setCode],
      filterType: 'set',
    };
  }

  if (modules) {
    filterModel.setModule = {
      values: modules,
      filterType: 'set',
    };
  }

  const base = `categories`;

  const params = new URLSearchParams();

  if (Object.keys(filterModel).length > 0) {
    params.set('filter', JSON.stringify({ filterModel }));
  }

  const queryString = params.toString();
  return queryString ? `${base}?${queryString}` : base;
};

type AnnotationSetsAndModulesData = {
  id: string;
  name: string;
  code: string;
  moduleCount: number;
  categoryCount: number;
  actions: string;
  moduleName?: string;
  modules?: AnnotationCategorySetModule[];
  level: number;
};

export const createServerSideDatasource = (
  client: ApolloClient<object>,
  queryDocument: DocumentNode
): IServerSideDatasource => {
  return {
    getRows: async (
      params: IServerSideGetRowsParams<AnnotationSetsAndModulesData>
    ) => {
      try {
        const {
          startRow: maybeStartRow,
          endRow: maybeEndRow,
          sortModel,
          groupKeys,
          filterModel,
        } = params.request;

        const { page, pageSize } = getAgGridPaginationConfig(
          maybeStartRow,
          maybeEndRow
        );

        const fm = filterModel as FilterModel;

        const extractTextFilter = (model?: TextFilterModel): string => {
          return model?.filterType === 'text' ? model.filter ?? '' : '';
        };

        const search = extractTextFilter(fm['ag-Grid-AutoColumn']);

        const variables = {
          page,
          pageSize,
          sortBy:
            sortModel?.[0]?.colId === 'ag-Grid-AutoColumn'
              ? 'name'
              : sortModel?.[0]?.colId || 'name',
          sortDirection: sortModel?.[0]?.sort || 'asc',
          search,
        };

        if (groupKeys && groupKeys.length > 0) {
          const { data, error } = await client.query({
            query: FETCH_ANNOTATION_CATEGORY_SET_MODULES,
            variables: { annotationCategorySetIds: groupKeys },
            fetchPolicy: 'network-only',
          });

          if (error) {
            params.fail();
            return;
          }

          const parentSet = data?.annotationCategorySetsByIds?.nodes.find(
            (set: AnnotationCategorySet) => set.id === groupKeys[0]
          );
          if (!parentSet?.modules) {
            params.success({ rowData: [], rowCount: 0 });
            return;
          }

          const moduleRows = parentSet.modules.map(
            (module: AnnotationCategorySetModule) => {
              return {
                name: parentSet.name,
                code: parentSet.code,
                moduleName: module.moduleName,
                categoryCount: module.categoryCount,
                level: 1,
              };
            }
          );

          params.success({
            rowData: moduleRows,
            rowCount: moduleRows.length,
          });
          return;
        }

        const { data, error } = await client.query({
          query: queryDocument,
          variables,
          fetchPolicy: 'network-only',
        });

        if (error) {
          params.fail();
          return;
        }

        const rawData =
          data?.annotationCategorySets?.nodes.map(
            (annotationCategorySet: AnnotationCategorySet) => ({
              ...annotationCategorySet,
              level: 0,
            })
          ) ?? [];

        const totalElements =
          data?.annotationCategorySets?.pageInfo?.totalElements ??
          rawData.length;

        params.success({ rowData: rawData, rowCount: totalElements });
      } catch {
        params.fail();
      }
    },
  };
};

const CategoriesCellRenderer = (
  props: CustomCellRendererProps<AnnotationSetsAndModulesData, string>
) => {
  const { data } = props;
  const moduleNames = data?.moduleName
    ? [data.moduleName]
    : data?.modules?.map((m) => m.moduleName);

  const href = generateGridFilterLink({
    setName: data?.name,
    setCode: data?.code,
    modules: moduleNames,
  });

  return <$TableLink to={href}>{data?.categoryCount ?? '0'}</$TableLink>;
};

const ModuleNameCellRenderer = (
  props: CustomCellRendererProps<AnnotationSetsAndModulesData, string>
) => {
  const { data } = props;
  if (data?.level === 1) {
    return <span>{data?.moduleName}</span>;
  }
  return <span>{data?.moduleCount}</span>;
};

export const AnnotationCategorySetsTable = ({
  annotationCategorySetCodes,
  annotationCategorySetNames,
  onRefresh,
}: AnnotationCategorySetsTableProps): ReactElement => {
  const client = useApolloClient();
  const datasource = useMemo(
    () => createServerSideDatasource(client, FETCH_ANNOTATION_CATEGORY_SETS),
    [client]
  );

  const isServerSideGroup = useCallback((dataItem: AnnotationCategorySet) => {
    return (dataItem?.moduleCount || 0) > 0;
  }, []);

  const getServerSideGroupKey = useCallback(
    (dataItem: AnnotationCategorySet) => {
      return dataItem.id;
    },
    []
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const handleGridReady = useCallback(
    (params: GridReadyEvent<AnnotationSetsAndModulesData>) => {
      setGridApi(params.api);

      if (onRefresh) {
        const refreshFunction = () => {
          params.api.refreshServerSide({ route: [], purge: true });
        };
        onRefresh(refreshFunction);
      }
    },
    [onRefresh]
  );

  const refreshGrid = useCallback(() => {
    if (gridApi) {
      gridApi.refreshServerSide({ route: [], purge: true });
    }
  }, [gridApi]);

  const columnDefs = useMemo(
    () =>
      [
        {
          field: 'code',
          headerName: 'Set Identifier',
          sortable: true,
          cellRenderer: (
            props: CustomCellRendererProps<AnnotationSetsAndModulesData, string>
          ) => {
            const { data } = props;
            if (data?.level === 1 || !data) return null;
            return data.code;
          },
        },
        {
          field: 'moduleCount',
          headerName: 'Modules',
          cellRenderer: ModuleNameCellRenderer,
        },
        {
          field: 'categoryCount',
          headerName: 'Categories',
          cellRenderer: CategoriesCellRenderer,
          sortable: true,
        },

        {
          field: 'actions',
          headerName: 'Actions',
          cellRenderer: (
            props: CustomCellRendererProps<AnnotationSetsAndModulesData, string>
          ) => {
            const { data, context } = props;
            if (data?.level === 1 || !data) return null;
            return (
              <AnnotationCategorySetsTableRowActions
                annotationCategorySet={
                  {
                    id: data.id,
                    code: data.code,
                    name: data.name,
                    categoryCount: data.categoryCount,
                    modules: data.modules,
                  } as AnnotationCategorySet
                }
                annotationCategorySetCodes={context.annotationCategorySetCodes}
                annotationCategorySetNames={context.annotationCategorySetNames}
                onRefresh={refreshGrid}
              />
            );
          },
          sortable: false,
        },
      ] satisfies ColDef<AnnotationSetsAndModulesData>[],
    [refreshGrid]
  );

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      colId: 'name',
      headerName: 'Annotation Set',
      field: 'name',
      valueGetter: (params) => {
        if (params.node?.level === 1) {
          return;
        }
        return params.data?.name;
      },
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    }),
    []
  );

  return (
    <Table
      columnDefs={columnDefs}
      serverSideDatasource={datasource}
      context={{
        annotationCategorySetCodes,
        annotationCategorySetNames,
      }}
      onGridReady={handleGridReady}
      treeData
      isServerSideGroup={isServerSideGroup}
      getServerSideGroupKey={getServerSideGroupKey}
      autoGroupColumnDef={autoGroupColumnDef}
    />
  );
};
