import {
  OneLiner,
  Tooltip,
  useSnackbarMutations,
} from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Copy from '../../assets/icons/copy.svg';
import { Tagger } from '../types';
import { StageIndicator } from './StageIndicator.component';

interface TiltedHeaderProps {
  stage?: Tagger['stage'];
  title: string;
  subTitle?: string;
  onClick?: () => void;
}

const $TiltedHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: ${({ theme }) => theme.spacings.button}px;
  transform: rotate(-45deg);
  cursor: ${(props) => (!props.onClick ? 'auto' : 'pointer')};
  background-color: transparent;
`;

const $TiltedHeaderLine = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings[4]}px;
  align-items: center;
  cursor: url(${Copy}), auto; // Use imported SVG and include fallback
  height: 18px;
  max-width: 330px;
  position: absolute;
  &:nth-child(1) {
    color: ${({ theme }) => theme.colors.text};
    ${({ theme }) => theme.fontStyles.smallBold};
  }

  &:nth-child(2) {
    position: absolute;
    color: ${({ theme }) => theme.colors.mid};
    transform: translate(30px, 12px);
    ${({ theme }) => theme.fontStyles.small};
  }
`;

const snackbarText = (text: string) => {
  return `Copied "${text}" to clipboard`;
};

const TiltedHeader = ({
  stage,
  title,
  subTitle,
}: TiltedHeaderProps): ReactElement => {
  const { addSnackbar } = useSnackbarMutations();

  const onTitleClick = async () => {
    await navigator.clipboard.writeText(title);
    addSnackbar({
      message: snackbarText(title),
      type: 'success',
      closesAfter: 3000,
    });
  };

  const onSubTitleClick = async () => {
    if (subTitle) {
      await navigator.clipboard.writeText(subTitle).then(() => {
        addSnackbar({
          message: snackbarText(subTitle),
          type: 'success',
          closesAfter: 3000,
        });
      });
    }
  };
  return (
    <$TiltedHeader>
      <$TiltedHeaderLine onClick={onTitleClick}>
        {stage ? (
          <Tooltip content={stage}>
            <StageIndicator stage={stage} />
          </Tooltip>
        ) : null}
        <OneLiner>{title}</OneLiner>
      </$TiltedHeaderLine>
      {subTitle && (
        <$TiltedHeaderLine onClick={onSubTitleClick}>
          <OneLiner>{subTitle}</OneLiner>
        </$TiltedHeaderLine>
      )}
    </$TiltedHeader>
  );
};

export default TiltedHeader;
