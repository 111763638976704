import React, { ReactElement } from 'react';

import type { CustomTooltipProps } from 'ag-grid-react';
import useGetBarcodeImageUrl from '../../../../hooks/useGetBarcodeImageUrl';
import { SingleOrMultiChannel } from '../MetadataGrid.types';
import {
  $BarcodeImageContainer,
  $SlideNameTooltipContainer,
} from './CustomCellSlideName.styles';

export function SlideNameTooltip(
  props: CustomTooltipProps<SingleOrMultiChannel>
): ReactElement | null {
  const { data, context } = props;
  const { barcodeImageUrl } = useGetBarcodeImageUrl(
    data?.slideFile?.source ?? null,
    context.organizationUuid,
    context.apiUrl,
    context.authToken
  );

  // When file name is not available return null
  if (!data?.slideFile?.filename) {
    return null;
  }

  return (
    <$SlideNameTooltipContainer>
      <p>{data.slideFile.filename}</p>
      {barcodeImageUrl && (
        <$BarcodeImageContainer
          src={barcodeImageUrl}
          alt={data.slideFile.filename}
        />
      )}
    </$SlideNameTooltipContainer>
  );
}
