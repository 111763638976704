import { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import type { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';

export const BatchNameCell = (
  props: CustomCellRendererProps<OnboardingBatchItem>
): ReactElement | null => {
  const batchItem = props.data;
  if (!batchItem) {
    return null;
  }
  const { id, batchName } = batchItem;
  return <Link to={`batch/${id}`}>{batchName}</Link>;
};
