import { IconButton } from '@aignostics/components';
import { gql, useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';

export const FETCH_USER_MANUAL = gql`
  query GET_USER_MANUAL {
    userManual {
      link
    }
  }
`;

export const UserManualLink = (): ReactElement => {
  const { data, loading, error } = useQuery<{
    userManual: {
      link: string;
    };
  }>(FETCH_USER_MANUAL);

  const link = data?.userManual.link;
  const description = loading
    ? 'User manual link is loading...'
    : error
      ? 'User manual link is not available.'
      : 'Open User Manual in new tab.';

  return (
    <div>
      <IconButton
        icon="Info"
        aria-label="User manual link"
        disabled={loading || !link}
        description={description}
        onClick={() => {
          if (link) {
            window.open(link, '_blank');
          }
        }}
      />
    </div>
  );
};
