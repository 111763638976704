import {
  Grid,
  List,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@aignostics/components';
import React, { ComponentType, ReactNode, useState } from 'react';

export type LayoutMode = 'Grid' | 'List';

export function useLayoutMode(
  initialLayoutMode?: LayoutMode,
  id?: string
): {
  LayoutContainer: ComponentType<{ children: ReactNode }>;
  LayoutSelector: ComponentType<
    Pick<ToggleButtonGroupProps, 'name' | 'label' | 'id'>
  >;
  layoutMode: LayoutMode;
} {
  const [layoutMode, setLayoutMode] = useState<LayoutMode>(() => {
    if (id) {
      const value = localStorage.getItem(id);
      return (value ?? initialLayoutMode ?? 'List') as LayoutMode;
    }
    return initialLayoutMode ?? 'List';
  });

  return {
    LayoutSelector: (props) => (
      <ToggleButtonGroup
        value={layoutMode}
        onChange={(value) => {
          setLayoutMode(value as LayoutMode);
          if (id) localStorage.setItem(id, value);
        }}
        options={[
          { value: 'List', icon: 'List' },
          { value: 'Grid', icon: 'Grid' },
        ]}
        {...props}
      />
    ),
    LayoutContainer: layoutMode === 'Grid' ? Grid : List,
    layoutMode,
  };
}
