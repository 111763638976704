import type { CustomCellRendererProps } from 'ag-grid-react';
import React, { type ReactElement } from 'react';
import styled from 'styled-components';
import type { GridData } from '../MetadataGrid.types';
import { SLIDENAME_ICON_GAP } from './CustomCellSlideName.styles';

const $ChannelName = styled.span`
  padding-left: ${({ theme }) => theme.spacings.line + SLIDENAME_ICON_GAP}px;
`;

export const CustomCellChannelName = (
  props: CustomCellRendererProps<GridData>
): ReactElement | null => {
  if (props.data?.type !== 'channel') {
    return null;
  }

  return <$ChannelName>{props.data.name}</$ChannelName>;
};
