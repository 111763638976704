import type { CustomCellRendererProps } from 'ag-grid-react';
import React, { ReactElement } from 'react';
import { ACCEPTED_WSI_FILE_TYPES } from '../../../../../../utils/ACCEPTED_FILE_TYPES';
import { $PillDefault, $PillPointer } from '../../../CloudSlideList.styles';
import { CloudAsset } from '../../../types/CloudAsset';

export type SlideNameCellParams = CustomCellRendererProps<CloudAsset> & {
  onPathUpdate: (path: string) => void;
};
export function SlideNameCell({
  data: cloudAsset,
  onPathUpdate,
}: SlideNameCellParams): ReactElement | null {
  if (!cloudAsset) {
    return null;
  }

  // In case of file
  if (cloudAsset.type === 'remote') {
    const icon = ACCEPTED_WSI_FILE_TYPES.some((fileType) =>
      cloudAsset.filename.endsWith(fileType)
    )
      ? 'Image'
      : 'FileText';
    return (
      <$PillDefault
        icon={icon}
        text={cloudAsset.filename}
        variant="white"
        aria-label={`${cloudAsset.filename} File`}
      />
    );
  }
  // In case of folder
  return (
    <$PillPointer
      icon="Folder"
      aria-label={`${cloudAsset.filename} Folder`}
      text={cloudAsset.filename}
      variant="regular"
      onClick={() => {
        onPathUpdate(cloudAsset.path);
      }}
    />
  );
}
