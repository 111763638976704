import { FilterConfigs } from '@aignostics/components';

export type FilterKeysSlidesList =
  | 'filterAnnotations'
  | 'filterOverlays'
  | 'filterAnnotatedBy'
  | 'filterAnnotationCategory';

export type FilterKeysWithoutAssociation =
  | 'searchWsis'
  | 'batches'
  | 'tissues'
  | 'stainings'
  | 'scanners'
  | 'objectivePowers'
  | 'caseId'
  | 'diseases';

export type FilterKeysWithoutAssociationWithSlidesFilter =
  | FilterKeysWithoutAssociation
  | FilterKeysSlidesList;

export type FilterKeysWithAssociation =
  | FilterKeysWithoutAssociation
  | 'associations';

export type FilterKeysWithAssociationWithSlidesFilter =
  | FilterKeysWithoutAssociationWithSlidesFilter
  | 'associations';

export const PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION: FilterConfigs<FilterKeysWithoutAssociation> =
  {
    searchWsis: { fallbackValue: '', type: 'string' },
    batches: { fallbackValue: [], type: 'array' },
    tissues: { fallbackValue: [], type: 'array' },
    stainings: { fallbackValue: [], type: 'array' },
    scanners: { fallbackValue: [], type: 'array' },
    objectivePowers: { fallbackValue: [], type: 'array' },
    caseId: { fallbackValue: '', type: 'string' },
    diseases: { fallbackValue: [], type: 'array' },
  };

export const PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST: FilterConfigs<FilterKeysWithoutAssociationWithSlidesFilter> =
  {
    ...PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION,
    filterAnnotatedBy: { fallbackValue: [], type: 'array' },
    filterAnnotationCategory: { fallbackValue: [], type: 'array' },
    filterAnnotations: { fallbackValue: 'all', type: 'string' },
    filterOverlays: { fallbackValue: 'all', type: 'string' },
  };

export const PAGE_FILTER_CONFIG_WITH_ASSOCIATION: FilterConfigs<FilterKeysWithAssociation> =
  {
    ...PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION,
    associations: { fallbackValue: [], type: 'array' },
  };

export const PAGE_FILTER_CONFIG_WITH_ASSOCIATION_WITH_SLIDES_LIST: FilterConfigs<FilterKeysWithAssociationWithSlidesFilter> =
  {
    ...PAGE_FILTER_CONFIG_WITHOUT_ASSOCIATION_WITH_SLIDES_LIST,
    associations: { fallbackValue: [], type: 'array' },
  };
