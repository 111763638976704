/** Commonly accepted WSI file extensions in Data Onboarding. */
const COMMONLY_ACCEPTED_FILE_TYPES = [
  '.tif',
  '.tiff',
  '.svs',
  '.vms',
  '.vmu',
  '.ndpi',
  '.scn',
  '.svslide',
  '.bif',
  '.qptiff',
  '.qptif',
  '.mrxs',
  '.dcm',
  '.zip',
] as const;

/** Accepted WSI file extensions in the cloud transfer. */
export const ACCEPTED_WSI_FILE_TYPES = [
  ...COMMONLY_ACCEPTED_FILE_TYPES,
  '.czi',
] as const;

/** Accepted WSI file extensions for the local upload. */
export const ACCEPTED_FILE_TYPES = [
  'image/tif',
  ...COMMONLY_ACCEPTED_FILE_TYPES,
  '.csv',
] as const;
