import {
  HStack,
  Icon,
  Placeholder,
  PopUp,
  SortableTableHeader,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  useDisclosure,
  useSnackbarMutations,
} from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import { IconKey } from '@aignostics/theme';
import { ApolloCache, DefaultContext, useMutation } from '@apollo/client';
import React, { ReactElement } from 'react';
import { CSSProperties } from 'styled-components';
import { WsiMetadataModal } from '../../../../../components';
import { SortBy, getSortDirection } from '../../../../../hooks';
import { Wsi } from '../../../../../types';
import { SortByOptions } from '../../../Subproject.types';
import { ToggleStainingAction } from '../EditStainingsModal/useEditStainings';
import {
  $TableHeaderText,
  $TableHeaderWithIcon,
} from './AssignedSlidesTable.styles';
import { GET_AVAILABLE_COEFFICIENTS } from './GET_AVAILABLE_COEFFICIENTS';
import { SET_CHILD_SLIDE_PARAMS_MUTATION } from './SET_CHILD_SLIDE_PARAMS_MUTATION';
import { AssignedSlidesTableSkeleton } from './components/AssignedSlidesTableSkeleton';
import { WsiRow } from './components/WsiRow';
import { updateCache } from './updateStainingVisibleCache';

export type CoefficientChangeModalState = {
  wsi: string;
  coefficient: string;
  onConfirm: () => Promise<void>;
};

export type OnSetCoefficient = (
  reference: string,
  origin: string,
  registration: string,
  active: boolean,
  registrationLabel: string,
  type: 'staining' | 'Fluorescence'
) => void;

type TableHeaders = {
  name: string;
  sortingBy?: SortByOptions;
  width?: CSSProperties['width'];
  icon?: IconKey;
  ariaLabel?: string;
};

export function AssignedSlidesTable({
  subprojectId,
  wsis,
  showMore = false,
  getWsiHref,
  setSortBy,
  loading,
  sortBy,
  pageSize,
  role,
  rasterTileServerUrl,
  getToken,
}: {
  subprojectId: string;
  wsis: Wsi[];
  showMore?: boolean;
  getWsiHref: (wsiUuid: string) => string;
  setSortBy: (sortByOption: SortByOptions) => void;
  loading: boolean;
  sortBy: SortBy<SortByOptions> | null;
  pageSize: number;
  role: OrganizationRole;
  getToken: () => Promise<string>;
  rasterTileServerUrl: string;
}): ReactElement {
  const infoModalDisclosure = useDisclosure<Wsi>(false);

  const coefficientChangeModal =
    useDisclosure<CoefficientChangeModalState>(false);

  const { addSnackbar } = useSnackbarMutations();

  const [setVisible, { loading: setActionLoading }] = useMutation<
    void,
    { subprojectId: string; toggleStainingActions: ToggleStainingAction[] },
    DefaultContext,
    ApolloCache<unknown>
  >(SET_CHILD_SLIDE_PARAMS_MUTATION, {
    onCompleted: () => {
      addSnackbar({
        message: 'Image registration coefficient successfully updated.',
        type: 'success',
      });
    },
    onError: (error) => {
      addSnackbar({ message: error.message, type: 'error', closesAfter: 0 });
    },
  });

  const onSetCoefficient: OnSetCoefficient = (
    reference: string,
    origin: string,
    registration: string,
    active: boolean,
    registrationLabel: string,
    type: 'staining' | 'Fluorescence'
  ) => {
    coefficientChangeModal.open({
      wsi: origin,
      coefficient: registrationLabel,
      onConfirm: async () => {
        await setVisible({
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_AVAILABLE_COEFFICIENTS,
              variables: {
                subProjectId: subprojectId,
                wsiId: reference,
              },
            },
          ],
          variables: {
            subprojectId,
            toggleStainingActions: [
              {
                stainingUuid: origin,
                baseWsiUuid: reference,
                active,
                registration,
              },
            ],
          },
          update: updateCache(
            [
              {
                stainingUuid: origin,
                baseWsiUuid: reference,
                active,
                registration,
              },
            ],
            [type]
          ),
        });
      },
    });
  };

  const renderWsiRow = (wsi: Wsi, sortOrder: number) => (
    <WsiRow
      key={wsi.id}
      wsi={wsi}
      subprojectId={subprojectId}
      infoModalDisclosure={infoModalDisclosure}
      showMore={showMore}
      to={getWsiHref(wsi.id)}
      onSetCoefficient={onSetCoefficient}
      sortOrder={sortOrder}
      rasterTileServerUrl={rasterTileServerUrl}
      getToken={getToken}
    />
  );

  if (!loading && wsis.length === 0) {
    return (
      <HStack>
        <Placeholder
          title="No slides"
          description="There are no slides matching the current filters"
        />
      </HStack>
    );
  }

  const onSortingClick = (sortingBy: SortByOptions | undefined) => {
    if (!loading && sortingBy) setSortBy(sortingBy);
  };

  const TABLE_HEADERS: TableHeaders[] = [
    { name: 'Slide', sortingBy: 'name', width: '25ch' },
    {
      name: 'Info',
      icon: 'FileText',
      ariaLabel: 'Info metadata',
    },
    {
      name: 'Region of interest',
      icon: 'RegionOfInterest',
      sortingBy: 'regionOfInterest',
    },
    {
      name: 'Brightfield',
      sortingBy: 'brightField',
      icon: 'BrightField',
    },
    { name: 'Fluorescence', icon: 'Fluorescence', sortingBy: 'fluorescence' },
    {
      name: 'Annotations',
      sortingBy: 'annotations',
      icon: 'Annotations',
    },
    {
      name: 'Interactive Overlays',
      sortingBy: 'taggers',
      icon: 'InteractiveOverlays',
    },
    {
      name: 'Static Overlays',
      sortingBy: 'overlays',
      icon: 'StaticOverlays',
    },
  ];

  return (
    <>
      <Table borderSpacing={0} style={{ alignSelf: 'baseline' }}>
        <TableHead>
          <TableRow>
            {TABLE_HEADERS.map((header) => {
              if (header.sortingBy) {
                return (
                  <SortableTableHeader
                    fixedWidth={header.width}
                    isDisabled={loading}
                    key={header.name}
                    icon={header.icon}
                    sortDirection={getSortDirection(sortBy, header.sortingBy)}
                    onClick={() => {
                      onSortingClick(header.sortingBy);
                    }}
                  >
                    {header.name}
                  </SortableTableHeader>
                );
              } else {
                return (
                  <TableHeader align="center" key={header.name}>
                    <$TableHeaderWithIcon aria-label={header.ariaLabel}>
                      {header.icon && <Icon icon={header.icon} size="button" />}
                      <$TableHeaderText>{header.name}</$TableHeaderText>
                    </$TableHeaderWithIcon>
                  </TableHeader>
                );
              }
            })}

            {showMore && (
              <TableHeader style={{ maxWidth: '120px', minWidth: '120px' }} />
            )}
          </TableRow>
        </TableHead>
        {!loading ? (
          <TableBody>
            {wsis.map((wsi, index) => renderWsiRow(wsi, index + 1))}
          </TableBody>
        ) : null}
      </Table>
      {loading && <AssignedSlidesTableSkeleton rows={pageSize} />}
      {infoModalDisclosure.data ? (
        <WsiMetadataModal
          isOpen={infoModalDisclosure.isOpen}
          wsiId={infoModalDisclosure.data.id}
          onClose={infoModalDisclosure.close}
          subProjectId={subprojectId}
          role={role}
          rasterTileServerUrl={rasterTileServerUrl}
          getToken={getToken}
        />
      ) : null}
      <PopUp
        isVisible={coefficientChangeModal.isOpen}
        title={'Image registration'}
        onClose={coefficientChangeModal.close}
        shouldCloseOnBackdropClick={true}
        primaryAction={{
          label: 'Continue',
          onClick: async () => {
            await coefficientChangeModal.data?.onConfirm();
            coefficientChangeModal.close();
          },
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: coefficientChangeModal.close,
        }}
        isLoading={setActionLoading}
      >
        <p style={{ margin: '0' }}>
          {`You will be applying image registration coefficient ${coefficientChangeModal.data?.coefficient}. Are you sure?`}
        </p>
      </PopUp>
    </>
  );
}
