import { PageLayout, VisibleWithScope } from '@aignostics/components';
import { OrganizationRole } from '@aignostics/core';
import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelectedOrganizationUuid } from '../utils/useSelectedOrganizationUuid';
import {
  TransferCloudSlidesSteps,
  TransferCloudSlideStep,
} from './TransferCloudSlides/TransferCloudSlidesSteps.component';

type AdminTransferCloudSlidesProps = {
  role: OrganizationRole;
  authToken: string | null;
  apiUrl: string;
};
/**
 * Page for admins to select Slide ( WSI ) files from a cloud bucket and start the slide onboarding process.
 */
export default function AdminTransferCloudSlides({
  role,
  authToken,
  apiUrl,
}: AdminTransferCloudSlidesProps): ReactElement {
  const location = useLocation();
  const organizationUuid = useSelectedOrganizationUuid();

  const files = location.state?.files;
  const [step, onStepChange] = useState<TransferCloudSlideStep>({
    name: 'requestMetadata',
    files,
    title: 'Requesting Metadata',
  });

  if (!files) {
    return (
      <Navigate
        to={`/${organizationUuid}/admin/data-onboarding`}
        state={{ shouldOpenDataUploadModal: true }}
      />
    );
  }

  return (
    <PageLayout
      title={step.title}
      description="Please provide information about the slides (you can use the table header to add global values). You may also include a CSV file containing prefilled fields."
      fullWidth
    >
      <VisibleWithScope role={role} scope="wsi:upload">
        <TransferCloudSlidesSteps
          apiUrl={apiUrl}
          step={step}
          onStepChange={onStepChange}
          authToken={authToken}
        />
      </VisibleWithScope>
    </PageLayout>
  );
}
