import {
  Button,
  Icon,
  Link,
  TableCell,
  TableRow,
  TableSubRowSkeleton,
  Tooltip,
} from '@aignostics/components';
import { buildClientWsiUrl } from '@aignostics/utils';
import { useLazyQuery } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement, useState } from 'react';
import { WsiThumbnail } from '../../../../../../components';
import { OverlayByType, SubProject, Wsi } from '../../../../../../types';
import { GET_RELATED_LAYERS } from '../../AssignedSlidesTable/GET_RELATED_LAYERS';
import {
  $ThumbnailWrapper,
  $WarningTooltip,
  $WsiRowRightContainer,
} from '../EditStainingsModal.styles';
import { EditStainingsFluorescenceRow } from './EditStainingsFluorescenceRow';
import { EditStainingsStainingRow } from './EditStainingsStainingRow';

export const EditStainingsWsiRow = ({
  wsi,
  subproject,
  switchStaining,
  isLoading,
  getToken,
  rasterTileServerUrl,
  organizationUuid,
  overlayMode,
}: {
  subproject: SubProject;
  wsi: Wsi;
  switchStaining: (wsiId: string, originType: string, value: boolean) => void;
  isLoading: boolean;
  rasterTileServerUrl: string;
  getToken: () => Promise<string>;
  organizationUuid: string;
  overlayMode: OverlayByType | undefined;
}): ReactElement => {
  const [showStainings, setShowStainings] = useState(false);

  const [getLayers, { data: stainingsData, loading: loadingStainingsData }] =
    useLazyQuery<{
      relatedLayers: {
        stainings: {
          id: string;
          name: string;
          isActive: boolean;
          stainingName: string;
          scanner: {
            id: string;
            vendor: string;
            model: string;
          };
        }[];
        fluorescence: {
          id: string;
          name: string;
          firstChannel: string;
          isActive: boolean;
          channelsCount: number;
        }[];
      };
    }>(GET_RELATED_LAYERS);

  const loading = isLoading || loadingStainingsData;

  return (
    <React.Fragment key={wsi.id}>
      <tr style={{ height: '8px' }} />

      <TableRow aria-label={`Assigned ${wsi.id}`}>
        <TableCell>
          <$ThumbnailWrapper>
            <WsiThumbnail
              wsiId={wsi.id}
              getToken={getToken}
              rasterTileServerUrl={rasterTileServerUrl}
              size="small"
            />
          </$ThumbnailWrapper>
        </TableCell>
        <TableCell tooltipText={wsi.name} style={{ maxWidth: '20vw' }}>
          <Link
            href={buildClientWsiUrl(
              organizationUuid,
              subproject.projectId,
              subproject.id,
              wsi.id
            )}
            target="_blank"
          >
            {wsi.name}
          </Link>
        </TableCell>
        <TableCell tooltipText={wsi.scanner.vendor}>
          {wsi.scanner.vendor}
        </TableCell>
        <TableCell tooltipText={wsi.staining || undefined}>
          {wsi.staining}
        </TableCell>
        <TableCell colSpan={2} align="right">
          <$WsiRowRightContainer>
            {!isLoading && wsi.hasHiddenStainings && (
              <Tooltip
                content={
                  <$WarningTooltip>
                    Some active stainings are hidden behind by Block/Section
                    matching condition.
                  </$WarningTooltip>
                }
              >
                <div style={{ cursor: 'pointer' }}>
                  <Icon icon={'AlertCircle'} color="error" />
                </div>
              </Tooltip>
            )}
            {/*  we make sure we have stainings to show 
              fluorescence count is greater than 0 or 
              brightfield count is greater than 1 because the base layer is included in count 
            */}
            {(!wsi.isBlackLayer && wsi.fluorescenceCount > 0) ||
            wsi.brightfieldCount > 1 ? (
              <Button
                small
                variant="ghost"
                onClick={() => {
                  setShowStainings((p) => !p);
                  if (!stainingsData) {
                    void getLayers({
                      variables: {
                        subProjectId: subproject.id,
                        wsiId: wsi.id,
                        mode: overlayMode,
                      },
                    });
                  }
                }}
              >
                {showStainings ? 'Hide ' : 'Show '} Stainings
              </Button>
            ) : null}
          </$WsiRowRightContainer>
        </TableCell>
      </TableRow>
      {!stainingsData && showStainings && loading && (
        <TableSubRowSkeleton rows={6} />
      )}

      <AnimatePresence>
        {showStainings && stainingsData?.relatedLayers && (
          <>
            <>
              {stainingsData?.relatedLayers?.stainings?.map((staining) => {
                return (
                  <EditStainingsStainingRow
                    stainingLayer={{
                      id: staining.id,
                      name: staining.name,
                      staining: staining.stainingName,
                      scanner: staining.scanner,
                      isActive: staining.isActive,
                    }}
                    key={staining.id}
                    subProjectId={subproject.id}
                    switchStaining={switchStaining}
                    rasterTileServerUrl={rasterTileServerUrl}
                    getToken={getToken}
                  />
                );
              })}
            </>
            <>
              {!wsi.isBlackLayer &&
                stainingsData?.relatedLayers?.fluorescence.map(
                  (group, index) => {
                    const origin = group.id;
                    const isFluorescenceVisible = group.isActive;
                    if (!group.channelsCount) return <></>;
                    return (
                      <EditStainingsFluorescenceRow
                        fluorescence={{
                          id: group.id,
                          isParentBlackLayer: true,
                          channelsCount: group.channelsCount,
                          firstChannel: group.firstChannel,
                        }}
                        subProjectId={subproject.id}
                        origin={origin}
                        fluorescenceVisible={isFluorescenceVisible}
                        key={`fluorescence ${index}`}
                        // used for backward compatibility
                        name={group.name ?? wsi.name}
                        switchStaining={switchStaining}
                        getToken={getToken}
                        rasterTileServerUrl={rasterTileServerUrl}
                      />
                    );
                  }
                )}
            </>
          </>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};
