import { pluralize } from '@aignostics/utils';
import {
  difference as _difference,
  differenceWith as _differenceWith,
} from 'lodash';
import { CSVParserInputWSIRow, CSVWSIRow } from '../types';
import { getMismatchedColumnsError } from './getMismatchedColumnsError';

type CollectWarningsResult = {
  hasError: boolean;
  error?: string;
  warnings: string[];
};

export const collectWarnings = (
  csvColumns: string[],
  missingColumns: string[],
  extraneousColumns: string[],
  wsis: CSVParserInputWSIRow[],
  data: CSVWSIRow[],
  CSV_INDEX_OFFSET: number
): CollectWarningsResult => {
  const hasMorphologyColumn = csvColumns.includes('Morphology');
  const hasCancerSiteColumn = csvColumns.includes('Cancer Site');

  if (missingColumns.length > 0 || extraneousColumns.length > 0) {
    return {
      hasError: true,
      error: getMismatchedColumnsError({
        missingColumns,
        extraneousColumns,
      }),
      warnings: [],
    };
  }

  const warnings: string[] = [];

  if (!hasMorphologyColumn) {
    warnings.push(
      'CSV file is missing optional "Morphology" column, using default value'
    );
  }

  if (!hasCancerSiteColumn) {
    warnings.push(
      'CSV file is missing optional "Cancer Site" column, using default value'
    );
  }

  const wsisFilenames = wsis.map((wsi) => wsi.Filename);
  const csvFilenames = data.map((datum) => datum.Filename);
  const missingsRows = _difference(wsisFilenames, csvFilenames);
  if (missingsRows.length > 0) {
    warnings.push(
      // prettier-ignore
      `Missing ${pluralize('row', missingsRows.length)} in the CSV for files: ${missingsRows
        .slice().sort((a, b) => a.localeCompare(b))
        .join(', ')}`
    );
  }

  const extraneousRows = _differenceWith(
    csvFilenames.map(
      (csvFilename, index) => [csvFilename, index] as [string, number]
    ),
    wsisFilenames,
    ([csvFilename], wsiFilename) => csvFilename === wsiFilename
  );
  if (extraneousRows.length > 0) {
    warnings.push(
      // prettier-ignore
      `Extraneous ${pluralize('row', extraneousRows.length)} in the CSV for ${pluralize('file', extraneousRows.length)}: ${extraneousRows
        .map(([filename]) => filename)
        .sort((a, b) => a.localeCompare(b))
        .join(', ')} (CSV line ${pluralize('number', extraneousRows.length)}: ${extraneousRows
          .map(([, index]) => index + CSV_INDEX_OFFSET)
          .sort((a, b) => a - b)
          .join(', ')})`
    );
  }
  return { hasError: false, warnings };
};
