import { gql } from '@apollo/client';

export const GET_FILTERS = gql`
  query GET_FILTERS($associations: [ID]!) {
    associations {
      id
      name
    }
    batches(associations: $associations) {
      id
      name
    }
    stainings(withBlackStaining: true) {
      id
      name
    }
    tissues {
      id
      name
    }
    scanners {
      id
      vendor
      model
    }
    diseases {
      name
    }
  }
`;
export const FETCH_WSIS = gql`
  query FETCH_WSIS(
    $subProjectId: ID!
    $page: Int!
    $pageSize: Int!
    $search: String
    $associations: [ID]!
    $batches: [ID]!
    $case: String
    $objectivePowers: [Float]!
    $scanners: [ID]!
    $stainings: [ID]!
    $tissues: [ID]!
    $diseases: [String]!
  ) {
    wsis(
      page: $page
      pageSize: $pageSize
      subprojectId: $subProjectId
      associations: $associations
      batches: $batches
      case: $case
      objectivePowers: $objectivePowers
      scanners: $scanners
      stainings: $stainings
      tissues: $tissues
      search: $search
      diseases: $diseases
    ) {
      nodes {
        id
        name
        originalName: name(type: original)
        inParent(subProjectId: $subProjectId)
        batchName
        objectivePower
        tissue
        staining
        case {
          id
          sid
        }
        scanner {
          id
          vendor
          model
        }
        association {
          id
          name
        }
      }
      pageInfo {
        page
        totalElements
        totalPages
      }
    }
  }
`;
