import { MULTIPLE_STAININGS_SEPARATOR } from '../const';
import { CSVParserInputWSIRow, CSVWSIRow } from '../types';
import { IsMatchingCsvColumns } from './getCsvParserResults';

export type CsvLinesWarnings = {
  tissues: number[];
  stainings: number[];
  wrongCountOfStainings: number[];
  scanners: number[];
  diseases: number[];
  preparationTypes: number[];
  sampleTypes: number[];
  morphologies: number[];
  cancerSites: number[];
};

export const getCsvLineWarnings = (
  warningsCsvLinks: CsvLinesWarnings,
  isMatchingCsvColumns: IsMatchingCsvColumns,
  csvRowIndex: number,
  CSV_INDEX_OFFSET: 2 | 3,
  csvRow: CSVWSIRow,
  wsi: CSVParserInputWSIRow
): CsvLinesWarnings => {
  const warningsCsvLinksCopy = { ...warningsCsvLinks };
  const {
    isMatchingTissue,
    isMatchingStainings,
    isMatchingScanner,
    isMatchingDisease,
    isMatchingPreparationType,
    isMatchingSampleType,
    isMatchingMorphology,
    isMatchingCancerSite,
  } = isMatchingCsvColumns;

  const countOfErrors = csvRowIndex + CSV_INDEX_OFFSET;

  if (!isMatchingTissue) {
    warningsCsvLinksCopy.tissues.push(countOfErrors);
  }

  // map he to h&e
  const csvStainings = csvRow.Staining.split(MULTIPLE_STAININGS_SEPARATOR);
  const wsiStainings = (wsi.Staining ?? '').split(MULTIPLE_STAININGS_SEPARATOR);
  if (csvStainings.length !== wsiStainings.length) {
    // add warning when count of stainings for multichannel slide
    // file differs from the one we have in the data upload ui
    warningsCsvLinksCopy.wrongCountOfStainings.push(countOfErrors);
  } else if (!isMatchingStainings) {
    warningsCsvLinksCopy.stainings.push(countOfErrors);
  }

  if (!isMatchingDisease) {
    warningsCsvLinksCopy.diseases.push(countOfErrors);
  }

  if (!isMatchingPreparationType) {
    warningsCsvLinksCopy.preparationTypes.push(countOfErrors);
  }
  if (!isMatchingSampleType) {
    warningsCsvLinksCopy.sampleTypes.push(countOfErrors);
  }

  if (!isMatchingScanner) {
    warningsCsvLinksCopy.scanners.push(countOfErrors);
  }
  if (!isMatchingMorphology) {
    warningsCsvLinksCopy.morphologies.push(countOfErrors);
  }

  if (!isMatchingCancerSite) {
    warningsCsvLinksCopy.cancerSites.push(countOfErrors);
  }

  return warningsCsvLinksCopy;
};
