import axios from 'axios';
import PQueue from 'p-queue';
import { useEffect, useMemo, useState } from 'react';
import {
  PairUploadLink,
  UploadMetadata,
} from '../../AgGridSetFileMetadataStep';
import {
  BlobFileWithChecksumAndMetadata,
  ONBOARDING_FILES_UPLOAD_ACTION,
  OnboardingBatchUploadLink,
  UPLOADING_FILE_STATUS,
  useUploadFilesContext,
} from '../UploadStateProvider';
import { useUpdateOnboardingWsisStatus } from './useUpdateOnboardingWsisStatus';

export function useUploadFiles(
  batchId: string,
  batchName: string,
  files: BlobFileWithChecksumAndMetadata[],
  filenameToWsiUuids: Map<string, string[]>,
  wsiUuidsToFileIndex: Map<string, number>,
  pairUploadLinks: PairUploadLink[]
): boolean {
  const { uploadState, dispatch } = useUploadFilesContext();
  const [isUploadingInitialized, setIsUploadingInitialized] = useState(false);
  const notifyFileUploadFinished = useUpdateOnboardingWsisStatus(batchId);

  // convert pairUploadLinks to flat array with indexes matching files ordering
  const uploadLinks = useMemo<OnboardingBatchUploadLink[]>(() => {
    const mapUploadLinks: Record<string, UploadMetadata> = {};
    for (const { filename, uploadLink } of pairUploadLinks) {
      mapUploadLinks[filename] = uploadLink;
    }

    return files.reduce((acc: OnboardingBatchUploadLink[], file) => {
      acc.push(mapUploadLinks[file.file.name]);
      return acc;
    }, []);
  }, [files, pairUploadLinks]);

  useEffect(() => {
    dispatch({
      type: ONBOARDING_FILES_UPLOAD_ACTION.INIT,
      batchId,
      batchName,
      fileSlides: files,
      wsiUuidsToFileIndex,
      uploadLinks,
    });
  }, [dispatch, batchId, batchName, files, wsiUuidsToFileIndex, uploadLinks]);

  useEffect(() => {
    if (
      uploadState.status !== UPLOADING_FILE_STATUS.WAITING_FOR_UPLOAD ||
      isUploadingInitialized
    ) {
      return;
    }

    const queue = new PQueue({
      concurrency: 2,
    });
    uploadLinks.forEach((uploadLink, index) => {
      const { url } = uploadLink;
      const currentFile = files[index].file;
      void queue.add(async () => {
        try {
          await axios.put(url, currentFile, {
            headers: {
              'content-type': 'image/tif',
            },
            onUploadProgress: (event) => {
              dispatch({
                type: ONBOARDING_FILES_UPLOAD_ACTION.UPLOADING,
                filename: currentFile.name,
                uploaded: event.loaded,
              });
            },
            signal: uploadState.abortController.signal,
          });

          await notifyFileUploadFinished(
            filenameToWsiUuids.get(currentFile.name)!
          );
          dispatch({
            type: ONBOARDING_FILES_UPLOAD_ACTION.DONE,
            filename: currentFile.name,
          });
        } catch (error) {
          if (error instanceof Error && error.message !== 'canceled') {
            dispatch({
              type: ONBOARDING_FILES_UPLOAD_ACTION.ERROR,
              filename: currentFile.name,
              error: `${currentFile.name} ${error.message}`,
            });
          }
        }
      });
    });
    setIsUploadingInitialized(true);
  }, [
    setIsUploadingInitialized,
    batchId,
    dispatch,
    files,
    uploadLinks,
    uploadState,
    notifyFileUploadFinished,
    filenameToWsiUuids,
    isUploadingInitialized,
  ]);

  return isUploadingInitialized;
}
