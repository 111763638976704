import { InfoModal } from '@aignostics/components';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { BatchReadOnlyForm } from '../../pages/AgGridSetFileMetadataStep/Form';

const $OnboardingProgressErrorsInfoModalBody = styled.div`
  padding: ${({ theme }) => theme.spacings[24]}px;
`;

const $OnboardingProgressErrorsInfoModalListItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings[16]}px;
`;

const $OnboardingProgressErrorsInfoModalLabel = styled.span`
  ${({ theme }) => theme.fontStyles.baseBold};
`;

const $OnboardingProgressErrorsInfoModalDescription = styled.p`
  ${({ theme }) => theme.fontStyles.base};
`;

export interface OnboardingProgressErrorsModalProps {
  isVisible: boolean;
  onClose: () => void;
  onboardingErrors: BatchReadOnlyForm['onboardingErrors'];
}

export const OnboardingProgressErrorsModal = ({
  isVisible,
  onClose,
  onboardingErrors,
}: OnboardingProgressErrorsModalProps): ReactElement => {
  return (
    <InfoModal
      isVisible={isVisible}
      title="Issues found"
      onClose={onClose}
      hasCloseButton
    >
      <$OnboardingProgressErrorsInfoModalBody>
        {onboardingErrors.map((onboardingError) => (
          <$OnboardingProgressErrorsInfoModalListItem
            key={`${onboardingError.filename}-errors`}
          >
            <$OnboardingProgressErrorsInfoModalLabel>
              {onboardingError.filename}
            </$OnboardingProgressErrorsInfoModalLabel>
            <$OnboardingProgressErrorsInfoModalDescription>
              {onboardingError.errors.map((error) => (
                <span key={`${onboardingError.filename}-${error}`}>
                  {error}
                </span>
              ))}
            </$OnboardingProgressErrorsInfoModalDescription>
          </$OnboardingProgressErrorsInfoModalListItem>
        ))}
      </$OnboardingProgressErrorsInfoModalBody>
    </InfoModal>
  );
};
