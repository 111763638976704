import { difference as _difference } from 'lodash';
import { parse } from 'papaparse';
import { OPTIONAL_COLUMNS, REQUIRED_COLUMNS } from './const';
import {
  CSVParserInputWSIRow,
  CSVWSIRow,
  ParserResult,
  ParserSelectData,
} from './types';
import {
  collectWarnings,
  dropFirstLine,
  handleParseError,
  processParsedRows,
} from './utils';
import { handleCsvWithTableNameHeader } from './utils/handleCsvWithTableNameHeader';

export const csvParser = (
  csvFile: File | string, // The CSV file to parse
  inputWsiRows: CSVParserInputWSIRow[], // Input WSI rows for validation
  selectData: ParserSelectData, // Dropdown data for validation
  isRetryWithStrippedHeader = false // Indicates if this is a retry with the first line stripped
): Promise<ParserResult> =>
  new Promise<ParserResult>((resolve) => {
    const csvIndexOffset = isRetryWithStrippedHeader ? 3 : 2; // Offset for CSV line mapping

    parse<CSVWSIRow>(csvFile, {
      header: true,
      skipEmptyLines: true,

      complete({ data: parsedCsvRows, meta, errors }) {
        // Call the function to handle the single field case
        if (handleCsvWithTableNameHeader(meta, isRetryWithStrippedHeader)) {
          void dropFirstLine(csvFile).then((csvWithoutHeader) => {
            resolve(
              csvParser(
                csvWithoutHeader,
                inputWsiRows,
                selectData,
                true // Retry with stripped header
              )
            );
          });
          return;
        }

        // Handle parsing errors
        const errorMessage = handleParseError(errors);
        if (errorMessage) {
          resolve({ ok: false, error: errorMessage });
          return;
        }

        const csvColumns = meta.fields ?? []; // Extracted column names from the CSV

        // Check for missing or extraneous columns
        const missingColumns = _difference(REQUIRED_COLUMNS, csvColumns);
        const extraColumns = _difference(csvColumns, [
          ...REQUIRED_COLUMNS,
          ...OPTIONAL_COLUMNS,
        ]);

        // Collect warnings for mismatched columns
        const warningsResult = collectWarnings(
          csvColumns,
          missingColumns,
          extraColumns,
          inputWsiRows,
          parsedCsvRows,
          csvIndexOffset
        );

        // If there's an error in warnings, resolve with that error and return
        if (warningsResult.hasError && warningsResult.error) {
          resolve({ ok: false, error: warningsResult.error });
          return;
        }

        try {
          const result = processParsedRows(
            parsedCsvRows,
            inputWsiRows,
            csvColumns,
            selectData,
            csvIndexOffset
          );

          if ('error' in result) {
            resolve({ ok: false, error: result.error });
            return;
          }

          const {
            parsedWsiRows,
            csvLineMapping,
            warnings: rowWarnings,
          } = result;
          const warnings = [...warningsResult.warnings, ...rowWarnings];

          resolve({
            ok: true,
            data: parsedWsiRows,
            csvLineMapping,
            warnings,
          });
        } catch (error) {
          resolve({
            ok: false,
            error: `CSV parsing error: ${(error as Error).message}`,
          });
        }
      },

      error(error) {
        resolve({
          ok: false,
          error: `CSV parsing error: ${error.message}`,
        });
      },
    });
  });
