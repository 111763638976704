import { ParseError } from 'papaparse';

export const handleParseError = (errors: ParseError[]): string | null => {
  // Handle parsing errors
  if (errors.length > 0) {
    let errorMessage = 'CSV parsing error (malformed input data)';

    // Display specific error message if only one error exists
    if (errors.length === 1) {
      errorMessage += `: ${errors[0].message}`;
    }
    return errorMessage;
  }
  return null;
};
