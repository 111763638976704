import { gql } from '@apollo/client';

import { CreatedBy } from '../../types';
import {
  OnboardingBatchStatus,
  OnboardingWsiOnboardProgress,
  OnboardingWsiStatus,
  OnboardingWsiUploadProgressStatus,
} from '../api.types';

export type FetchOnboardingWsisQueryVariables = {
  batchId: string;
  injectBlackLayerReferences: boolean;
};
export type FetchOnboardingWsisQuery = {
  onboardingBatch: Array<FetchedOnboardingBatch>;
};

type FetchedOnboardingBatchWsiRecord = {
  uuid: string;
  name: string;
  caseUuid: string;
  caseId: string;
  block: string;
  section: string;
  patientExternalId: string;
  staining: string;
  tissue: string;
  path: string;
  fileSize: number;
  scannerModel: string;
  scannerType: string | null;
  status: OnboardingWsiStatus;
  relatedSlide: null | string;
  disease: string | null;
  samplePreparation: string | null;
  sampleType: string | null;
  morphology: string | null;
  cancerSite: string | null;
  // MULTIPLEX = FLUORESCENCE
  relationType: null | 'MULTIPLEX' | 'IHC';
  uploadProgress: OnboardingWsiUploadProgressStatus;
  onboardProgress: Array<OnboardingWsiOnboardProgress>;
  originalFilename: null | string;
  metadata?: {
    czi?: {
      channel_name: string;
    };
    qptiff?: {
      channel_name: string;
    };
  };
  parentTmaRow: number | null;
  parentTmaCol: number | null;
  parentSlidePosX: number | null;
  parentSlidePosY: number | null;
  parentWsiUuid: string | null;
};
export type FetchedOnboardingBatch = {
  id: string;
  name: string;
  batchName: string;
  associationName: string;
  createdAt: string;
  createdBy: CreatedBy | null;
  species: string;
  status: OnboardingBatchStatus;
  wsis: Array<FetchedOnboardingBatchWsiRecord>;
  totalSlideFiles: number;
  metadataUsageRestriction: string;
};

export const FETCH_ONBOARDING_WSIS = gql`
  query GET_ONBOARDING_WSIS(
    $batchId: ID!
    $injectBlackLayerReferences: Boolean!
  ) {
    onboardingBatch(id: $batchId) {
      id
      batchName
      associationName
      createdAt
      status
      species
      scanMonth
      scanYear
      totalSlideFiles
      metadataUsageRestriction
      createdBy {
        id
        name
        email
      }
      wsis(injectBlackLayerReferences: $injectBlackLayerReferences) {
        uuid
        name
        caseUuid
        caseId
        block
        section
        patientExternalId
        staining
        tissue
        disease
        samplePreparation
        sampleType
        morphology
        cancerSite
        path
        fileSize
        metadata
        scannerModel
        scannerType
        status
        relatedSlide
        relationType
        uploadProgress
        onboardProgress {
          name
          status
          errors
        }
        originalFilename
        parentTmaRow
        parentTmaCol
        parentSlidePosX
        parentSlidePosY
        parentWsiUuid
      }
    }
  }
`;
