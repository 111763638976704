import { ColDef } from 'ag-grid-enterprise';
import { OnboardingBatchItem } from '../../../../graphql/queries/FETCH_ONBOARDING_BATCHES';
import { Association } from '../../../../types';
import { FilterOptions } from '../../hooks';
import { getAssociationFilterValues } from './helpers';
import {
  associationValueFormatter,
  generateAssociationFilterFormatter,
} from './valueFormatters';

type Params = {
  userAssignedAssociation: Pick<Association, 'name'> | null;
  associationFilterOptions: FilterOptions['associations'];
};
export const getAssociationColDef = ({
  userAssignedAssociation,
  associationFilterOptions,
}: Params): ColDef<OnboardingBatchItem>[] => {
  if (userAssignedAssociation !== null) return [];

  const associationFilterValues = getAssociationFilterValues(
    associationFilterOptions
  );
  const associationFilterFormatter = generateAssociationFilterFormatter(
    associationFilterOptions
  );
  return [
    {
      headerName: 'Association',
      field: 'associationName',
      sortable: false,
      valueFormatter: associationValueFormatter,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: associationFilterValues,
        valueFormatter: associationFilterFormatter,
      },
    },
  ];
};
