import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FETCH_ONBOARDING_WSIS } from '../../../graphql/queries/FETCH_ONBOARDING_WSIS';
import { BatchEditForm, UpdateOnboardingBatchWsiRecord } from '../Form';

export interface UpdateOnboardingBatchMutationResults {
  updateOnboardingBatchWsis: {
    batchId: string;
    batchName: string;
    wsiUuids: string[];
  };
}

type UpdateOnboardingBatchWsisParams = {
  batch_id: BatchEditForm['batch_id'];
  wsis: UpdateOnboardingBatchWsiRecord[];
  association: BatchEditForm['association'];
  metadataUsageRestriction: BatchEditForm['metadataUsageRestriction'];
};
type UpdateOnboardingBatchWsis = ({
  batch_id,
  wsis,
  association,
  metadataUsageRestriction,
}: UpdateOnboardingBatchWsisParams) => Promise<UpdateOnboardingBatchMutationResults>;

const updateOnboardingBatchMutation = gql`
  mutation UPDATE_ONBOARDING_BATCH_WSIS(
    $batch_id: String!
    $wsis: [UpdateOnboardingBatchWsiInput]!
    $association: String!
    $metadataUsageRestriction: String!
  ) {
    updateOnboardingBatchWsis(
      batch_id: $batch_id
      wsis: $wsis
      association: $association
      metadataUsageRestriction: $metadataUsageRestriction
    ) {
      batch_id
      wsi_uuids
    }
  }
`;

/**
 * Returns a function to update slides
 */
export const useUpdateOnboardingBatchWsis = (
  batchId?: string
): null | UpdateOnboardingBatchWsis => {
  const [execute] = useMutation<
    UpdateOnboardingBatchMutationResults,
    UpdateOnboardingBatchWsisParams
  >(updateOnboardingBatchMutation, {
    refetchQueries: [
      {
        query: FETCH_ONBOARDING_WSIS,
        variables: {
          batchId,
          injectBlackLayerReferences: false,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const updateOnboardingBatchWsis = useCallback<UpdateOnboardingBatchWsis>(
    ({ batch_id, wsis, association, metadataUsageRestriction }) =>
      execute({
        variables: {
          batch_id,
          wsis,
          association,
          metadataUsageRestriction,
        },
      }).then((value) => {
        if (value.data === undefined || value.data === null) {
          throw new Error('Null data received from onboarding data update.');
        }
        return value.data;
      }),
    [execute]
  );

  if (!batchId) return null;

  return updateOnboardingBatchWsis;
};
