import {
  CancerSite,
  Disease,
  Morphology,
  PreparationType,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../../types';
import {
  DEFAULT_CANCER_SITE_VALUE,
  DEFAULT_DISEASE_VALUE,
  DEFAULT_MORPHOLOGY_VALUE,
  DEFAULT_PREPARATION_TYPE_VALUE,
  DEFAULT_SAMPLE_TYPE_VALUE,
} from '../../const';
import { CSVParseResultWSIRow, CSVWSIRow } from '../types';
import { NormalizedEntities } from './normalizeAllEntities';

export type IsMatchingCsvColumns = {
  isMatchingTissue: boolean;
  isMatchingStainings: boolean;
  isMatchingScanner: boolean;
  isMatchingDisease: boolean;
  isMatchingPreparationType: boolean;
  isMatchingSampleType: boolean;
  isMatchingMorphology: boolean;
  isMatchingCancerSite: boolean;
};

export function isEmptyValue(value: string): boolean {
  // if truthy – return false
  return !value;
}

export const getCsvParserResults = (
  datum: CSVWSIRow,
  normalizedSelectData: NormalizedEntities,
  isMatchingCsvColumns: IsMatchingCsvColumns,
  wsiStainings: string[],
  hasMorphologyColumn: boolean,
  hasCancerSiteColumn: boolean,
  isWsiUuidSetForMultiChannel: boolean
): CSVParseResultWSIRow => {
  const {
    normalizedTissues,
    normalizedStainings,
    normalizedScanners,
    normalizedDiseases,
    normalizedPreparationTypes,
    normalizedSampleTypes,
    normalizedMorphologies,
    normalizedCancerSites,
  } = normalizedSelectData;

  const {
    isMatchingTissue,
    isMatchingStainings,
    isMatchingScanner,
    isMatchingDisease,
    isMatchingPreparationType,
    isMatchingSampleType,
    isMatchingMorphology,
    isMatchingCancerSite,
  } = isMatchingCsvColumns;
  return {
    Filename: datum.Filename,
    Localization: getLocalization(datum, isMatchingTissue, normalizedTissues),
    Staining: getStaining(
      datum,
      wsiStainings,
      isMatchingStainings,
      normalizedStainings
    ),
    scannerId: getScannerId(datum, isMatchingScanner, normalizedScanners),
    'Case Name': getCaseName(datum),
    'Patient ID': datum['Patient ID'],
    Block: datum.Block,
    Section: datum.Section,
    Disease: getDisease(datum, isMatchingDisease, normalizedDiseases),
    'Preparation Type': getPreparationType(
      datum,
      isMatchingPreparationType,
      normalizedPreparationTypes
    ),
    'Sample Type': getSampleType(
      datum,
      isMatchingSampleType,
      normalizedSampleTypes
    ),
    Morphology: getMorphology(
      datum,
      hasMorphologyColumn,
      isMatchingMorphology,
      normalizedMorphologies
    ),
    'Cancer Site': getCancerSite(
      datum,
      hasCancerSiteColumn,
      isMatchingCancerSite,
      normalizedCancerSites
    ),
    parent_tma_row: getParentTmaRow(datum),
    parent_tma_col: getParentTmaCol(datum),
    parent_slide_pos_x: getParentSlidePosX(datum),
    parent_slide_pos_y: getParentSlidePosY(datum),
    parent_wsi_uuid: getParentWsiUuid(datum),
    wsi_uuid: getWsiUuid(datum, isWsiUuidSetForMultiChannel),
    case_uuid: getCaseUuid(datum),
  };
};

const getLocalization = (
  datum: CSVWSIRow,
  isMatchingTissue: boolean,
  normalizedTissues: Map<string, Tissue>
) => {
  return isMatchingTissue
    ? normalizedTissues.get(datum.Localization.toLowerCase())!.name
    : null;
};
const getStaining = (
  datum: CSVWSIRow,
  wsiStainings: string[],
  isMatchingStainings: boolean,
  normalizedStainings: Map<string, Staining>
) => {
  return wsiStainings.length > 1
    ? datum.Staining
    : isMatchingStainings
      ? normalizedStainings.get(datum.Staining.toLowerCase())!.name
      : null;
};
const getScannerId = (
  datum: CSVWSIRow,
  isMatchingScanner: boolean,
  normalizedScanners: Map<string, Scanner>
) => {
  return isMatchingScanner
    ? normalizedScanners.get(datum.Scanner.toLowerCase())!.id
    : null;
};
const getCaseName = (datum: CSVWSIRow) => {
  return datum['Case Name'] && datum['Case Name'].length > 0
    ? datum['Case Name']
    : datum.Filename.slice(0, datum.Filename.lastIndexOf('.'));
};
const getDisease = (
  datum: CSVWSIRow,
  isMatchingDisease: boolean,
  normalizedDiseases: Map<string, Disease>
) => {
  return !isEmptyValue(datum.Disease)
    ? isMatchingDisease
      ? normalizedDiseases.get(datum.Disease.toLowerCase())!.name
      : null
    : DEFAULT_DISEASE_VALUE;
};
const getPreparationType = (
  datum: CSVWSIRow,
  isMatchingPreparationType: boolean,
  normalizedPreparationTypes: Map<string, PreparationType>
) => {
  return !isEmptyValue(datum['Preparation Type'])
    ? isMatchingPreparationType
      ? normalizedPreparationTypes.get(datum['Preparation Type'].toLowerCase())!
          .name
      : null
    : DEFAULT_PREPARATION_TYPE_VALUE;
};
const getSampleType = (
  datum: CSVWSIRow,
  isMatchingSampleType: boolean,
  normalizedSampleTypes: Map<string, SampleType>
) => {
  return !isEmptyValue(datum['Sample Type'])
    ? isMatchingSampleType
      ? normalizedSampleTypes.get(datum['Sample Type'].toLowerCase())!.name
      : null
    : DEFAULT_SAMPLE_TYPE_VALUE;
};
const getMorphology = (
  datum: CSVWSIRow,
  hasMorphologyColumn: boolean,
  isMatchingMorphology: boolean,
  normalizedMorphologies: Map<string, Morphology>
) => {
  return hasMorphologyColumn
    ? !isEmptyValue(datum['Morphology'])
      ? isMatchingMorphology
        ? normalizedMorphologies.get(datum['Morphology'].toLowerCase())!.code
        : null
      : DEFAULT_MORPHOLOGY_VALUE
    : DEFAULT_MORPHOLOGY_VALUE;
};
const getCancerSite = (
  datum: CSVWSIRow,
  hasCancerSiteColumn: boolean,
  isMatchingCancerSite: boolean,
  normalizedCancerSites: Map<string, CancerSite>
) => {
  return hasCancerSiteColumn
    ? !isEmptyValue(datum['Cancer Site'])
      ? isMatchingCancerSite
        ? normalizedCancerSites.get(datum['Cancer Site'].toLowerCase())!.name
        : null
      : DEFAULT_CANCER_SITE_VALUE
    : DEFAULT_CANCER_SITE_VALUE;
};
const getParentTmaRow = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.parent_tma_row)
    ? Number(datum.parent_tma_row)
    : null;
};
const getParentTmaCol = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.parent_tma_col)
    ? Number(datum.parent_tma_col)
    : null;
};
const getParentSlidePosX = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.parent_slide_pos_x)
    ? Number(datum.parent_slide_pos_x)
    : null;
};
const getParentSlidePosY = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.parent_slide_pos_y)
    ? Number(datum.parent_slide_pos_y)
    : null;
};
const getParentWsiUuid = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.parent_wsi_uuid) ? datum.parent_wsi_uuid : null;
};
const getWsiUuid = (datum: CSVWSIRow, isWsiUuidSetForMultiChannel: boolean) => {
  return isWsiUuidSetForMultiChannel
    ? null
    : !isEmptyValue(datum.wsi_uuid)
      ? datum.wsi_uuid
      : null;
};
const getCaseUuid = (datum: CSVWSIRow) => {
  return !isEmptyValue(datum.case_uuid) ? datum.case_uuid : null;
};
