import * as uuid from 'uuid';
import { OPTIONAL_COLUMNS_INT32, OPTIONAL_COLUMNS_UUID } from '../const';
import { CSVWSIRow } from '../types';
import { isInt32Number } from './isInt32Number';

export const checkValueTypeCorrectness = (
  datum: CSVWSIRow,
  csvRowIndex: number,
  CSV_INDEX_OFFSET: number
): string | null => {
  // optional values used for TMA upload specified in CSV must conform to:
  // parent_tma_row: signed 32bit int,
  // parent_tma_col: signed 32bit int,
  // parent_slide_pos_x: signed 32bit int,
  // parent_slide_pos_y: signed 32bit int,
  // parent_wsi_uuid: uuid,
  // wsi_uuid: uuid,
  // case_uuid: uuid
  // if any of those values are not conforms (if they're specified in the csv)
  // we would return parsing error
  for (const optionalColumnInt32 of OPTIONAL_COLUMNS_INT32) {
    const rawValue = datum[optionalColumnInt32];
    if (
      // value must be truthy
      rawValue &&
      !isInt32Number(Number(datum[optionalColumnInt32]))
    ) {
      return `CSV parsing error (malformed input data) ${optionalColumnInt32} on csv line ${csvRowIndex + CSV_INDEX_OFFSET}: "${rawValue}" is not Int32`;
    }
  }
  for (const optionalColumnUuid of OPTIONAL_COLUMNS_UUID) {
    const rawValue = datum[optionalColumnUuid];
    if (
      // value must be truthy
      rawValue &&
      !uuid.validate(rawValue)
    ) {
      return `CSV parsing error (malformed input data) ${optionalColumnUuid} on csv line ${csvRowIndex + CSV_INDEX_OFFSET}: "${rawValue}" is not UUID`;
    }
  }

  return null; // No errors found
};
