import { OnboardingUploadListValidationIcon } from '@aignostics/components';
import type { CustomCellRendererProps } from 'ag-grid-react';
import React, { type ReactElement } from 'react';
import type { GridData, MetadataGridContext } from '../MetadataGrid.types';
import {
  $CsvLine,
  $IconContainer,
  $SlideName,
  $SlideNameContainer,
  $TextContainer,
} from './CustomCellSlideName.styles';

type SlideDetailsProps = {
  context: MetadataGridContext;
  data: GridData;
  isValid: boolean;
};

const SlideDetails = ({
  data,
  isValid,
}: SlideDetailsProps): ReactElement | null => {
  if (data.type === 'channel') {
    return null;
  }

  return (
    <$SlideNameContainer>
      <$IconContainer>
        <OnboardingUploadListValidationIcon isValid={isValid} />
      </$IconContainer>
      <$TextContainer>
        <$SlideName>{data.slideFile?.filename}</$SlideName>
        {data.csvLineMapping && (
          <$CsvLine>{`CSV Line ${data.csvLineMapping}`}</$CsvLine>
        )}
      </$TextContainer>
    </$SlideNameContainer>
  );
};

interface CustomCellSlideNameProps
  extends CustomCellRendererProps<GridData, unknown, MetadataGridContext> {
  isValid: boolean;
}
export const CustomCellSlideName = ({
  context,
  data,
  isValid,
}: CustomCellSlideNameProps): ReactElement | null => {
  if (!data || data.isHeaderRow) {
    return null;
  }

  return <SlideDetails context={context} data={data} isValid={isValid} />;
};
