export const getMismatchedColumnsError = ({
  missingColumns,
  extraneousColumns,
}: {
  missingColumns: string[];
  extraneousColumns: string[];
}): string => {
  let msg = 'Mismatching columns in the CSV';
  if (missingColumns.length > 0) {
    msg += `. Missing: ${missingColumns.map((c) => `"${c}"`).join(', ')}`;
  }
  if (extraneousColumns.length > 0) {
    msg += `. Extraneous: ${extraneousColumns.map((c) => `"${c}"`).join(', ')}`;
  }

  return msg;
};
