import {
  HStack,
  Icon,
  Input,
  SelectCreatable,
  Tooltip,
  useDisclosure,
  VStack,
} from '@aignostics/components';
import { AnimatePresence } from 'framer-motion';
import React, { ReactElement } from 'react';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { AnnotationCategorySet, AnnotationCategorySetInput } from '../../types';
import { isNewAnnotationCategorySetCodeValid } from '../../utils';
import {
  $AnnotationCategorySetBlock,
  $AnnotationCategorySetBlockToggle,
  $AnnotationCategorySetBlockToggleText,
  $AnnotationCategorySetCodeErrorMessage,
} from './AnnotationCategoryForm.component.styles';

export const existingAnnotationCategorySetCodeErrorMessage =
  'Proposed code already exists for a different annotation category set. Please choose a different value.';

const AnnotationCategorySetBlockTooltip = (): ReactElement => (
  <p>
    Link your annotation category to an annotation set (new or existing one).
    <br /> Each set system requires a name and identifier.
    <br /> Optionally, you can attach a module.
  </p>
);

export const AnnotationCategoryFormSetBlock = ({
  annotationCategorySetInput,
  annotationCategorySets,
  updateAnnotationCategorySetInput,
  initialOpen,
}: {
  annotationCategorySetInput: AnnotationCategorySetInput;
  annotationCategorySets: AnnotationCategorySet[];
  updateAnnotationCategorySetInput: (val: AnnotationCategorySetInput) => void;
  initialOpen: boolean;
}): ReactElement => {
  const { isOpen, close, open } = useDisclosure(initialOpen);
  const theme = useTheme();

  const annotationCategorySetId =
    annotationCategorySetInput.kind === 'assign'
      ? annotationCategorySetInput.categorySet.id
      : annotationCategorySetInput.kind === 'create'
        ? annotationCategorySetInput.id
        : null;

  const annotationCategorySetCodeValue =
    annotationCategorySetInput.kind === 'assign'
      ? annotationCategorySetInput.categorySet.code
      : annotationCategorySetInput.kind === 'create'
        ? annotationCategorySetInput.code
        : null;

  const annotationCategorySetModuleId =
    annotationCategorySetInput.kind !== 'unset' &&
    annotationCategorySetInput.module
      ? annotationCategorySetInput.module.moduleId
      : null;

  const onChangeAnnotationCategorySetName = (
    value?: string,
    isNew?: boolean
  ) => {
    if (!value) {
      updateAnnotationCategorySetInput({ kind: 'unset' });
      return;
    }
    if (isNew) {
      updateAnnotationCategorySetInput({
        kind: 'create',
        id: uuid(),
        name: value,
        code: null,
      });
      return;
    }
    const selectedAnnotationCategorySet = annotationCategorySets.find(
      (set) => set.id === value
    );
    if (selectedAnnotationCategorySet) {
      updateAnnotationCategorySetInput({
        kind: 'assign',
        id: selectedAnnotationCategorySet.id,
        categorySet: selectedAnnotationCategorySet,
      });
      return;
    }
    updateAnnotationCategorySetInput({ kind: 'unset' });
  };

  const onChangeAnnotationCategorySetModule = (
    value?: string,
    isNew?: boolean
  ) => {
    const parentSet = annotationCategorySets.find((set) =>
      set.modules.some((module) => module.moduleId === value)
    );
    const module = parentSet?.modules.find(
      (module) => module.moduleId === value
    );

    const updatedModule = value
      ? isNew
        ? { kind: 'create', moduleName: value, moduleId: uuid() }
        : { kind: 'assign', moduleName: module?.moduleName, moduleId: value }
      : null;

    updateAnnotationCategorySetInput({
      ...annotationCategorySetInput,
      module: updatedModule,
    } as AnnotationCategorySetInput);
  };

  return (
    <VStack style={{ gap: `${theme.spacings['16']}px` }}>
      <$AnnotationCategorySetBlockToggle
        small
        icon={isOpen ? 'ChevronUp' : 'ChevronDown'}
        onClick={() => {
          if (isOpen) {
            close();
          } else {
            open();
          }
        }}
        variant="ghost"
        aria-expanded={isOpen}
      >
        <$AnnotationCategorySetBlockToggleText>
          Annotation Category Set
        </$AnnotationCategorySetBlockToggleText>
        <Tooltip content={<AnnotationCategorySetBlockTooltip />}>
          <Icon
            icon="AlertCircle"
            size="small"
            style={{ marginLeft: `${theme.spacings['8']}px` }}
          />
        </Tooltip>
      </$AnnotationCategorySetBlockToggle>
      <AnimatePresence initial={false}>
        {isOpen && (
          <$AnnotationCategorySetBlock
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <VStack style={{ gap: `${theme.spacings['16']}px`, width: '100%' }}>
              <HStack
                style={{ gap: `${theme.spacings['8']}px`, width: '100%' }}
              >
                <div style={{ flex: 3 }}>
                  <SelectCreatable
                    allowInvalidValue={false}
                    isClearable={true}
                    id="annotation-category-set-name"
                    label="Set Name"
                    onChange={(val) => {
                      onChangeAnnotationCategorySetName(
                        val?.value,
                        val?.__isNew__
                      );
                    }}
                    value={annotationCategorySetId}
                    options={annotationCategorySets.map((acs) => ({
                      value: acs.id,
                      label: acs.name,
                    }))}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Input
                    id="annotation-category-set-code"
                    type="text"
                    onChange={(value) => {
                      updateAnnotationCategorySetInput({
                        ...annotationCategorySetInput,
                        code: value,
                      } as AnnotationCategorySetInput);
                    }}
                    value={annotationCategorySetCodeValue ?? ''}
                    label="Set Identifier"
                    disabled={
                      annotationCategorySetInput.kind !== 'create' ||
                      !annotationCategorySetId
                    }
                  />
                </div>
              </HStack>
              {annotationCategorySetInput &&
              annotationCategorySetInput.kind === 'create' &&
              annotationCategorySetInput.code &&
              !isNewAnnotationCategorySetCodeValid(
                annotationCategorySetInput.code,
                annotationCategorySets
              ) ? (
                <$AnnotationCategorySetCodeErrorMessage>
                  {existingAnnotationCategorySetCodeErrorMessage}
                </$AnnotationCategorySetCodeErrorMessage>
              ) : null}
              <HStack style={{ width: '100%' }}>
                <SelectCreatable
                  isClearable={true}
                  id="annotation-category-set-module"
                  allowInvalidValue={false}
                  onChange={(val) => {
                    onChangeAnnotationCategorySetModule(
                      val?.value,
                      val?.__isNew__
                    );
                  }}
                  value={annotationCategorySetModuleId}
                  options={
                    annotationCategorySetInput.kind === 'assign'
                      ? annotationCategorySetInput.categorySet.modules?.map(
                          (module) => ({
                            value: module.moduleId,
                            label: module.moduleName,
                          })
                        )
                      : []
                  }
                  label="Module (optional)"
                />
              </HStack>
            </VStack>
          </$AnnotationCategorySetBlock>
        )}
      </AnimatePresence>
    </VStack>
  );
};
