// This function is used to calculate the page and page size for the server-side pagination of the ag-grid.
// It takes the maybeStartRow and maybeEndRow from the request object and calculates the page and page size.
export const getAgGridPaginationConfig = (maybeStartRow, maybeEndRow) => {
    const startRow = maybeStartRow ?? 0;
    const endRow = maybeEndRow ?? startRow;
    const computedPageSize = endRow - startRow;
    if (computedPageSize <= 0) {
        throw new Error(`Invalid computed page size: startRow=${startRow}, endRow=${endRow}`);
    }
    return {
        page: Math.floor(startRow / computedPageSize) + 1,
        pageSize: computedPageSize,
    };
};
