import styled from 'styled-components';

export const $Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const $SubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 56px;
  margin: 0;
`;

export const $FileMetadataFileSetWrapper = styled.div`
  width: 100%;
  min-width: ${({ theme }) =>
    theme.breakpoints.FULL_EXTENDED_DATA_ONBOARDING}px;
  gap: ${({ theme }) => theme.spacings[8]}px;
  display: flex;
  flex-direction: column;
`;

export const $HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings[8]}px;
`;

export const $Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[32]}px;
  margin: 0 auto;
  padding-bottom: ${({ theme }) => `${theme.spacings[96]}px`};
  flex-grow: 1;
  width: 100%;
  padding-bottom: 0;
`;

export const $BatchMetadataFullWidthWrapper = styled.div`
  width: 100%;
  padding-left: 56px;
`;
