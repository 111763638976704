/**
 * Builds a unified pagination configuration from grid state and user options
 *
 * @param gridState - The current grid state (possibly from URL parameters)
 * @param options   - User-provided pagination options
 * @returns         A complete pagination configuration
 */
export function buildPaginationConfig(gridState, options) {
    const { enabled = true, pageSize = 20 } = options;
    const effectivePageSize = gridState?.pagination?.pageSize ?? pageSize;
    const page = gridState?.pagination?.page ?? 0;
    const initialRowCount = (page + 1) * effectivePageSize;
    return {
        enabled,
        pageSize: effectivePageSize,
        initialRowCount,
        page,
    };
}
