import {
  CellSelectionModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  GridApi,
  IRowNode,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowGroupingModule,
  RowStyleModule,
  SelectEditorModule,
  TextEditorModule,
  TooltipModule,
  TreeDataModule,
  ValidationModule,
  type ColDef,
  type RichCellEditorParams,
} from 'ag-grid-enterprise';
import type {
  CancerSite,
  Disease,
  Morphology,
  PreparationType,
  SampleType,
  Scanner,
  Staining,
  Tissue,
} from '../../../types';
import {
  CustomCellSelectValue,
  type CustomCellSelectValueValidatorProp,
} from './CustomCellRenderers';
import { GridData } from './MetadataGrid.types';

export const GRID_MODULES = [
  CellSelectionModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ClipboardModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  ColumnMenuModule,
  ContextMenuModule,
  PinnedRowModule,
  RenderApiModule,
  RichSelectModule,
  RowApiModule,
  RowAutoHeightModule,
  RowStyleModule,
  TextEditorModule,
  TooltipModule,
  RowGroupingModule,
  ValidationModule,
  SelectEditorModule,
  TreeDataModule,
];

export const GEN_PINNED_ROW_TEMPLATE = (): GridData[] => [
  {
    rowId: '%TOP_ROW_PINNED%',
    path: [],
    isHeaderRow: true,
    type: 'single-channel',
    slideFile: {
      checksum: 'AAAAAA==',
      filename: 'placeholder',
      fileIndex: 0,
      size: 0,
      type: 'local',
      source: null,
    },
    caseName: '',
    block: '',
    section: '',
    patientExternalId: '',
    scannerId: null,
    staining: null,
    tissue: null,
    disease: null,
    samplePreparation: null,
    sampleType: null,
    morphology: null,
    cancerSite: null,
    parentTmaRow: null,
    parentTmaCol: null,
    parentSlidePosX: null,
    parentSlidePosY: null,
    parentWsiUuid: null,
    caseUuid: null,
    wsiUuid: null,
  },
];
export const slideNameColId = 'colId:slidename';
export const CHANNEL_ENABLED_FOR_EDITING_COLID = 'staining';
export const defaultColDef: ColDef = {
  resizable: true,
  sortable: false,
  filter: false,
  suppressHeaderMenuButton: true,
};
export const collectionValueFormatter = <T>(
  collection: T[],
  valuePropertyName: keyof T,
  renderValue: (arg: T) => string,
  value: string | null | undefined
): string => {
  if (!value) {
    return '';
  } else {
    const matchingObject = collection.find(
      (x) => x[valuePropertyName] === value
    );

    if (matchingObject) {
      return renderValue(matchingObject);
    } else {
      return value;
    }
  }
};

export const generateSelectColDef = <
  Column extends keyof GridData,
  Collection extends { [K in IdColumn]: string },
  IdColumn extends keyof Collection = keyof Collection,
>(
  column: Column,
  {
    headerName,
    collection,
    collectionValues,
    collectionIdColumn,
    renderCollectionItem,
  }: {
    headerName: string;
    collection: Collection[];
    collectionValues: string[];
    collectionIdColumn: IdColumn;
    renderCollectionItem: (i: Collection) => string;
  }
): ColDef<GridData> => ({
  headerName,
  field: column,
  minWidth: 130,
  cellRenderer: CustomCellSelectValue,
  cellRendererParams: {
    checkValidValue: (
      value: GridData[Column],
      dataType: 'multi-channel' | 'single-channel' | 'channel'
    ) => {
      if (dataType === 'multi-channel' || dataType === 'single-channel') {
        // @ts-expect-error same as above
        return collection.some((x) => x[collectionIdColumn] === value);
      }
      return true;
    },
  } as CustomCellSelectValueValidatorProp<GridData[Column]>,
  cellEditor: 'agRichSelectCellEditor',
  valueFormatter: ({ value }) =>
    collectionValueFormatter(
      collection,
      collectionIdColumn,
      renderCollectionItem,
      value as string | null | undefined
    ),
  valueParser: (props) => {
    // if empty string or undefined is being set, set null
    if (props.newValue === '' || props.newValue === undefined) {
      return null;
    }

    return props.newValue as GridData[Column];
  },
  cellEditorParams: {
    values: collectionValues,
    formatValue: (value) =>
      collectionValueFormatter(
        collection,
        collectionIdColumn,
        renderCollectionItem,
        value as string | null | undefined
      ),
    allowTyping: true,
    searchType: 'matchAny',
    filterList: true,
    highlightMatch: true,
    valueListMaxHeight: 220,
  } as RichCellEditorParams<GridData, GridData[Column]>,
  resizable: true,
  suppressNavigable: (params) => params.data?.type === 'channel',
  cellClassRules: {
    'channel-first-row': (params) => {
      if (params.node.parent?.data?.type === 'multi-channel') {
        return params.node.childIndex === 0;
      }
      return false;
    },
    'channel-last-row': (params) => {
      if (
        params.node.childrenAfterSort &&
        params.node.parent?.data?.type === 'multi-channel'
      ) {
        return (
          params.node.childIndex === params.node.childrenAfterSort.length - 1
        );
      }
      return false;
    },
    'channel-disabled-cell': (params) => params.data?.type === 'channel',
  },
  editable: (params) => {
    return params.data?.type !== 'channel';
  },
  rowSpan: (params) => {
    if (
      params.data &&
      params?.node?.parent?.childrenAfterSort &&
      params.data.type === 'channel'
    ) {
      return (
        params.node.parent.childrenAfterSort.length - params.node.childIndex
      );
    }
    return 1;
  },
  colSpan: (params) => {
    if (params.data && params.data.type === 'channel') {
      const displayedColIds = params.api
        .getDisplayedCenterColumns()
        .map((column) => column.getColId());
      const currentColIdx = displayedColIds.indexOf(params.column.getColId());

      let spanLength = 0;
      for (let x = currentColIdx; x < displayedColIds.length; x++) {
        if (displayedColIds[x] === CHANNEL_ENABLED_FOR_EDITING_COLID) {
          break;
        }
        spanLength++;
      }
      return spanLength;
    }

    return 1;
  },
  suppressMovable: true,
});

export const generateTextColDef = <column extends keyof GridData>(
  column: column,
  { headerName }: { headerName: string }
): ColDef<GridData, GridData[column]> => ({
  headerName,
  // @ts-expect-error I'm not sure how to make generic work with prop
  // enumeration of GridDataFlat.
  field: column,
  valueParser: (props) => {
    // if null or undefined is being set, set instead empty string
    if (props.newValue === null || props.newValue === undefined) {
      return '' as GridData[column];
    }

    return props.newValue as GridData[column];
  },
  editable: (params) => {
    return params?.data?.type !== 'channel';
  },
  suppressNavigable: (params) => params.data?.type === 'channel',
  cellClassRules: {
    'channel-first-row': (params) => {
      if (params.node.parent?.data?.type === 'multi-channel') {
        return params.node.childIndex === 0;
      }
      return false;
    },
    'channel-last-row': (params) => {
      if (
        params.node.childrenAfterSort &&
        params.node.parent?.data?.type === 'multi-channel'
      ) {
        return (
          params.node.childIndex === params.node.childrenAfterSort.length - 1
        );
      }
      return false;
    },
    'channel-disabled-cell': (params) => params.data?.type === 'channel',
  },
  minWidth: 130,
  cellEditor: 'agTextCellEditor',
  tooltipValueGetter: ({ value, valueFormatted }) => valueFormatted ?? value,
  resizable: true,
  rowSpan: (params) => {
    if (
      params.data &&
      params?.node?.parent?.childrenAfterSort &&
      params.data.type === 'channel'
    ) {
      const a =
        params.node.parent.childrenAfterSort.length - params.node.childIndex;
      return a;
    }
    return 1;
  },
  suppressMovable: true,
});
export const renderStaining = (staining: Staining): string => staining.name;
export const renderScanner = (scanner: Scanner): string =>
  scanner.model ? `${scanner.vendor} ${scanner.model}` : scanner.vendor;
export const renderLocalization = (tissue: Tissue): string => tissue.name;
export const renderDisease = (disease: Disease): string => disease.name;
export const renderPreparationType = (
  preparationType: PreparationType
): string => preparationType.name;
export const renderSampleType = (sampleType: SampleType): string =>
  sampleType.name;
export const renderMorphology = (morphology: Morphology): string =>
  morphology.displayName;
export const renderCancerSite = (cancerSite: CancerSite): string =>
  cancerSite.name;

export const isStainingValid = (
  staining: GridData['staining'],
  stainings: Staining[]
): boolean => stainings.some(({ name }) => staining === name);

export const areMIFChannelsValid = (
  rowNode: IRowNode<GridData>,
  stainings: Staining[]
): boolean =>
  rowNode.allLeafChildren!.every((channel) =>
    isStainingValid(channel.data!.staining, stainings)
  );

export const isValidRowDatum = (
  rowDatum: GridData,
  api: GridApi<GridData>,
  stainings: Staining[]
): boolean => {
  if (rowDatum.type === 'single-channel' || rowDatum.type === 'multi-channel') {
    if (rowDatum.type === 'multi-channel' && api) {
      const multiChannel = api.getRowNode(rowDatum.rowId)!;
      const areChannelsValid = areMIFChannelsValid(multiChannel, stainings);
      const multiChannelData = multiChannel?.data;
      if (!multiChannelData) {
        return false;
      }
      const isMultiChannelEntriesValid =
        Boolean(multiChannelData.scannerId) &&
        Boolean(multiChannelData.tissue) &&
        Boolean(multiChannelData.caseName) &&
        Boolean(multiChannelData.disease) &&
        Boolean(multiChannelData.samplePreparation) &&
        Boolean(multiChannelData.sampleType) &&
        Boolean(multiChannelData.morphology) &&
        Boolean(multiChannelData.cancerSite);

      return isMultiChannelEntriesValid && areChannelsValid;
    }
    return (
      Boolean(rowDatum.staining) &&
      Boolean(rowDatum.scannerId) &&
      Boolean(rowDatum.tissue) &&
      Boolean(rowDatum.caseName) &&
      Boolean(rowDatum.disease) &&
      Boolean(rowDatum.samplePreparation) &&
      Boolean(rowDatum.sampleType) &&
      Boolean(rowDatum.morphology) &&
      Boolean(rowDatum.cancerSite)
    );
  }
  return true;
};

/**
 * Return slide file name for corresponding instance of GridData (be it a
 * single-channel, multi-channel, or a channel of multi-channel file).
 */
export const getSlideFilename = (gridDatum: GridData): string =>
  gridDatum.path[0];
